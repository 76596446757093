export const ProductUploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        className="icon"
        d="M7.013 231.518a4.768 4.768 0 0 0-4.76 4.76v9.479a4.768 4.768 0 0 0 4.76 4.761h9.479a4.768 4.768 0 0 0 4.761-4.761v-9.48a4.768 4.768 0 0 0-4.761-4.761zm0 1.591h9.479a3.146 3.146 0 0 1 3.17 3.17v9.479a3.146 3.146 0 0 1-3.17 3.17H7.013a3.145 3.145 0 0 1-3.17-3.17v-9.48a3.146 3.146 0 0 1 3.17-3.17zm4.927 2.424a.8.8 0 0 0-.784.806v3.832H7.325a.8.8 0 1 0 0 1.59h3.831v3.831a.8.8 0 1 0 1.591 0v-3.831h3.831a.8.8 0 1 0 0-1.59h-3.831v-3.832a.8.8 0 0 0-.807-.806z"
        transform="translate(3.247 -226.018)"
        fill="#fff"
      />
      <path data-name="사각형 3745" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
