interface Props {
  size?: number;
  color?: string;
}

export const ResetIcon = ({ color, size }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size : 24}
      height={size ? size : 24}
      viewBox="0 0 24 24"
    >
      <path data-name="패스 4032" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="패스 4033"
        d="M17.65 6.35a7.95 7.95 0 0 0-6.48-2.31 8 8 0 1 0 8.04 11.4 1 1 0 0 0-.9-1.44.973.973 0 0 0-.88.53A6 6 0 1 1 12 6a5.915 5.915 0 0 1 4.22 1.78l-1.51 1.51a1 1 0 0 0 .7 1.71H19a1 1 0 0 0 1-1V6.41a1 1 0 0 0-1.71-.71z"
        fill={color ? color : "#39415c"}
      />
    </svg>
  );
};
