import { UseMutationResult } from "react-query";
import { ProhibitReplaceWord } from "../../../../../interface/uploadinterface";
import { ReplaceWordTableItem } from "./ReplaceWordTableItem";
import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";
import { useTooltip } from "@/hooks/useTooltip";
import { NoneItemMessage } from "@/components/Admin/AdminCategoryManage/component/openmarketcategorymanage/openmarket/NoneItemMessage";

interface Props {
  replaceWordList: ProhibitReplaceWord[];
  setSelectedReplaceWord: React.Dispatch<
    React.SetStateAction<ProhibitReplaceWord[]>
  >;
  selectedReplaceWord: ProhibitReplaceWord[];
  getReplaceWord: UseMutationResult<any, unknown, void, unknown>;
  clickAll: () => Promise<void>;
  isAll: boolean;
  searchValue: { beforeWord: string; afterWord: string };
  setSearchValue: React.Dispatch<
    React.SetStateAction<{ beforeWord: string; afterWord: string }>
  >;
}

export const ReplaceWordTable = ({
  searchValue,
  setSearchValue,
  replaceWordList,
  selectedReplaceWord,
  setSelectedReplaceWord,
  getReplaceWord,
  clickAll,
  isAll,
}: Props) => {
  const [isModifyTooltip, enterModify, leaveModify] = useTooltip();
  const [isReplaceTooltip, enterReplace, leaveReplace] = useTooltip();
  const [isFunctionTooltip, enterFunction, leaveFunction] = useTooltip();

  const inputSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchValue({
      ...searchValue,
      [name]: value,
    });
  };
  return (
    <div className="row total-table-col mt-4 collect-market">
      <div className="col-12">
        <div className="relative heading-two heading-three heading-five heading-five1  m-0 tableMaxHeight prohibitWordTableMinHeight">
          <GlobalTooltip
            isVisible={isModifyTooltip as boolean}
            style={{ left: "130px" }}
          >
            등록된 변경 단어를 검색할 수 있습니다.
          </GlobalTooltip>
          <GlobalTooltip
            isVisible={isReplaceTooltip as boolean}
            style={{ right: "80px" }}
          >
            등록된 치환 단어를 검색할 수 있습니다.
          </GlobalTooltip>
          <GlobalTooltip
            isVisible={isFunctionTooltip as boolean}
            style={{ right: "0" }}
          >
            선택한 치환 단어를 등록에서 제거합니다.
          </GlobalTooltip>
          <table
            className="productNameTable"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border={0}
            align="center"
          >
            <thead className="head-one">
              <tr>
                <th>
                  <div className="form-group">
                    <input
                      onClick={clickAll}
                      checked={isAll}
                      type="checkbox"
                      id="htmlaxs"
                      readOnly
                    />
                    <label htmlFor="htmlaxs"></label>
                  </div>
                </th>
                <th
                  onMouseEnter={enterModify as () => void}
                  onMouseLeave={leaveModify as () => void}
                >
                  <div>
                    <input
                      name="beforeWord"
                      value={searchValue.beforeWord}
                      onChange={inputSearchValue}
                      className="prohibitSearchInput"
                      placeholder="변경 단어"
                    />
                  </div>
                </th>
                <th
                  onMouseEnter={enterReplace as () => void}
                  onMouseLeave={leaveReplace as () => void}
                >
                  <div>
                    <input
                      name="afterWord"
                      value={searchValue.afterWord}
                      onChange={inputSearchValue}
                      className="prohibitSearchInput"
                      placeholder="치환 단어"
                    />
                  </div>
                </th>
                <th>등록일자</th>
                <th
                  onMouseEnter={enterFunction as () => void}
                  onMouseLeave={leaveFunction as () => void}
                >
                  기능
                </th>
              </tr>
            </thead>
            <tbody className="body-one price-one">
              {replaceWordList?.map((word: ProhibitReplaceWord) => (
                <ReplaceWordTableItem
                  key={word.idx}
                  word={word}
                  setSelectedReplaceWord={setSelectedReplaceWord}
                  selectedReplaceWord={selectedReplaceWord}
                  getReplaceWord={getReplaceWord}
                />
              ))}
              {replaceWordList.length === 0 && <NoneItemMessage />}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
