import { ProdcutManageResultItem } from "@/interface/productmanageinterface";

interface Props {
  result: ProdcutManageResultItem;
  isSelect: boolean;
}

export const ResultItemPriceTag = ({ result, isSelect }: Props) => {
  return (
    <td className="original-td">
      <div
        className={`collect-inner-1 original-price text-start original-product-view tableMaxHeight ${
          isSelect && "selectedItem"
        }`}
      >
        <div className="original-price-inner">
          <h5 className="textCenter">
            원본 할인가 (￥) :{" "}
            {result?.minimumPrice[0].originalPrice.toLocaleString("CN")} ~{" "}
            {result?.maximumPrice[0].originalPrice}
            <br />
            설정 상품가 (\) :{" "}
            {result?.minimumPrice[1].salePrice.toLocaleString()} ~{" "}
            {result?.maximumPrice[1].salePrice.toLocaleString()}
          </h5>
          <div className="tags-btn mb-1 tagBox">
            {result?.isSetTag &&
              result?.tags.map((tag, idx) => (
                <button key={idx} className="tag-1">
                  {tag}
                </button>
              ))}
          </div>
        </div>
      </div>
    </td>
  );
};
