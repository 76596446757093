import { QuestionButton } from "@/components/Button/QuestionButton";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import useSelectHelperComponent from "@/hooks/useSelectHelperComponent";
import { CollectValue } from "@/interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectWay: string;
}

export const AliSearchingUrl = ({
  collectValue,
  inputCollectValue,
  collectWay,
}: Props) => {
  const openHelper = useSelectHelperComponent();
  return (
    <div className="product-label product-label-1 col-md-9 col-12">
      <div className="d-flex align-items-center gap-3 mb-2">
        <label htmlFor="product-name" className="paddingZero">
          {collectWay === "multi" ? "상품목록URL" : "상품단일URL"}
        </label>
        <QuestionButton openId="66755bbd604d73de56fa56a4" />
      </div>
      <input
        type="text"
        value={collectValue.url}
        onChange={inputCollectValue}
        className="form-control"
        name="url"
        placeholder="url을 입력하세요."
      />
    </div>
  );
};
