import { AlertOnlyClose } from "@/components/Alert/AlertOnlyClose";
import { OhooTranslateResult } from "@/components/OhooTranslateResult";
import { ToastEditor } from "@/components/ToastEditor";
import { useAlert } from "@/hooks/useAlert";
import useToggle from "@/hooks/useToggle";
import { UploadSummary } from "@/interface/uploadinterface";
import { ohoo } from "@/utils/functions/ohooStudioApi";
import { urlToBase64 } from "@/utils/functions/urlToBase64";
import { useRef, useState } from "react";
import { ReactCropperElement } from "react-cropper";
import LoadingModal from "../LoadingModal";
import { EditBox } from "./component/EditBox";
import { Layer } from "./component/Layer";
import { Viewer } from "./component/Viewer";

interface Props {
  cropImg: string;
  item: UploadSummary;
  imgList: string[];
  applyImage: (img: string) => Promise<void>;
  setCropImg: React.Dispatch<React.SetStateAction<string>>;
}

export const ImgEditor = ({
  cropImg,
  item,
  imgList,
  applyImage,
  setCropImg,
}: Props) => {
  const { state: isPrevTranslate, handleToggle: handlePrevTanslate } =
    useToggle();
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isSquare, setIsSquare] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { state: isToastEditor, handleToggle: handleToastEditor } = useToggle();
  const { state: isTranslate, handleToggle: handleTranslate } = useToggle();
  const [editedImg, setEditedImg] = useState<string>(imgList[0]);
  const [ohooEditorId, setOhooEditorId] = useState<{
    id: string;
    link: string;
  }>({
    id: "",
    link: "",
  });

  const [infoText, setInfoText] = useState<string>("");

  const clickPrevResult = async () => {
    if (ohooEditorId.id !== "") {
      handlePrevTanslate();
    } else {
      handleAlert("번역기 호출이 필요합니다.");
    }
  };

  const handleSquareCrop = () => {
    setIsSquare((prev) => !prev);
    if (isSquare) {
      (cropperRef.current as any).cropper.options.aspectRatio = undefined;
    } else {
      (cropperRef.current as any).cropper.options.aspectRatio = 1;
    }
  };

  const clickTranslate = async () => {
    setIsLoading(true);
    const base64 = await urlToBase64(cropImg);
    try {
      const res = await ohoo.getEditor({
        base64Image: base64,
        code: item.code,
        site: item.site,
      });
      if (res) {
        setOhooEditorId(res);
        handleTranslate();
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const mouseHover = (string: string) => {
    setInfoText(string);
  };

  const mouseLeave = () => {
    setInfoText("");
  };

  const setImageFromEditor = (img: string) => {
    setCropImg(img);
    setEditedImg(img);
  };

  return (
    <div className="row mt-3">
      <Layer
        isSquare={isSquare}
        cropImg={cropImg}
        cropperRef={cropperRef}
        setEditedImg={setEditedImg}
      />
      <div className="col-md-6 col-12 widthHalf mt-4 paddingRight0">
        <Viewer
          editedImg={editedImg}
          cropperRef={cropperRef}
          mouseHover={mouseHover}
          mouseLeave={mouseLeave}
          applyImage={applyImage}
          infoText={infoText}
        />
        <EditBox
          clickPrevResult={clickPrevResult}
          cropperRef={cropperRef}
          mouseHover={mouseHover}
          mouseLeave={mouseLeave}
          isSquare={isSquare}
          handleSquareCrop={handleSquareCrop}
          clickTranslate={clickTranslate}
          handleToastEditor={handleToastEditor}
        />
      </div>
      {isToastEditor && (
        <ToastEditor
          applyImage={setImageFromEditor}
          cropImg={editedImg}
          closeEditor={handleToastEditor}
        />
      )}
      {isLoading && (
        <LoadingModal>
          <LoadingModal.LoadingModalCloseButton
            closeModal={() => setIsLoading(false)}
          />
        </LoadingModal>
      )}
      {isTranslate && (
        <OhooTranslateResult
          applyImage={setImageFromEditor}
          item={item}
          ohooEditorId={ohooEditorId}
          originalImg={cropImg}
          closeModal={handleTranslate}
        />
      )}
      {isPrevTranslate && (
        <OhooTranslateResult
          isPrev={true}
          closeModal={handlePrevTanslate as () => void}
          ohooEditorId={ohooEditorId}
          item={item!}
          applyImage={setImageFromEditor}
          originalImg={cropImg}
        />
      )}
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </div>
  );
};
