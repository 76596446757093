import { ModalBg } from "@/components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@/components/ModalLayout/ModalInnerTop";
import { useMemo, useState } from "react";
import axiosInstance from "@/utils/axiosInstance";
import axios, { AxiosError } from "axios";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import { PayResult } from "@/interface/payment";

interface Props {
  payInfo: PayResult;
  closeModal: () => void;
  setIsSendCode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReferenceCodeModal = ({
  payInfo,
  closeModal,
  setIsSendCode,
}: Props) => {
  const openInfo = useInfoWindow();
  const api = useMemo(() => {
    return process.env.REACT_APP_CENTER_API;
  }, []);

  const [code, setCode] = useState("");
  const inputCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const payDate = useMemo(() => {
    const dateArr: string[] = payInfo.AuthDate.match(/.{1,2}/g)!;
    const newDate = `20${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`;
    return newDate;
  }, []);

  const clickSubmitCode = async () => {
    if (code === "") return;
    try {
      const res = await axios.post(`${api}/paylog`, {
        orderNumber: payInfo.MOID,
        code: code,
        payAmount: payInfo.Amt,
        payerName: payInfo.BuyerName,
        payDate: payDate,
        payerPhone: payInfo.BuyerTel.split("-")[2],
        payerEmail: payInfo.BuyerEmail,
      });

      if (res.data) {
        setIsSendCode(true);
        closeModal();
      }
    } catch (error) {
      if (error instanceof AxiosError) openInfo(error.response?.data.message);
    }
  };

  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop>
        <>
          <div className="modal-header modal-sticky">
            <h5 className="modal-title d-flex align-items-center">
              추천인 코드 입력
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <input
                onChange={inputCode}
                value={code}
                className="customInput"
                placeholder="추천인 코드를 입력해주세요"
              />
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                  <button className="cancel-btn" onClick={closeModal}>
                    닫기
                  </button>
                  <button
                    onClick={clickSubmitCode}
                    className="cancel-btn save-btn"
                  >
                    코인 신청
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
