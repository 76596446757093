import NavBar from "@/components/NavBar/NavBar";
import { NavBarDefaultComponent } from "@/components/NavBar/component/NavBarDefaultComponent";
import { Popup } from "@/components/Popup/Popup";
import useFetch from "@/hooks/useFetch";
import "@/components/Home/Home.css";
import "@/components/Admin/Admin.css";
import { lazy } from "react";
import { HomeBanner } from "@/components/Home/HomeBanner/component/HomeBanner";
import { useRecoilValue } from "recoil";
import { isDeskTop } from "@/atom/atom";
const HomeSalesGraph = lazy(
  () => import("../components/Home/HomeSalesGraph/HomeSalesGraph")
);
const HomeProductManage = lazy(
  () => import("../components/Home/HomeProductManage/HomeProductManage")
);
const HomeUserInfo = lazy(
  () => import("../components/Home/HomeUserInfo/HomeUserInfo")
);

const HomeCollectLog = lazy(
  () => import("../components/Home/HomeCollectLog/HomeCollectLog")
);
const HomeUploadLog = lazy(
  () => import("../components/Home/HomeUploadLog/HomeUploadLog")
);
const HomeNotice = lazy(
  () => import("../components/Home/HomeNotice/HomeNotice")
);

const Home = () => {
  const [popupList] = useFetch(
    "popupList",
    "https://center.allttam.com/api/banner/popup",
    100000
  );
  const isDesktop = useRecoilValue(isDeskTop);

  return (
    <div>
      {isDesktop &&
        popupList?.map((item: Popup) => (
          <Popup
            key={item._id}
            img={item.imgUrl}
            link={item.link}
            name={`allttam-popup_${item._id}`}
          />
        ))}
      <NavBar>
        <NavBarDefaultComponent />
      </NavBar>
      <div className="row">
        <HomeUserInfo />
        <HomeBanner />
      </div>
      <div className="row">
        <div className="col-md-6 col-12 d-flex gap-3 md-f-c">
          <HomeNotice />
          <HomeProductManage />
        </div>
        <HomeSalesGraph />
      </div>
      <div className="row">
        <HomeCollectLog />
        <HomeUploadLog />
      </div>
    </div>
  );
};

// md-d-none

interface Popup {
  _id: string;
  imgUrl: string;
  link: string;
}
export default Home;
