import { ExpectListType, UploadSummary } from "@/interface/uploadinterface";

interface Parameters {
  itemList: UploadSummary[];
  rateUSD: number;
  rateCNY: number;
  mainValue: number;
  subValue: number;
  deliveryFee: number;
  extraMargin: number;
}

export const calculateExpect = ({
  itemList,
  rateUSD,
  rateCNY,
  mainValue,
  subValue,
  deliveryFee,
  extraMargin,
}: Parameters): ExpectListType[] => {
  return itemList.map((item) => {
    const currency =
      item.maximumPrice.filter((item) => item.currency !== "KRW")[0]
        .currency === "CNY"
        ? rateCNY
        : item.maximumPrice.filter((item) => item.currency !== "KRW")[0]
            .currency === "USD"
        ? rateUSD
        : 1;

    const currencyString = item.maximumPrice.filter(
      (item) => item.currency !== "KRW"
    )[0].currency;

    const defaultPriceKoreanMin =
      item.minimumPrice.filter((item) => item.currency !== "KRW")[0].salePrice *
      currency;

    const defaultPriceKoreanMax =
      item.maximumPrice.filter((item) => item.currency !== "KRW")[0].salePrice *
      currency;

    const settingPriceKoreanMin =
      item.minimumPrice.filter((item) => item.currency !== "KRW")[0].salePrice *
        currency *
        mainValue +
      subValue;

    const settingPriceKoreanMax =
      item.maximumPrice.filter((item) => item.currency !== "KRW")[0].salePrice *
        currency *
        mainValue +
      subValue;

    return {
      currency: currencyString as "USD" | "CNY" | "KRW" | "WON",
      thumbnail: item.mainImages[0],
      productName: item.title.filter((item) => item.language === "KR")[0].text,
      defaultPrice: {
        min: item.minimumPrice.filter((item) => item.currency !== "KRW")[0]
          .salePrice,
        max: item.maximumPrice.filter((item) => item.currency !== "KRW")[0]
          .salePrice,
      },
      settingPrice: {
        min: Math.round(settingPriceKoreanMin),
        max: Math.round(settingPriceKoreanMax),
      },
      expectMarinRate: Math.round(
        ((settingPriceKoreanMin - defaultPriceKoreanMin - deliveryFee) /
          settingPriceKoreanMin) *
          100
      ),
      expectMargin: {
        min: Math.round(
          settingPriceKoreanMin - defaultPriceKoreanMin - deliveryFee
        ),
        max: Math.round(
          settingPriceKoreanMax - defaultPriceKoreanMax - deliveryFee
        ),
      },
    };
  });
};
