import { orderManage } from "../../../../utils/functions/postApi";
import { Revenue } from "../../../../interface/orderManageInterface";
import { useInfoWindow } from "@/hooks/useInfoWindow";

interface Props {
  closeModal: () => void;
  revenueValue: Revenue;
  profit: number;
}

export const RevenueUpdateButton = ({
  closeModal,
  revenueValue,
  profit,
}: Props) => {
  const openWindow = useInfoWindow();

  const confirmUpdate = async () => {
    try {
      const updateRes = await orderManage.updateRevenue({
        ...changeNullToZero(revenueValue),
        profit: Math.round(profit),
      });
      if (updateRes.status === "Success") {
        openWindow("성공적으로 수정했습니다.");
        closeModal();
      }
    } catch {
      openWindow("적용에 실패했습니다.");
    }
  };

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center gap-3">
          <button className="cancel-btn" onClick={closeModal}>
            닫기
          </button>
          <button onClick={confirmUpdate} className="cancel-btn save-btn">
            적용
          </button>
        </div>
      </div>
    </div>
  );
};

const changeNullToZero = (obj: any) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === null) {
      obj[key as keyof Revenue] = 0;
    }
  }

  return obj;
};
