import { intlTimeFormat } from "../../../../../utils/functions/intlTimeFormat";
import { UploadLog } from "../../../../../interface/uploadinterface";
import { copyFunction } from "@/utils/functions/copyFunction";

interface Props {
  item: UploadLog;
  itemCode?: string;
}

export const LogItem = ({ item, itemCode }: Props) => {
  //스마트 스토어 예시
  //https://smartstore.naver.com/jibeoneo/products/10198580421
  // G마켓
  // https://item.gmarket.co.kr/Item?goodscode=3707614511
  // 11번가
  // https://www.11st.co.kr/products/7023995640

  return (
    <div className="work-number-table-tab heading-five1 uploadLogWrapper">
      <table className="table">
        <thead className="uploadThead">
          <tr>
            <th className="oneLine">작업 번호</th>
            <th className="oneLine">사용자 정보</th>
            <th className="oneLine">상태</th>
            <th className="oneLine">마켓 고유번호</th>
            <th className="oneLine d-flex align-items-end">
              원본상품코드
              <button
                value={itemCode}
                onClick={(e) => {
                  e.stopPropagation();
                  copyFunction(e.currentTarget.value);
                }}
                className="d-flex align-items-center madebutton"
                style={{
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingBottom: "0.3rem",
                }}
              >
                <span className="copy-1"></span>
                <span className="copy-1 copy-2"></span>
              </button>
            </th>
            <th className="oneLine">등록 시간</th>
          </tr>
        </thead>
        <tbody>
          <tr className="">
            <td>{item.workNumber}</td>
            <td>{item.account}</td>
            <td className={item.status === "Success" ? "textGreen" : "textRed"}>
              {item.status}
            </td>
            <td>{item.marketProductCode}</td>
            <td>{itemCode}</td>
            <td>{intlTimeFormat(item.regDate)}</td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div
                className={
                  item.status === "Success"
                    ? "green-success-text mt-4 "
                    : "red-error-text mt-4"
                }
              >
                {item.status === "Success" ? (
                  <a
                    href={item.marketEtcInfo}
                    target="_blank"
                    className="textGreen"
                  >
                    {item.marketEtcInfo}
                  </a>
                ) : (
                  <p>{item.marketEtcInfo}</p>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
