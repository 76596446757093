import { useEffect, useState } from "react";
import { changeTimeFormat } from "../../../../../utils/functions/changeTimeFormat";
import { ProhibitReplaceWord } from "../../../../../interface/uploadinterface";
import { XIcon } from "../../../../Admin/AdminCategoryMapping/component/svg/XIcon";
import { upload } from "../../../../../utils/functions/postApi";
import { UseMutationResult } from "react-query";

interface Props {
  word: ProhibitReplaceWord;
  setSelectedReplaceWord: React.Dispatch<
    React.SetStateAction<ProhibitReplaceWord[]>
  >;
  selectedReplaceWord: ProhibitReplaceWord[];
  getReplaceWord: UseMutationResult<any, unknown, void, unknown>;
}

export const ReplaceWordTableItem = ({
  word,
  setSelectedReplaceWord,
  selectedReplaceWord,
  getReplaceWord,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>();
  useEffect(() => {
    if (selectedReplaceWord.find((item) => item.idx === word.idx)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedReplaceWord]);

  const selectReplaceWord = () => {
    if (isSelect) {
      setSelectedReplaceWord(
        selectedReplaceWord.filter((item) => item.idx !== word.idx)
      );
    } else {
      setSelectedReplaceWord((prev) => [...prev, word]);
    }
  };

  const clickRemoveReplaceWord = async () => {
    const removeRes = await upload.removeProhibitReplaceWord([word.idx]);
    if (removeRes === "완료했습니다.") {
      setSelectedReplaceWord([]);
      getReplaceWord.mutate();
    }
  };
  return (
    <tr onClick={selectReplaceWord}>
      <td>
        <div
          className={`form-group d-flex align-items-center justify-content-center height45 border-left ${
            isSelect && "selectedItem"
          }`}
        >
          <input type="checkbox" checked={isSelect} readOnly />
          <label></label>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center height45 ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0 weight500">{word.targetWord}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center height45 ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0 weight500">{word.convertWord}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center height45 ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0 weight500">
            {changeTimeFormat(word.regDate.split("T")[0])}
          </p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center height45 border-right ${
            isSelect && "selectedItem"
          }`}
        >
          <button
            className="madebutton"
            onClick={(e) => {
              e.stopPropagation();
              clickRemoveReplaceWord();
            }}
          >
            <XIcon />
          </button>
        </div>
      </td>
    </tr>
  );
};
