import { PaymentLog } from "@/interface/paymentInterface";

interface Props {
  log: PaymentLog;
}

export const PayLogItem = ({ log }: Props) => {
  return (
    <tr>
      <td>
        <p className="b-table-p">{log.no}</p>
      </td>
      <td>
        <p>{log.planName}</p>
      </td>
      <td>
        <p>{log.slot}</p>
      </td>
      <td>
        <p>{log.price.toLocaleString()}원</p>
      </td>
      <td>
        <p>{log.lastPayDate}</p>
      </td>
    </tr>
  );
};
