import { useEffect, useMemo, useState } from "react";
import useFetch from "@/hooks/useFetch";
import { Revenue } from "@/interface/orderManageInterface";
import { RevenueCurrency } from "./component/RevenueCurrency";
import { RevenueAdjustPrice } from "./component/RevenueAdjustPrice";
import { RevenueProductName } from "./component/RevenueProductName";
import { RevenueOrderPrice } from "./component/RevenueOrderPrice";
import { RevenueWeightFee } from "./component/RevenueWeightFee";
import { RevenueDeliveryFee } from "./component/RevenueDeliveryFee";
import { RevenuePurchasePrice } from "./component/RevenuePurchasePrice";
import { RevenueOpenMarketFee } from "./component/RevenueOpenMarketFee";
import { RevenueShipment } from "./component/RevenueShipment";
import { RevenueCustomDuty } from "./component/RevenueCustomDuty";
import { RevenueEtcFee } from "./component/RevenueEtcFee";
import { RevenueProfit } from "./component/RevenueProfit";
import { RevenueUpdateButton } from "./component/RevenueUpdateButton";
import { ModalBg } from "@/components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@/components/ModalLayout/ModalInnerTop";
import { RevenueVat } from "./component/RevenueVat";

interface Props {
  closeModal: () => void;
  idx: number;
}

export const ExpectationRevenueModal = ({ closeModal, idx }: Props) => {
  const [revenue] = useFetch(
    `${idx}Revenue`,
    `/OrderManagement/GetRevenue?idx=${idx}`
  );

  const [revenueValue, setRevenueValue] = useState<Revenue>(REVENUE_DEFAULT);

  const inputRevenueValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setRevenueValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (revenue) {
      setRevenueValue(revenue);
    }
  }, [revenue]);

  useEffect(() => {
    setRevenueValue((prev) => {
      return {
        ...prev,
        purchasePriceWon:
          revenueValue.currencyRate * revenueValue.purchasePriceOrigin
            ? Math.round(
                revenueValue.currencyRate * revenueValue.purchasePriceOrigin
              )
            : 0,
        openmarketFeePrice: Math.floor(
          revenueValue.orderPrice * revenueValue.openmarketFee * 0.01
        )
          ? Math.floor(
              revenueValue.orderPrice * revenueValue.openmarketFee * 0.01
            )
          : 0,
        adjustPrice:
          revenueValue.orderPrice -
          (Math.floor(
            revenueValue.orderPrice * revenueValue.openmarketFee * 0.01
          )
            ? Math.floor(
                revenueValue.orderPrice * revenueValue.openmarketFee * 0.01
              )
            : 0) +
          revenueValue.deliveryFee,
      };
    });
  }, [
    revenueValue.currencyRate,
    revenueValue.purchasePriceOrigin,
    revenueValue.openmarketFee,
    revenueValue.customsDutyRate,
  ]);

  const vat = useMemo(() => {
    const newObj = { ...revenueValue };
    const newObj2 = changeNullToZero(newObj);
    return (
      (newObj2.adjustPrice -
        newObj2.purchasePriceWon -
        newObj2.shipmentFee -
        newObj2.customsDuty) *
      newObj2.customsDutyRate *
      0.01
    );
  }, [revenueValue]);

  const profit = useMemo(() => {
    const total =
      revenueValue.purchasePriceWon +
      revenueValue.shipmentFee +
      vat +
      revenueValue.etcFee;

    return Math.round(revenueValue.adjustPrice - total);
  }, [revenueValue, vat]);

  return (
    <ModalBg
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ModalInnerTop width={900}>
        <>
          <div className="modal-sticky">
            <div className="modal-header">
              <h5 className="modal-title d-flex align-items-center">
                예상수익
              </h5>
              <button
                onClick={closeModal}
                type="button"
                className="btn-close"
              ></button>
            </div>
            <hr className="margin0" />
          </div>
          <div className="modal-body">
            <div className="textStart">
              <p className="margin0">
                ** 본 메뉴는 예상수익을 계산해볼 수 있는 계산기 입니다. 아래
                값을 입력가능한 값을 수정하시면 계산결과 값이 변경되어
                보여집니다.
              </p>
              <p className="margin0">
                ** 예상수익계산기에 보여지는 기본값은 수집된 대표상품의 금액 및
                통화, 해당 상품에 적용된 정책값(환율, 국제운송료, 마켓수수료),
                마켓에서 수집된 주문금액 기초합니다.
              </p>
              <p className="margin0">
                ** 자동 계산된 값은 실제 마진과는 다를 수 있으니 참고만 하시기
                바랍니다.
              </p>
            </div>
            <RevenueProductName name={revenueValue.productName} />
            <RevenueCurrency
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenuePurchasePrice
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenueOrderPrice orderPrice={revenueValue.orderPrice} />
            <RevenueOpenMarketFee
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
              setRevenueValue={setRevenueValue}
            />
            <RevenueDeliveryFee
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenueAdjustPrice
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenueShipment
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenueWeightFee
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenueCustomDuty
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenueVat
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
              vat={vat}
            />
            <RevenueEtcFee
              revenueValue={revenueValue}
              inputRevenueValue={inputRevenueValue}
            />
            <RevenueProfit profit={profit} />
            <RevenueUpdateButton
              profit={profit}
              closeModal={closeModal}
              revenueValue={revenueValue}
            />
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};

const REVENUE_DEFAULT = {
  adjustPrice: 0,
  currency: "",
  currencyRate: 0,
  customsDuty: 0,
  customsDutyRate: 0,
  deliveryFee: 0,
  etcFee: 0,
  idx: 0,
  mainImageUrl: "",
  openmarketFee: 0,
  openmarketFeePrice: 0,
  optionName: "",
  orderAmount: 0,
  orderPrice: 0,
  productName: "",
  profit: 0,
  purchasePriceOrigin: 0,
  purchasePriceWon: 0,
  shipmentFee: 0,
  weight: 0,
  weightFee: 0,
};

const changeNullToZero = (obj: any) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === null) {
      obj[key as keyof Revenue] = 0;
    }
  }

  return obj;
};
