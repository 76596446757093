interface Props {
  color?: string;
}

export const RightIcon = ({ color }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path data-name="패스 176" d="M0 0h24v24H0z" fill="none"></path>
      <path
        data-name="패스 177"
        d="M9.29 6.71a1 1 0 0 0 0 1.41L13.17 12l-3.88 3.88a1 1 0 1 0 1.41 1.41l4.59-4.59a1 1 0 0 0 0-1.41L10.7 6.7a1 1 0 0 0-1.41.01z"
        fill={color ? color : "#39415c"}
      ></path>
    </svg>
  );
};
