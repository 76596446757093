import { CollectValue, CollectWay } from "../../../interface/collectInterface";

import { SearchingTab } from "../CollectSearchingTab/SearchingTab";
import { TaobaoSearchingDuplicateSelect } from "../CollectTaobao/component/TaobaoSearchingDuplicateSelect";
import { TaobaoSearchingImgSelect } from "../CollectTaobao/component/TaobaoSearchingImgSelect";
import { TaobaoSearchingMemoInput } from "../CollectTaobao/component/TaobaoSearchingMemoInput";
import { TaobaoSearchingProductGroupNameInput } from "../CollectTaobao/component/TaobaoSearchingProductGroupNameInput";
import { TaobaoSearchingUrlInput } from "../CollectTaobao/component/TaobaoSearchingUrlInput";
import { ResetIcon } from "../../svg/ResetIcon";
import { SearchingIcon } from "../../svg/SeachingIcon";

interface Props {
  clickReset: () => void;
  collectFunction: (page: number) => Promise<void>;
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const CollectSearchingBoard = ({
  setCollectValue,
  clickReset,
  collectFunction,
  collectValue,
  inputCollectValue,
  collectWay,
  setCollectWay,
}: Props) => {
  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="tabs-col-btn">
          <SearchingTab
            clickReset={clickReset}
            setCollectWay={setCollectWay}
            collectWay={collectWay}
          />
          <div className="tab-content">
            <div className="first-product-tab serachingBoard shadow">
              <div className="row">
                <TaobaoSearchingProductGroupNameInput
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                  setCollectValue={setCollectValue}
                />
                <TaobaoSearchingUrlInput
                  collectWay={collectWay}
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
              </div>
              <div className="row mt-3">
                <TaobaoSearchingMemoInput
                  setCollectValue={setCollectValue}
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
                <TaobaoSearchingImgSelect
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
                <TaobaoSearchingDuplicateSelect
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
              </div>
              <hr className="hr1 mt-4" />
              <div className="row pt-3 pb-1 align-items-center justify-content-end">
                <div className="col-md-6 col-12">
                  <div className="search-btn d-flex justify-content-end gap-3 align-items-center">
                    <button
                      onClick={clickReset}
                      className="cancel-btn save-btn reset-btn"
                    >
                      <ResetIcon />
                      초기화
                    </button>
                    <button
                      onClick={() => collectFunction(1)}
                      className="cancel-btn save-btn"
                    >
                      <SearchingIcon />
                      {collectWay === "one" ? "수집" : "검색"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
