import { useEffect, useMemo, useState } from "react";
import { ResultItem } from "../../../../interface/orderManageInterface";
import { ItemOrderDate } from "./item/ItemOrderDate";
import { ItemProductInfo } from "./item/ItemProductInfo";
import { ItemProductOrderInfo } from "./item/ItemProductOrderInfo";
import { ItemExpectationRevenueButton } from "./item/ItemExpectationRevenueButton";
import { ItemOrderState } from "./item/ItemOrderState";
import smartStore from "@/assets/images/marketIcon/smartstore.svg";
import auction from "@/assets/images/marketIcon/auction.svg";
import gmarket from "@/assets/images/marketIcon/gmarket.svg";
import coupnag from "@/assets/images/marketIcon/coupang.svg";
import elevenst from "@/assets/images/marketIcon/elevenst.svg";
import { MarketEnglish } from "@/interface/uploadinterface";

interface Props {
  item: ResultItem;
  setSelectedOrder: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  selectedOrder: ResultItem[];
  orderList: ResultItem[];
  idx: number;
  setOrderList: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  refetchNowValue: () => void;
}

export const OrderManageTableItem = ({
  idx,
  item,
  selectedOrder,
  setSelectedOrder,
  orderList,
  setOrderList,
  refetchNowValue,
}: Props) => {
  const isSelect = useMemo(
    () => Boolean(selectedOrder.find((order) => order.idx === item.idx)),
    [selectedOrder]
  );

  const selectOrder = () => {
    if (isSelect) {
      const newArr = [...selectedOrder];
      setSelectedOrder(newArr.filter((order) => order.idx !== item.idx));
    } else {
      setSelectedOrder((prev) => [...prev, item]);
    }
  };

  return (
    <tr onClick={selectOrder}>
      <td className="width5">
        <div
          className={`collect-inner-1 div-collect 
          ${isSelect && "selectedItem"}`}
        >
          <div className="form-group">
            <input
              onClick={(e) => e.stopPropagation()}
              type="checkbox"
              id="htmlh"
              checked={isSelect}
              readOnly={true}
            />
            <label htmlFor="htmlh"></label>
          </div>
        </div>
      </td>
      <td className="width5">
        <div className={`collect-inner-1 ${isSelect && "selectedItem"}`}>
          <button
            className="madebutton"
            onClick={(e) => {
              e.stopPropagation();
              window.open(URL_INDEX[item.market as MarketEnglish], "_blank");
            }}
          >
            <img
              src={IMG_INDEX[item.market as MarketEnglish]}
              className="logoRound"
            />
          </button>
        </div>
      </td>
      <ItemProductInfo item={item} isSelect={isSelect} />
      <td className="width5">
        <div className={`collect-inner-1 ${isSelect && "selectedItem"}`}>
          <div>
            <p className="margin0">{item.ordererName}</p>
            <p className="margin0 orderCollectDay">({item.receiverName})</p>
          </div>
        </div>
      </td>
      <ItemExpectationRevenueButton item={item} isSelect={isSelect} />
      <ItemProductOrderInfo
        idx={idx}
        item={item}
        isSelect={isSelect}
        orderList={orderList}
        setOrderList={setOrderList}
      />
      <ItemOrderState
        isSelect={isSelect}
        idx={idx}
        orderList={orderList}
        setOrderList={setOrderList}
      />
      <ItemOrderDate
        item={item}
        isSelect={isSelect}
        refetchNowValue={refetchNowValue}
      />
    </tr>
  );
};
const IMG_INDEX = {
  SmartStore: smartStore,
  Coupang: coupnag,
  Elevenst: elevenst,
  Auction: auction,
  Gmarket: gmarket,
};

const URL_INDEX = {
  SmartStore: "https://sell.smartstore.naver.com/#/home/dashboard",
  Coupang: "https://wing.coupang.com/",
  Elevenst: "https://soffice.11st.co.kr/view/main",
  Auction: "https://www.esmplus.com/Home/Home#",
  Gmarket: "https://www.esmplus.com/Home/Home#",
};
