export const ReductionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        d="M21.71 20.29 18 16.61A9 9 0 1 0 16.61 18l3.68 3.68a.994.994 0 1 0 1.42-1.39zM11 18a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm4-8H7a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2z"
        transform="translate(6.021 6.021)"
        fill="#39415c"
      />
      <path data-name="사각형 3986" fill="none" d="M0 0h36v36H0z" />
    </svg>
  );
};
