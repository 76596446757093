import { CollectValue } from "@/interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectWay: string;
}

export const VvicSearchingUrl = ({
  collectValue,
  inputCollectValue,
  collectWay,
}: Props) => {
  return (
    <div className="product-label product-label-1 col-md-9 col-12">
      <label htmlFor="product-name1">
        {collectWay === "one" ? "URL" : "키워드"}
      </label>
      <input
        type="text"
        className="form-control"
        value={collectValue.url}
        name="url"
        onChange={inputCollectValue}
        placeholder={
          collectWay === "one"
            ? "URL을 입력해주세요."
            : "키워드를 입력해주세요."
        }
      />
    </div>
  );
};
