interface Props {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  children: string;
}

export const WhiteMiddleButton = ({
  onClick,
  children,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  return (
    <button
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      className="allttam-button button-middle white-border-button "
    >
      {children}
    </button>
  );
};
