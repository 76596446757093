export const SubscribeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="4">
        <path
          data-name="패스 10539"
          d="M22.644 8.031 20.7 9a8.694 8.694 0 1 1-3.967-3.945l.913-1.967A10.683 10.683 0 0 0 12.864 2a10.867 10.867 0 1 0 9.781 6.031z"
          transform="translate(2.088 2.132)"
          fill="#fff"
        />
        <path
          data-name="패스 10540"
          d="M16.292 7.467a6.52 6.52 0 1 0 1.543 1.543l1.652-1.663H22.3V5.173h-2.172V3h-2.174v2.815zm.576 5.314a4.4 4.4 0 1 1-2.174-3.728l-2.967 2.956a1.091 1.091 0 1 0 1.543 1.543l2.978-2.945a4.347 4.347 0 0 1 .62 2.174z"
          transform="translate(2.431 2.219)"
          fill="#fff"
        />
      </g>
      <path data-name="사각형 3926" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
