import { useEffect, useRef } from "react";

interface Props {
  closeModal: () => void;
}

export const TermsOfUse = ({ closeModal }: Props) => {
  const modalRef = useRef<any>(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);
  return (
    <div className="policy-modal" ref={modalRef}>
      <div className="policy-modal-sticky">
        <div className="policymodalHeader">
          <p>서비스이용약관</p>
          <button
            onClick={closeModal}
            type="button"
            className="btn-close"
          ></button>
        </div>
      </div>
      <p className="mt-5">제1조 (목적)</p>
      <p>
        본 약관은 이들(이하 ‘사업자’라 한다)이 개발, 운영, 제공하는 구매대행관리
        서비스 플랫폼(이하 ‘플랫폼’이라 한다)에 대한 웹 서비스(이하 ‘서비스’라
        한다)를 이용하는 자(‘회원’이라 칭한다)의 권리, 의무 및 책임 사항을
        규정함을 목적으로 한다.
      </p>

      <p className="mt-3">제2조 (정의)</p>
      <p>① 본 약관에서 사용하는 용어의 정의는 다음과 같다</p>
      <p>
        1. 서비스란 플랫폼에 가입한 회원에게 제공되는 플랫폼의 구매대행관리 웹
        서비스를 말한다.
      </p>
      <p>
        2. 플랫폼이란 회원이 서비스를 이용하기 위한 구매대행관리 서비스
        플랫폼으로써, 사업자가 개발하여 운영 및 회원에게 제공하는 것을 말한다.
      </p>
      <p>
        3. 회원이란 본 약관에 동의 및 플랫폼에 가입한 후, 서비스 이용을 위해
        사업자가 정하는 요금제를 이용하고 있는 자를 말한다.
      </p>
      <p>
        4. 요금제란 회원이 서비스 구독기간(이하 ‘이용기간’이라 한다)에 서비스를
        이용할 수 있도록, 사업자가 제공하는 요금 제도를 뜻한다.
      </p>
      <p>
        5. 이용기간이란 회원이 사업자에게 요금제에 따라 요금을 입금하고,
        서비스를 이용할 수 있는 유효한 기간을 의미한다.
      </p>
      <p>
        6. 코인제도란 회원이 사업자에게 코인 정책에 따라 대금을 입금하고,
        사용량에 따라 코인(이하 ‘코인’이라 한다)을 차감하는 형태로 일부 서비스를
        이용하도록 사업자가 제공하는 코인 제도를 뜻한다.
      </p>
      <p>
        ② 1항에서 규정한 것 외에 본 약관에서 사용하는 용어는 저작자 또는
        개발사의 플랫폼 사용권 동의, 관계 법령에서 정하는 바에 따르며 그 외에는
        일반 관례에 따른다.
      </p>
      <p className="mt-3">제3조 (약관 등의 명시와 설명 및 개정)</p>
      <p>
        ① 사업자는 이 약관의 내용, 사업자명, 대표자명, 사업자주소,
        사업자등록번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 회원
        가입 시 플랫폼에 게시하고, 회원은 동의를 함으로써 효력이 발생한다.
      </p>
      <p>
        ② 사업자는 관계 법령에 준수하여 이 약관의 내용을 개정할 수 있으며,
        개정약관은 플랫폼 이용 시 게시하고, 회원은 동의를 함으로써 효력이
        발생한다. 이때 사업자는 개정약관의 적용일자 7일 전부터 적용일자 전일까지
        공지한다.
      </p>
      <p>
        ③ 회원이 개정약관의 적용에 동의하지 않는 경우, 사업자는 제8조 1항에 따라
        회원에 대하여 서비스의 제공을 제한하거나 중지할 수 있다.
      </p>
      <p>
        ④ 이 약관에 정하지 아니한 사항과 약관의 해석에 관하여는 「약관의 규제에
        관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 및 관계
        법령에 따른다
      </p>
      <p className="mt-3">제4조 (회원가입)</p>
      <p>
        ① 회원은 사업자가 정한 방법에 따라 회원 정보 작성 및 약관 동의를
        함으로써 가입이 신청되며, 회원가입의 계약 성립 시기는 사업자의 승낙이
        회원에게 도달한 시점으로 본다.
      </p>
      <p>
        ② 사업자는 1항의 가입신청을 확인하고 회원으로 등록한다. 단, 다음 각 호의
        어느 하나에 해당하는 경우, 거절할 수 있다.
      </p>
      <p>가입신청 등록정보에 허위, 기재누락, 오기가 있는 경우</p>
      <p>이전에 회원 자격이 제한되거나 정지된 적이 있는 경우</p>
      <p>실명이 아니거나 타인의 명의를 이용한 경우</p>
      <p>
        기타, 사업자의 정책 및 기술에 있어 지장을 초래할 것으로 판단되는 경우
      </p>
      <p>
        ③ 1항에 따른 신청에 있어 사업자는 관련 전문기관을 통한 실명확인 및
        본인인증을 요청할 수 있다.
      </p>
      <p>
        ④ 사업자는 플랫폼 및 서비스 관련 설비의 여유가 없거나, 기술상 또는
        업무상 이슈가 있는 경우에는 승낙을 유보할 수 있다.
      </p>
      <p>
        ⑤ 2항과 4항에 따라 거절 및 승낙 유보에 해당이 될 경우, 사업자는 회원가입
        신청자에게 알리도록 한다.
      </p>
      <p className="mt-3">제5조 (서비스의 제공)</p>
      <p>① 요금제에 따른 국,내외 사이트의 정보를 수집하는 서비스</p>
      <p>② 1항에서 수집한 정보를 수정하는 서비스</p>
      <p>③ 2항에서 수정된 정보를 지정된 사이트에 게시하는 서비스</p>
      <p>④ 기타, 사업자가 정하는 서비스</p>
      <p className="mt-3">제6조 (서비스의 대금 및 결제방법)</p>
      <p>① 서비스의 대금은 사업자가 게시한 요금제에 따른다.</p>
      <p>
        ② 서비스의 결제방법은 사업자의 명의인 계좌로의 입금 및 카드 결제가
        있으며, 회원 입금 및 결제를 통해 서비스를 구매할 수 있다. 이때 사업자가
        정한 방법으로 진행할 수 있다.
      </p>
      <p>
        ③ 회원은 대금 결제방법에 있어 제2항의 방법이 아닌 다른 방법으로
        결제하였을 경우 이는 정당한 납부로 인정되지 아니한다. 회원이 이를
        위반하여 발생하는 문제에 대하여 사업자는 일체의 책임을 지지 아니하고
        회원이 전적인 책임을 지고 이를 해결하여야 한다.
      </p>
      <p>
        ① 사업자는 플랫폼의 기술상의 필요에 따라 서비스를 변경할 수 있다. 이때
        서비스의 변경 내용 및 적용일에 대해 공지하도록 한다.
      </p>
      <p>
        ② 사업자는 장차 체결되는 사업의 계약이 발생하는 경우, 서비스를 변경할 수
        있다. 이때 서비스의 변경 내용 및 적용일에 대해 공지하도록 한다.
      </p>
      <p>
        ③ 기타, 사업자가 변경이 필요하다고 판단되는 경우에는 서비스의 변경 내용
        및 적용일에 대해 공지하도록 한다.
      </p>
      <p className="mt-3">제8조 (서비스 이용 제한)</p>
      <p>
        ① 사업자는 회원의 서비스 이용 요청에 있어 다음 각 항의 어느 하나에
        해당하는 경우, 서비스의 제공을 거절 또는 중지할 수 있다.
      </p>
      <p>회원이 약관 또는 개정약관의 적용에 동의하지 않는 경우</p>
      <p>
        회원이 요청한 서비스의 제공이 회사의 경영상 또는 기술상의 이유로 현저히
        곤란한 경우
      </p>
      <p>
        회원이 불법 또는 부당한 목적을 위해 서비스를 이용하는 것으로 판단되는
        경우
      </p>
      <p>
        ② 1항에 따라 서비스의 제공을 거절 또는 중지하는 경우, 사업자는 회원에게
        거절의 사유 및 근거를 통지하여야 한다.
      </p>
      <p className="mt-3">제9조 (서비스의 중단)</p>
      <p>
        ① 사업자는 서버 등 정보통신설비의 보수 점검, 교체 및 고장, 통신 두절
        등의 기술 및 운영상 사유가 발생한 경우에는 서비스의 제공을 일시적으로
        중단할 수 있다.
      </p>
      <p>
        ② 사업자는 제1항의 사유로 서비스의 제공이 일시 중단될 경우, 이의 복구를
        지체없이 수행한다.
      </p>
      <p className="mt-3">제10조 (회원 탈퇴 및 자격 상실 등)</p>
      <p>
        ① 회원은 사업자에게 언제든지 회원의 탈퇴를 요청할 수 있으며 사업자는
        즉시 회원 탈퇴를 처리한다. 탈퇴한 회원은 이후 동일한 아이디로 다시
        회원가입을 할 수 없다.
      </p>
      <p>
        ② 회원이 다음 각 호의 어느 하나의 사유에 해당하는 경우, 사업자는 회원의
        자격을 제한하거나 정지시킬 수 있다.
      </p>
      <p>
        서비스를 이용하기 위하여, 회원가입 신청 시에 허위 내용을 등록한 경우
      </p>
      <p>
        서비스를 이용하기 위하여, 회원가입 신청 시에 허위 내용을 등록한 사정
        등으로 인하여 계정이 제한되거나 정지된 경우
      </p>
      <p>
        3. 다른 회원의 서비스 이용을 방해하거나, 서비스의 정보를 도용하는 등의
        서비스 이용 질서를 위협하는 경우
      </p>
      <p>
        ③ 제2항에 따라 사유에 해당하는 회원은 시정조치를 하여야 하며, 사업자는
        일정한 기간을 정하여 시정조치 요청을 할 수 있다. 이때 일정한 기간 내에
        시정되지 아니하는 경우 사업자는 회원의 자격을 상실시킬 수 있다.
      </p>
      <p>
        ④ 회원의 아이디 관리 소홀 등으로 인하여 이용자(상품구매고객)의
        개인정보가 제 3자에게 유출되었을 경우 이에 관한 모든 책임은 전적으로
        회원에게 있으며, 사업자는 회원의 자격을 제한하거나 정지시킬 수 있다.
      </p>
      <p>
        ⑤ 사업자가 회원의 자격을 상실시키는 경우에는 회원 등록을 말소한다. 이때
        회원에게 통지한다.
      </p>
      <p>
        ⑥ 회원의 탈퇴, 자격의 상실이 발생하는 경우, 회원의 개인정보는 개인정보
        처리방침에 따른다.
      </p>
      <p>
        ⑦ 사업자는 휴면회원에 대해 회원의 자격 상실에 대한 안내문을 제11조 게시
        및 공지의 방법에 따라 통지하고, 사업자가 지정한 기한 내에 회신이 없는
        경우에는 회원의 자격을 상실시킬 수 있다. 이때 회원의 개인정보는 개인정보
        처리방침에 따른다.
      </p>
      <p className="mt-3">제11조 (게시 및 공지의 방법)</p>
      <p>
        ① 사업자는 게시 및 공지의 방법으로서, 다음 각 항의 방법을 택할 수 있다.
      </p>
      <p>플랫폼 상의 게시 또는 공지</p>
      <p>2. 전자메일 또는 우편, SNS 등 발신 형태의 게시 또는 공지</p>
      <p>
        ② 회원은 플랫폼 상의 회원 정보수정 아래 1항의 2호에 대해 정보 수신
        거부를 할 수 있으나, 서비스 및 개정약관 등의 중대한 변경 사유가 있을
        시에 사업자는 1항에 따라 게시 및 공지할 수 있다.
      </p>
      <p className="mt-3">제12조 (개인 정보 보호)</p>
      <p>
        ① 사업자는 회원의 정보 수집 시 서비스 사용에 필요한 최소한의 정보를
        수집하여 이를 가공, 보관, 관리할 수 있다. 이 경우 사업자가 수집하는
        정보의 구체적인 항목과 대상, 범위 등에 관하여는 별도 고지하는
        개인정보취급방침에 따른다.
      </p>
      <p>
        ② 사업자가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시
        당해 이용자의 동의를 받는다.
      </p>
      <p>
        ③ 제공된 개인정보는 당해 회원의 동의 없이 목적 외의 이용이나 제 3자에게
        제공할 수 없으며 이에 대한 모든 책임은 사업자가 가진다. 다만 다음의
        경우에는 예외로 한다.
      </p>
      <p>
        1. 배송 업무상 배송업체에게 배송에 필요한 최소한의 회원의 정보 (성명,
        주소, 전화번호)를 알려 주는 경우
      </p>
      <p>
        2. 통계 작성, 학술 연구 또는 시장 조사를 위하여 필요한 경우로서 특정
        개인을 식별할 수 없는 형태로 제공되는 경우
      </p>
      <p>3. 재화 등의 거래에 따른 대금 정산을 위하여 필요한 경우</p>
      <p>4. 도용방지를 위하여 본인확인에 필요한 경우</p>
      <p>5. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</p>
      <p>
        ④ 사업자가 제 2항과 제 3항에 의해 회원의 동의를 받아야 하는 경우에는
        개인 정보 관리 책임자의 신원(성명 및 전화번호 기타 연락처), 정보의
        수집목적 및 이용목적, 제 3자에 대한 정보 제공 관련사항 (제공 받는 자,
        제공 목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호
        등에 관한 법률」 제 22조 제 2항이 규정한 사항을 미리 명시하거나 고지해야
        하며 회원은 언제든지 이 동의를 철회할 수 있다.
      </p>
      <p>
        ⑤ 회원은 언제든지 사업자가 가지고 있는 자신의 개인정보에 대해 열람 및
        오류정정을 요구할 수 있으며, 사업자는 이에 지체없이 필요한 조치를 취할
        의무를 가진다. 회원이 오류의 정정을 요구한 경우에 사업자는 그 오류를
        정정할 때까지 당해 개인정보를 이용하지 않는다.
      </p>
      <p>
        ⑥ 사업자 또는 그로부터 개인정보를 제공받은 제 3자는 개인 정보의 수집
        목적 또는 제공 받는 목적을 달성한 때에는 당해 개인정보를 지체 없이
        파기한다.
      </p>
      <p className="mt-3">제13조 (사업자의 의무)</p>
      <p>
        ① 사업자는 본 약관에 동의한 회원에게 한해 플랫폼의 서비스를 이용할 수
        있는 사용권을 부여한다.
      </p>
      <p>
        ② 사업자는 계속적이고 안정적인 서비스 제공을 위하여, 플랫폼 및 서버에
        장애가 생기거나 관련 자료가 멸실이 된 경우에는 부득이한 사유가 없는 한
        지체없이 이를 수리 또는 복구한다.
      </p>
      <p>
        ③ 사업자는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로
        인정되는 경우에는 적절한 절차를 거쳐 즉시 처리하여야 한다. 다만, 즉시
        처리가 곤란한 경우는 회원에게 그 사유와 처리일정을 통보하여야 한다.
      </p>
      <p>
        ④ 사업자는 서비스가 업데이트될 경우 즉시 회원에게 업데이트된 서비스를
        제공한다. 단, 업데이트의 상황에 따라 이미 제공된 서비스 기능의 일부를
        사용할 수 없거나 비용이 발생할 수 있다.
      </p>
      <p>
        ⑤ 사업자는 회원이 서비스 이용에 있어 상당한 업그레이드가 있는 경우에는
        그 내용을 회원에게 알릴 의무가 있다.
      </p>
      <p className="mt-3">제14조 (회원의 의무)</p>
      <p>
        ① 회원은 본 약관에서 규정하는 사항과 사업자가 정한 제반 규정, 공지사항
        및 관계 법령을 준수하여야 하며, 사업자의 업무에 방해가 되는 행위와
        사업자의 명예를 훼손시키는 행위를 해서는 안된다.
      </p>
      <p>
        ② 회원은 사업자가 제공하는 플랫폼과 서비스를 이용해야 하며, 다른
        방법으로 이용하거나 해당 서버 등에 접근을 시도해서는 안된다.
      </p>
      <p>③ 사용, 복사, 변경에 대한 제한</p>
      <p>
        1. 약관에서 명시적으로 허용된 경우를 제외하고 플랫폼 및 서비스의 전부
        또는 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보 제공하거나 타인에게
        그 이용을 허락하는 행위와 플랫폼 및 서비스를 역설계하거나 소스 코드의
        추출을 시도하는 등 플랫폼 및 서비스를 복제, 분해 또는 모방하거나 기타
        변형행위를 해서는 안된다.
      </p>
      <p>2. 서비스를 이용해 2차 저작물을 만들 수 없다.</p>
      <p>
        3. 서비스에서 지적재산권의 귀속에 관한 표시, 마크, 라벨을 제거해서는
        안된다.
      </p>
      <p>4. 기타 불법한 행위를 해서는 안된다.</p>
      <p>
        ④ 회원은 사업자의 사전 승낙 없이 플랫폼 및 서비스를 이용하여 영업활동을
        할 수 없으며, 그 영업활동의 결과에 대해 사업자는 책임을 지지 않는다.
        또한 회원은 이와 같은 영업활동으로 사업자가 손해를 입은 경우 사업자에
        대해 손해배상 의무를 지며, 사업자는 해당 회원에 대해 플랫폼 및 서비스
        이용 제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있다.
      </p>
      <p>
        ⑤ 회원은 플랫폼 및 서비스를 이용하여 사업자 및 제 3자의 명예를
        훼손하거나, 악성코드 기타 반사회적인 산출물을 작성하는 행위, 또는 기타
        법령 또는 사회상규에 위반되는 행위를 해서는 안된다.
      </p>
      <p>
        ⑥ 회원은 사업자 및 제 3자의 지적 재산권을 침해하는 행위를 해서는 안된다.
      </p>
      <p>
        ⑦ 회원의 행위에 대한 모든 책임은 회원이 부담하며, 회원은 사업자를
        대리하는 것으로 오인케 하는 행위를 해서는 안된다.
      </p>
      <p>
        ⑧ 플랫폼의 ID와 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은
        자신의 ID 및 비밀번호를 제 3자에게 이용하게 해서는 안된다.
      </p>
      <p>
        ⑨ 회원은 자신의 ID 및 비밀번호를 도난 당하거나 제 3자가 사용하고 있음을
        인지한 경우에는 바로 사업자에게 알리고 사업자의 안내를 따라야 한다.
      </p>
      <p>
        ⑩ 회원의 ID 및 비밀번호 관리 부주의 등으로 인해 발생한 손해에 대해
        사업자는 책임을 부담하지 않는다.
      </p>
      <p className="mt-3">제15조 (책임제한 및 서비스에 대한 보증의 한계)</p>
      <p>
        ① 사업자는 서비스를 통하여 국내외사이트 또는 개발사가 제공하는 콘텐츠
        등의 내용의 정확성 등의 여부에 대하여 보증을 하지 아니한다.
      </p>
      <p>
        ② 서비스의 이용에 따른 모든 위험은 회원이 부담한다. 사업자는 관련 법률이
        허용하는 최대한의 범위 내에서 상품성, 특정 목적에 대한 적합성,
        지적재산권 또는 지적재산권의 비침해성에 대한 묵시적 보증 등을 포함한
        명시적 또는 묵시적인 모든 보증을 배제한다.
      </p>
      <p>
        ③ 사업자는 사업자가 정한 이용기준을 준수하지 않은 이용으로 인한 결과에
        대하여 책임을 지지 않는다.
      </p>
      <p>
        ④ 사업자는 서비스에 포함된 기능이 회원의 요구사항을 모두 충족시키거나,
        컴퓨터의 사용에 있어 일시적인 간섭이나 오류가 발생하지 않을 것을
        보증하지 않는다.
      </p>
      <p>
        ⑤ 회원이 플랫폼 및 서비스를 이용할 경우, 회원의 PC상에 의도하거나
        예상하지 못한 장애(통신장애, 컴퓨터의 기능 저하, 마비 또는 오동작 등)가
        발생할 수 있으며, 회원은 이러한 사항을 충분히 고려하여 서비스의 사용을
        결정하여야 한다. 이러한 장애 가능성에도 불구하고 회원이 서비스를
        사용함으로써 발생하는 장애에 대하여 사업자는 책임을 부담하지 않는다.
      </p>
      <p>
        ⑥ 사업자는 서비스를 제공한 이후에 제조된 컴퓨터 하드웨어와 컴퓨터
        운영체제의 변경에 따라서 발생하는 문제에 대해 책임지지 않는다.
      </p>
      <p>
        ⑦ 사업자는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한
        것에 대하여 책임을 지지 않는다.
      </p>
      <p>
        ⑧ 사업자는 회원 상호 간 및 회원과 제 3자 상호 간에 서비스를 매개로
        발생한 분쟁에 대해 개입하지 않으며, 이로 인한 손해에 대해 배상하지
        않는다.
      </p>
      <p>
        ⑨ 서비스와 관련하여 사업자가 부담할 모든 책임과 회원이 사업자로부터 받을
        수 있는 유일한 구제 방법은 사업자의 재량에 따라
      </p>
      <p>
        1. 사업자의 제한적 보증을 충족시키지 못하는 본 플랫폼의 수리 또는 교체
      </p>
      <p>2. 본 계약의 해지와 사용기간 환불 중 1가지로 한정된다.</p>
      <p>
        ⑩ 손해배상에 대한 면책 규정: 본 약관에 규정된 구제 조치가 그 목적을
        달성했는지 여부를 불문하고, 사업자는 본 약관에서 명시된 것을 제외하고는
        관련 법률이 허용하는 최대한의 범위 내에서 본 플랫폼의 사용/사용 불능
        또는 이용자지원 서비스의 제공이나 그 불이행으로 야기된 모든 손해(결과적,
        우연적, 간접적, 특별, 경제적, 징벌적 또는 기타 이와 유사한 모든 손해
        또는 사업상 이익의 손실, 영업권의 손실, 사업에 대한 간섭, 컴퓨터의 기능
        마비 또는 오동작, 사업 정보의 손실 또는 기타 제반 상업적 또는 금전적
        손해 또는 손실 등을 포함)에 대해 책임을 지지 않는다. 상기 사항과
        관련하여 전술된 손해 등에 대한 회원의 청구가 약관위반, 불법행위 등
        어떠한 책임의 법리를 권원으로 하는지 불문하며, 사업자가 상기 손해발생의
        가능성에 대해 사전 통보를 받았거나 그와 같은 손해의 가능성을 사전에 알고
        있었을 경우에도 사업자의 고의 또는 중대한 과실이 없는 한 사업자는 상기
        손해 등에 대해 책임을 지지 않으며, 제 3자가 주장하는 어떠한 배상 청구에
        대해서도 책임을 지지 않는다. 회원은 본 약관의 동의에 이미 이러한 위험
        부담이 반영되어 있다는 사실을 인지한 것으로 간주한다.
      </p>
      <p>
        ⑪ 사업자는 회원이 플랫폼을 이용하여 업로드한 상품에 대해서는 어떠한
        책임을 지지 않으며, 업로드한 상품에서 생기는 모든 책임은 회원에게 있다.
      </p>
      <p>
        ⑫ 사업자는 회원의 서비스 이용기간이 만료된 이후, 회원이 이용기간 내에
        사용했던 상품정보를 삭제할 수 있다. 회원의 서비스 이용기간과 회원의 코인
        유무는 상관관계가 없으며. 사업자는 상품정보 삭제로 인해 발생하는 문제에
        대해 책임지지 않는다.
      </p>
      <p className="mt-3">제16조 (계약의 취소 및 환불)</p>
      <p>
        ① 요금제에 따른 서비스 이용 대금을 결제한 시점부터 일정 기간 안에 서비스
        이용 계약을 취소하고 환불을 요청할 수 있으며, 다음의 각 항과 따른다.
      </p>
      <p>1개월 이용기간을 가진 요금제의 경우에는, 결제한 시점부터 14일 이내</p>
      <p>
        1개월 초과 이용기간을 가진 요금제의 경우에는, 결제한 시점부터 30일 이내
      </p>
      <p>코인제도에 따라 코인을 결제한 경우에는, 결제한 시점부터 14일 이내</p>
      <p>
        ② 1항에 따라 결제한 시점으로부터 일정 기간이 지난 경우에는 계약의 취소
        및 환불을 요청할 수 없으며, 사업자는 서비스 계약의 취소 및 환불을 거부할
        수 있는 권리를 가진다.
      </p>
      <p>
        ③ 코인을 결제한 이후 코인을 일부 사용했을 경우에는 환불을 요청할 수
        없다.
      </p>
      <p>④ 증정 및 할인 프로모션을 통해 구매한 코인은 환불을 요청할 수 없다.</p>
      <p>제17조 (계약의 해지 및 손해배상)</p>
      <p>
        ① 사업자는 회원이 이용약관을 준수하지 않는 경우 본 사용권 계약을 해지할
        수 있다. 이 경우 사업자는 이용기간의 남은 일수와 상관없이 계약의 환불을
        거부할 수 있는 권리를 가진다.
      </p>
      <p>
        ② 사업자는 제휴 관계의 종료, 기타 서비스를 지속할 수 없는 중대한 사업적
        이유 등을 원인으로 하여 서비스의 제공을 중지할 수 있으나, 회원의
        이용기간은 유지되도록 한다.
      </p>
      <p>
        ③ 회원에 의하여 사업자에 손해가 발생한 경우 사업자는 사용권 계약의
        해지와는 별도로 회원에게 손해배상을 청구할 수 있다.
      </p>
      <p>제18조 (관할법원 및 준거법)</p>
      <p>
        ① 사업자와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에
        발생한 소송은 제소 당시의 사업자의 주소에 의한다. 회원이 외국 거주자의
        경우에는 민사소송법상의 관할법원에 제기한다.
      </p>
      <p>
        ② 사업자와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에
        발생한 소송의 준거법은 대한민국법으로 한다.
      </p>
      <div className="d-flex justify-content-end">
        <button
          onClick={closeModal}
          className="mt-4 mb-3 policy-confirm-button madebutton"
        >
          확인
        </button>
      </div>
    </div>
  );
};
