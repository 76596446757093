import { ComponentType, IndexType } from "@/interface/fqa.interface";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import {
  helperComponent,
  helperPrevComponentList,
  isHelper,
} from "@/atom/atom";

const useSelectHelperComponent = () => {
  const [isOpenHelper, setIsOpenHelper] = useRecoilState<boolean>(isHelper);
  const [component, setComponent] =
    useRecoilState<ComponentType>(helperComponent);
  const [prevList, setPrevList] = useRecoilState(helperPrevComponentList);

  const selectComponent = useCallback(
    (type: IndexType, id: string) => {
      setIsOpenHelper(true);
      setPrevList((prev) => [...prev, component]);
      setComponent({
        type,
        id,
      });
    },
    [component]
  );

  return selectComponent;
};

export default useSelectHelperComponent;
