import { ProductManageSearchingValue } from "@/interface/productmanageinterface";

interface Props {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  value: ProductManageSearchingValue;
}

export const InputBoxProductCode = ({ onChange, value }: Props) => {
  return (
    <div className="product-label product-label-1 col-md-3 col-12 width-devide-1 pe-2 mobile-dp-none">
      <label htmlFor="codie">상품코드 복수 검색</label>
      <input
        value={value.code}
        onChange={onChange}
        type="text"
        className="form-control"
        id="codie"
        aria-describedby="emailHelp"
        placeholder="쉼표(,)로 구분하여 코드 복수 입력이 가능합니다."
        name="code"
      />
    </div>
  );
};
