import { CustomRateItem } from "@/interface/uploadinterface";

interface Props {
  exchangeRateValue: { exchangeCNYBase: number; exchangeUSDBase: number };
  setExchangeRateValue: React.Dispatch<
    React.SetStateAction<{
      exchangeCNYBase: number;
      exchangeUSDBase: number;
    }>
  >;
  basePrice: number;
  customRate: CustomRateItem;
  item: {
    id: number;
    country: string;
    currency: string;
    symbolCode: string;
    currSgn: string;
    base: string;
  };
}

export const ExchangeRateTableItem = ({
  setExchangeRateValue,
  exchangeRateValue,
  basePrice,
  item,
  customRate,
}: Props) => {
  const inputExchangeRateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setExchangeRateValue({
      ...exchangeRateValue,
      [name]: value,
    });
  };

  return (
    <tr>
      <td>
        <div className="weight500 text14">{item.currency}</div>
      </td>
      <td>
        <div className="weight500 text14">{basePrice}</div>
      </td>
      <td>
        <div className="weight500 text14">
          {customRate
            ? item.country === "일본"
              ? (parseFloat(customRate.imexTp) * 100).toFixed(2)
              : customRate?.imexTp
            : 0}
        </div>
      </td>
      <td>
        <div>
          <input
            onChange={inputExchangeRateValue}
            value={
              item.base === "none"
                ? "준비중"
                : exchangeRateValue[
                    item.base as "exchangeUSDBase" | "exchangeCNYBase"
                  ]
            }
            placeholder={item.base === "none" ? "준비중" : ""}
            name={item.base}
            type="number"
            disabled={item.country === "유럽" || item.country === "일본"}
          />
        </div>
      </td>
    </tr>
  );
};
