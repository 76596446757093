import { lazy, useEffect, useState } from "react";
import NavBar from "@/components/NavBar/NavBar";
import { NavManageOrder } from "@/components/NavBar/component/NavManageOrder";
import { OrderManageCS } from "../components/Ordermanage/OrderManageCS/OrderManageCS";
import { OrderManageOrder } from "../components/Ordermanage/OrderManageOrder/OrderManageOrder";
import { useNavigate } from "react-router-dom";
import chromeAPI from "../utils/functions/chromeAPI";
import "@/styles/OrderManage.css";
import { useRecoilValue } from "recoil";
import { isDeskTop } from "@/atom/atom";

export const OrderManage = () => {
  const [navState, setNavState] = useState<"order" | "cs">("order");
  const navigate = useNavigate();
  const isDesktop = useRecoilValue(isDeskTop);
  const checkExtension = async () => {
    const res = await chromeAPI.exCheck();
    return res.data;
  };

  useEffect(() => {
    isDesktop &&
      checkExtension()
        .then((res) => {})
        .catch(() => {
          navigate("/home");
        });
  }, []);

  return (
    <div className="orderManage">
      <NavBar>
        <NavManageOrder setNavState={setNavState} navState={navState} />
      </NavBar>
      {TAB_INDEX[navState as "cs" | "order"]}
    </div>
  );
};

const TAB_INDEX = {
  order: <OrderManageOrder />,
  cs: <OrderManageCS />,
};

export default OrderManage;
