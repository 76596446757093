import { GreenBigButton } from "@/components/Button/allttamButton/GreenBigButton";
import { WhiteBigButton } from "@/components/Button/allttamButton/WhiteBigButton";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import { DefaultInfo } from "@/interface/uploadinterface";
import { upload } from "@/utils/functions/postApi";
import { useQueryClient } from "react-query";

interface Props {
  closeModal: () => void;
  model: string;
  fetchData: DefaultInfo | undefined;
  queryKey: string;
}

export const DetailPageSetButton = ({
  queryKey,
  closeModal,
  fetchData,
  model,
}: Props) => {
  const openWindow = useInfoWindow();
  const queryClient = useQueryClient();

  const confirmSetDetailPage = async () => {
    const setRes = await upload.editDefaultInfo({
      ...fetchData,
      detailPageHtml: model,
    });
    if (setRes === "성공하였습니다") {
      openWindow("상세페이지 내용을 저장했습니다.");
      queryClient.invalidateQueries(queryKey);
      closeModal();
    } else {
      openWindow("저장에 실패했습니다.");
    }
  };
  return (
    <div className="d-flex align-items-center justify-content-center gap-3 mt-1">
      <WhiteBigButton onClick={closeModal}>취소</WhiteBigButton>
      <GreenBigButton onClick={confirmSetDetailPage}>저장</GreenBigButton>
    </div>
  );
};
