import { GreenBigButton } from "@/components/Button/allttamButton/GreenBigButton";
import { WhiteBigButton } from "@/components/Button/allttamButton/WhiteBigButton";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import {
  DetailOptionType,
  OptionGroupType,
  UploadSummary,
} from "@/interface/uploadinterface";
import { upload } from "@/utils/functions/postApi";
import { UseMutationResult, useQueryClient } from "react-query";

interface Props {
  optionList: any;
  optionGroups: OptionGroupType[];
  detailOptionList: DetailOptionType[];
  item: UploadSummary;
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
  closeModal: () => void;
}

export const OptionSetButton = ({
  closeModal,
  optionList,
  optionGroups,
  detailOptionList,
  item,
  collectProductSummary,
}: Props) => {
  const queryClient = useQueryClient();
  const openWindow = useInfoWindow();

  const setOption = async () => {
    const newObj = { ...optionList };
    delete newObj.Id;
    newObj.option.optionGroups = optionGroups;
    newObj.option.detailOptions = detailOptionList;
    const setOptionRes = await upload.editDefaultInfo({
      ...newObj,
      price: [
        newObj.price.filter((item: any) => item.currency !== "KRW")[0],
        newObj.price.filter((item: any) => item.currency === "KRW")[0],
      ],
    });

    if (setOptionRes === "성공하였습니다") {
      queryClient.invalidateQueries(`productOption${item.code}`);
      collectProductSummary.mutate();
      openWindow("저장되었습니다.");
      closeModal();
    } else {
      openWindow("오류가 발생했습니다.");
    }
  };

  return (
    <div className="d-flex gap-3 mt-4 justify-content-center">
      <WhiteBigButton onClick={closeModal}>취소</WhiteBigButton>
      <GreenBigButton onClick={setOption}>저장</GreenBigButton>
    </div>
  );
};
