import SideBar from "@/components/Sidebar/SideBar";
import useToggle from "@/hooks/useToggle";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { modalAtom } from "./atom/modalAtom";
import { GlobalModal } from "@/components/shared/modal/GlobalModal";
import { createPortal } from "react-dom";
import { lazy, useEffect, useState } from "react";
import { allttamCare, isDeskTop } from "./atom/atom";

const AllttamCare = lazy(() => import("@/components/AllttamCare/AllttamCare"));
const AllttamHelper = lazy(
  () => import("@/components/AllttamHelper/AllttamHelper")
);

const SideBarRouter = () => {
  const { state: isNav, handleToggle: handleIsNav } = useToggle(true);
  const { isOpen } = useRecoilValue(modalAtom);
  const $root_portal = document.getElementById("modal-portal");
  const [isAllttamCaresOpen, setIsAllttamCaresOpen] = useState<boolean>(false);
  const isDesktop = useRecoilValue(isDeskTop);
  const isOpenCare = useRecoilValue(allttamCare);

  useEffect(() => {
    isOpenCare && setIsAllttamCaresOpen((prev) => !prev);
  }, [isOpenCare]);

  const handleClickOpenAllttamCare = () => {
    setIsAllttamCaresOpen((prev) => !prev);
  };

  return (
    <div
      className={`main-hero-sec stickyBgHeight ${
        isNav ? "desktop-nav-active" : ""
      }`}
    >
      {$root_portal != null && isOpen
        ? createPortal(<GlobalModal />, $root_portal)
        : null}
      <SideBar isNav={isNav} handleIsNav={handleIsNav as () => void} />
      <div className={`heroSec notification`}>
        <div className="contentContainer">
          <Outlet />
          <AllttamCare activeToggle={isAllttamCaresOpen} />
          <AllttamHelper activeToggle={isAllttamCaresOpen} />
          {isDesktop && (
            <button
              className="allttam-all-btn"
              onClick={handleClickOpenAllttamCare}
            >
              <div className="allttam-all-img-layout">
                <img
                  src={
                    "https://center.allttam.com/api/media/banners/allttamHelper%26SimbolClose1722340665112.png"
                  }
                  alt="/"
                  className={`allttam-close-img ${
                    isAllttamCaresOpen ? "open" : "close"
                  }`}
                />
                <img
                  src={
                    "https://center.allttam.com/api/media/banners/allttamHelper%26Simbol1722337277736.png"
                  }
                  alt="/"
                  className={`allttam-all-img ${
                    isAllttamCaresOpen ? "close" : "open"
                  }`}
                />
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBarRouter;
