import { useState } from "react";
import {
  AliItem,
  CollectValue,
  CollectWay,
} from "@/interface/collectInterface";
import { collect } from "@/utils/functions/postApi";
import { useAlert } from "@/hooks/useAlert";
import chromeAPI from "@/utils/functions/chromeAPI.js";
import { AliCollectItem } from "./component/AliCollectItem";
import { toScrollTop } from "@/utils/functions/toScrollTop";
import { Spinner } from "@/components/Spinner/Spinner";
import { MultiSelectedCollect } from "@/components/MultiSelectedCollect/MultiSelectedCollect";
import { CollectMultiSubMenu } from "../CollectTaobao/component/TaobaoMultiSubMenu";
import { AlertOnlyClose } from "@/components/Alert/AlertOnlyClose";
import { AliSearchingBoard } from "./component/AliSearchingBoard";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import isValidUrl from "@/utils/functions/isValidUrl";
import { useRecoilState } from "recoil";
import { refetchIntervalLoading } from "@/atom/atom";
import { regex } from "@/utils/regex";

interface Props {
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  COLLECT_VALUE_DEFAULT: CollectValue;
}

export const CollectAli = ({
  collectWay,
  setCollectWay,
  COLLECT_VALUE_DEFAULT,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const [collectValue, setCollectValue] = useState<CollectValue>(
    COLLECT_VALUE_DEFAULT
  );
  const [pageData, setPageData] = useState<any>();
  const [selectedCollectItem, setSelectedCollectItem] = useState<string[]>([]);
  const [collectItemList, setCollectItemList] = useState<AliItem[]>([]);

  const openWindow = useInfoWindow();
  const [refetchInterval, setRefetchInterval] = useRecoilState(
    refetchIntervalLoading
  );

  const inputCollectValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCollectValue({
      ...collectValue,
      [name]: value,
    });
  };

  const clickReset = () => {
    setCollectValue(COLLECT_VALUE_DEFAULT);
  };
  const clickCollect = async (page: number) => {
    if (collectValue.groupName === "") {
      return openWindow(
        "상품 그룹명을 선택해 주세요.\n선택된 상품 그룹명이 없습니다."
      );
    }
    if (collectValue.url === "") {
      return openWindow(
        "상품 URL을 입력해 주세요.\n입력된 상품 URL이 없습니다."
      );
    }

    if (collectWay === "one") {
      try {
        if (!isValidUrl(collectValue.url)) {
          openWindow("검색 결과가 없습니다.\n검색 결과 없음");
          return;
        }
        if (!collectValue.url.includes("item")) {
          openWindow("상품 URL을 다시 입력해주세요.\n잘못된 URL 양식입니다.");
          return;
        }
        const collectRes = await collect.collectAliUsingApi(collectValue);
        if (collectRes.result) {
          const workNum = Number(collectRes.result.replace(regex.numRegex, ""));
          setRefetchInterval([
            ...refetchInterval,
            { workNum: workNum, isLoading: true },
          ]);
          openWindow(
            `상품 수집을 시작합니다.\n작업번호 ${collectRes.result
              .split(" ")[1]
              .slice(0, 5)} , 상품 1건`
          );
        }
      } catch (e: any) {
        openWindow(e.response.data.result);
      }
    } else {
      setIsLoading(true);
      const collectRes = await chromeAPI.aliexpressItemSearch(
        collectValue.url,
        page
      );

      if (collectRes.responseMsg) {
        setCollectItemList(
          collectRes?.responseMsg?.data?.root?.fields?.mods?.itemList?.content
        );
        setPageData(collectRes?.responseMsg?.data?.root?.fields?.pageInfo);
        setIsLoading(false);
      } else {
        if (collectValue.url.includes("item")) {
          openWindow("상품 URL을 다시 입력해주세요.\n잘못된 URL 양식입니다.");
          setIsLoading(false);
          return;
        }
        openWindow("검색 결과가 없습니다.\n검색 결과 없음");
        setCollectItemList([]);
        setPageData(undefined);
        setIsLoading(false);
      }
    }
  };
  const clickCollectMulti = async () => {
    try {
      if (collectValue.groupName !== "") {
        const collectRes = await collect.collectAliUsingExtenstion({
          duplicate: collectValue.duplicate,
          groupName: collectValue.groupName,
          imageIndex: collectValue.imageIndex,
          itemIds: selectedCollectItem,
          memo: collectValue.memo,
          translate: collectValue.translate,
          url: collectValue.url,
        });
        if (collectRes.result) {
          const workNum = Number(collectRes.result.replace(regex.numRegex, ""));
          setRefetchInterval([
            ...refetchInterval,
            { workNum: workNum, isLoading: true },
          ]);
          openWindow(
            `상품 수집을 시작합니다.\n작업번호 ${collectRes.result
              .split(" ")[1]
              .slice(0, 5)} , 상품 ${selectedCollectItem?.length}건`
          );
          resetSelect();
        }
      } else {
        openWindow(
          "상품 그룹명을 선택해 주세요.\n선택된 상품 그룹명이 없습니다."
        );
      }
    } catch (e: any) {
      openWindow(e.response.data.result);
    }
  };

  const resetSelect = () => {
    setSelectedCollectItem([]);
  };

  const clickNextPage = async () => {
    clickCollect(pageData.page + 1);
    toScrollTop();
  };

  const clickPrevPage = async () => {
    clickCollect(pageData.page - 1);
    toScrollTop();
  };

  const clickPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    clickCollect(parseInt(e.currentTarget.value));
    toScrollTop();
  };

  return (
    <>
      <AliSearchingBoard
        inputCollectValue={inputCollectValue}
        collectValue={collectValue}
        collectWay={collectWay}
        setCollectWay={setCollectWay}
        clickCollect={clickCollect}
        clickReset={clickReset}
        setCollectValue={setCollectValue}
      />
      {isLoading && (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      )}
      {!isLoading &&
        collectWay === "multi" &&
        collectItemList?.length !== 0 && (
          <>
            <MultiSelectedCollect
              clickPage={clickPage}
              totalPages={Math.floor(pageData.totalResults / pageData.pageSize)}
              currentPage={pageData?.page!}
              clickNextPage={clickNextPage}
              clickPrevPage={clickPrevPage}
              resetSelect={resetSelect}
              clickCollectMulti={clickCollectMulti}
              length={selectedCollectItem?.length}
            />
            <CollectMultiSubMenu
              itemList={collectItemList}
              setSelectedCollectItem={setSelectedCollectItem}
              selectedCollectItem={selectedCollectItem}
              totalData={pageData?.totalResults}
              site="ali"
            />
            <div className="row justify-content-center">
              {collectItemList?.map((item, idx) => (
                <AliCollectItem
                  key={item.productId}
                  item={item}
                  setSelectedCollectItem={setSelectedCollectItem}
                  selectedCollectItem={selectedCollectItem}
                />
              ))}
            </div>
          </>
        )}
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </>
  );
};
