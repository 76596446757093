import { useEffect, useState } from "react";
import {
  MarketAccount,
  ProdcutManageResultItem,
  ProductManageSearchingValue,
} from "@/interface/productmanageinterface";
import { ModalBg } from "@/components/ModalLayout/ModalBg";
import { ProductEditModalBody } from "./ProductEditModalBody";
import { useInput } from "@/hooks/useInput";
import { usePost } from "@/hooks/usePost";
import { ProductEditResult } from "./ProductEditResult";
import { ModalInnerTop } from "@/components/ModalLayout/ModalInnerTop";
import { Keyword } from "@/interface/uploadinterface";
import { UseMutationResult } from "react-query";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import ModalContent from "@/components/shared/modal/ModalContent";

interface Props {
  selectedItem: ProdcutManageResultItem[];
  searchingValue: ProductManageSearchingValue;
  marketAccountValue: MarketAccount | undefined;
  getSearchResult: UseMutationResult<any, unknown, void, unknown>;
}

export const ProductEditModal = ({
  selectedItem,
  searchingValue,
  marketAccountValue,
  getSearchResult,
}: Props) => {
  const openWindow = useInfoWindow();

  useEffect(() => {
    return () => {
      getSearchResult.mutate();
    };
  }, []);

  const [productTitleValue, setProductTitleValue] = useState(
    selectedItem.map((item) => {
      return {
        objectId: item.id,
        code: item.code,
        title: item.title.filter((item) => item.language === "KR")[0].text,
      };
    })
  );

  const [editCheckBoxValue, setEditCheckBoxValue] = useState({
    name: false,
    price: false,
    detail: false,
    tag: false,
  });

  const [isEdit, setIsEdit] = useState({
    salePrice: false,
    deliverPrice: false,
    detailPage: false,
  });

  const [deliveryPrice, inputDeleveryPrice] = useInput({
    deliveryFee: 0,
    returnFee: 0,
  });

  const [salePrice, inputSalePrice] = useInput({ type: "", value: 0 });

  const [selectedTag, setSelectedTag] = useState<string[]>([]);

  const submitProductEdit = usePost(
    "/ProductManagement/MarketProductSimpleEdit",
    {
      market:
        marketAccountValue?.market === "SmartStore"
          ? "SmartStoreAPI"
          : marketAccountValue?.market,
      marketAccount: marketAccountValue?.account?.account,
      productTitleChange: editCheckBoxValue.name
        ? productTitleValue
        : selectedItem.map((item) => {
            return {
              objectId: item.id,
              code: item.code,
              title: item.title.filter((item) => item.language === "KR")[0]
                .text,
            };
          }),
      productPriceChange: isEdit.salePrice ? salePrice : null,
      productDeliveryPriceChange: isEdit.deliverPrice ? deliveryPrice : null,
      productTagChanges: selectedTag.map((item) => item),
      isEditDetailPage: editCheckBoxValue.detail,
    }
  );

  const setFunction = () => {
    if (isEdit.salePrice && (salePrice.type === "" || salePrice.value === 0)) {
      openWindow("판매가 수정의 변경타입과 값을 입력해주세요");
    } else {
      submitProductEdit.mutate();
    }
  };

  return (
    <ModalContent width={1000}>
      <ModalContent.ModalHead>
        <h5
          className="modal-title d-flex align-items-center"
          id="exampleModalLabel"
        >
          상품 수정{" "}
          <span className="three-selected">
            선택상품 {selectedItem?.length}개
          </span>
        </h5>
      </ModalContent.ModalHead>
      <ProductEditResult
        isSuccess={submitProductEdit.isSuccess}
        isLoading={submitProductEdit.isLoading}
        data={submitProductEdit.data}
      />
      {!submitProductEdit.isLoading && !submitProductEdit.isSuccess && (
        <ProductEditModalBody
          setProductTitleValue={setProductTitleValue}
          setIsEdit={setIsEdit}
          inputDeleveryPrice={inputDeleveryPrice}
          inputSalePrice={inputSalePrice}
          submitProductEdit={submitProductEdit}
          selectedItem={selectedItem}
          isEdit={isEdit}
          productTitleValue={productTitleValue}
          salePrice={salePrice}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          deliveryPrice={deliveryPrice}
          setEditCheckBoxValue={setEditCheckBoxValue}
          editCheckBoxValue={editCheckBoxValue}
          setFunction={setFunction}
        />
      )}
    </ModalContent>
  );
};
