export const SqueareMinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g data-name="그룹 9820">
        <path
          data-name="패스 10357"
          d="m0 0 7.793 7.793"
          fill="none"
          stroke="#d62828"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          transform="rotate(-45 17.73 -1.833)"
        />
      </g>
      <path data-name="사각형 3654" fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};
