import { DeliveryInfo } from "../OrderInfoModal";

interface Props {
  orderBill: DeliveryInfo;
}

export const OrderInfoTable = ({ orderBill }: Props) => {
  return (
    <table className="orderInfoTable" width="">
      <thead>
        <tr>
          <th className="oneLine">사진</th>
          <th className="oneLine">제품명</th>
          <th className="oneLine">옵션</th>
          <th className="oneLine">단가</th>
          <th className="oneLine">수량</th>
          <th className="oneLine">합계</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className="d-flex align-items-center justify-content-center">
              <img
                className="orderInfoImg rounded-md"
                src={orderBill?.mainimageUrl}
              />
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center">
              {orderBill?.productName}
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center">
              {orderBill?.optionName}
            </div>
          </td>
          <td className="oneLine">
            <div className="d-flex align-items-center">
              {orderBill?.unitPrice.toLocaleString("KR")}원
            </div>
          </td>
          <td className="oneLine">
            <div className="d-flex align-items-center">
              {orderBill?.orderAmount}개
            </div>
          </td>
          <td className="oneLine">
            <div className="d-flex align-items-center">
              {orderBill?.orderPrice.toLocaleString("KR")}원
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <div>
              <p className="margin0 orderInfoPriceRight">
                상품 : <span>{orderBill?.orderPrice.toLocaleString("KR")}</span>
                원 + 배송비 : <span>{orderBill?.deliveryPrice}</span>원{" "}
              </p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <div>
              <p className="margin0 orderInfoPriceRight">
                결제총액 :{" "}
                <span className="textRed font-bold">
                  {orderBill?.totalPrice.toLocaleString("KR")}
                </span>
                원
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
