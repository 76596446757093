import styles from "./Input.module.css";

interface Props {
  defaultBackGround?: string;
  checked: boolean;
  onClick: () => void;
  name?: string;
}

export const ToggleInput = ({
  name,
  defaultBackGround,
  checked,
  onClick,
}: Props) => {
  return (
    <input
      type="checkbox"
      className={styles["allttam-toggle-input"]}
      name={name}
      checked={checked}
      onClick={onClick}
      readOnly
      style={{
        backgroundColor: "#00b8ba",
      }}
    />
  );
};
