import useToggle from "@/hooks/useToggle";
import { ProdcutManageResultItem } from "@/interface/productmanageinterface";
import { ViewCountModal } from "@/components/Productmanage/modal/ViewCountModal";

interface Props {
  result: ProdcutManageResultItem;
  isSelect: boolean;
  market: string;
}

export const ResultItemAdditionInfo = ({ result, isSelect, market }: Props) => {
  const { state: isViewModal, handleToggle: handleViewModal } = useToggle();
  return (
    <td>
      <div
        className={`collect-inner-1 original-price text-start div-collect-1 total-view-col ${
          isSelect && "selectedItem"
        }`}
      >
        <div className="original-price-inner">
          <h5 className="toModal" onClick={handleViewModal}>
            유입수 : {result?.viewsCount}
          </h5>
          <h5>
            수집일 : <br />
            {result?.collectDate.slice(0, 10)}
          </h5>
          <h5>
            수정일 : <br /> {result?.editDate.slice(0, 10)}
          </h5>
          <h5>등록일 : {result?.uploadDate.slice(0, 10)}</h5>
        </div>
      </div>
      {isViewModal && (
        <ViewCountModal
          market={market}
          closeModal={handleViewModal}
          site={result.site}
          code={result.code}
        />
      )}
    </td>
  );
};
