import { GreenMiddleButton } from "@/components/Button/allttamButton/GreenMiddleButton";
import { OrangeMiddleButton } from "@/components/Button/allttamButton/OrangeMiddleButton";
import { YellowMiddleButton } from "@/components/Button/allttamButton/yellowMiddleButton";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import { ohoo } from "@/utils/functions/ohooStudioApi";
import { urlToBase64 } from "@/utils/functions/urlToBase64";
import { useState } from "react";
import LoadingModal from "../LoadingModal";
import { UploadSummary } from "@/interface/uploadinterface";
import { EditorId } from "../../EditDetailPageModal";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import { base64toBlob } from "@/utils/functions/base64toBlob";
import { fileRequest } from "@/utils/functions/fileRequest";
import { XIcon } from "@/components/Admin/AdminCategoryMapping/component/svg/XIcon";
import axiosInstance from "@/utils/axiosInstance";

interface Props {
  setSelectedUrl: React.Dispatch<React.SetStateAction<string>>;
  selectedUrl: string;
  item: UploadSummary;
  pannelList: EditorId[];
  // setEditorId: React.Dispatch<React.SetStateAction<EditorId[] | null>>;
  // editorId: EditorId[];
  index: number | null;
  model: string;
  setModel: React.Dispatch<React.SetStateAction<string>>;
  setPannelList: React.Dispatch<React.SetStateAction<EditorId[]>>;
}

export const DetailImgPannel = ({
  item,
  pannelList,
  // setEditorId,
  index,
  // editorId,
  model,
  setModel,
  setPannelList,
  selectedUrl,
  setSelectedUrl,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const openWindow = useInfoWindow();
  // 첫 번역 클릭
  const clickOhooStudio = async () => {
    if (index === null) return openWindow("이미지를 선택해주세요");
    setIsLoading(true);
    try {
      const base64 = await urlToBase64(selectedUrl);
      const res = await ohoo.getEditor({
        base64Image: base64,
        site: item?.site ? item?.site : "allttam ",
        code: item?.code ? item?.code : "detailPage",
      });
      if (res) {
        const idList = [...pannelList];
        idList[index] = {
          ...res,
          resultUrl: "",
          originalUrl: idList[index].originalUrl,
        };
        setPannelList(idList);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  //다시하기 클릭
  const reTryEdit = async () => {
    if (index === null) return;
    if (pannelList[index].id) {
      const res = await axiosInstance.post("/Ohoo/GetOhooEditorUrl", [
        pannelList[index].id,
      ]);
      window.open(
        `${res.data.data}&client=allttam`,
        "_blank",
        "width=1400, height=800"
      );
    } else {
      const idList = [...pannelList];
      idList[index] = {
        ...idList[index],
        resultUrl: "",
      };
      setPannelList(idList);
      window.open(
        `${pannelList[index].link}&client=allttam`,
        "_blank",
        "width=1400, height=800"
      );
    }
  };

  // 번역 결과 클릭
  const clickGetResult = async () => {
    if (index === null) return openWindow("이미지를 선택해주세요");
    if (pannelList[index].id === "") return openWindow("번역 결과가 없습니다.");

    setIsLoading(true);
    const res = await ohoo.getTranslateResult(
      pannelList[index].id,
      item.site,
      item.code
    );
    const idList = [...pannelList];
    idList[index] = {
      ...idList[index],
      resultUrl: res.data,
    };
    setPannelList(idList);
    setIsLoading(false);
  };

  const applyImage = async () => {
    if (index === null) return openWindow("이미지를 선택해주세요");
    if (pannelList[index].resultUrl === "")
      return openWindow("번역 결과가 없습니다.");
    const base64 = await urlToBase64(pannelList[index].resultUrl);
    const blob = await base64toBlob(base64);
    const res = await fileRequest("/Image/UploadImage", blob);

    if (model.includes(selectedUrl)) {
      setModel(model.replace(selectedUrl, res.link));
    } else if (model.includes(selectedUrl.replace("https", "http"))) {
      setModel(model.replace(selectedUrl.replace("https", "http"), res.link));
    }

    setPannelList((prev) =>
      prev.map((item) => {
        return item.originalUrl === selectedUrl
          ? { ...item, originalUrl: res.link, resultUrl: "" }
          : item;
      })
    );
    setSelectedUrl(res.link);
  };

  const clickX = () => {
    if (index !== null) {
      const idList = [...pannelList];
      idList[index] = {
        ...idList[index],
        resultUrl: "",
      };
      setPannelList(idList);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex gap-3 align-items-center">
          <p className="weight500 text16 black margin0">올땀 스튜디오 뷰어</p>
          <QuestionMarkIcon />
        </div>
        <div className={`d-flex gap-3 ${index === null && "opacity5"}`}>
          {index === null ||
          (!pannelList[index]?.link && !pannelList[index]?.id) ? (
            <YellowMiddleButton onClick={clickOhooStudio}>
              올땀 스튜디오
            </YellowMiddleButton>
          ) : (
            <OrangeMiddleButton onClick={reTryEdit}>
              다시 하기
            </OrangeMiddleButton>
          )}
          <GreenMiddleButton onClick={applyImage}>패널 적용</GreenMiddleButton>
        </div>
      </div>
      <div
        className={` ${
          index === null && "opacity5"
        } d-flex widthFull mt-3 allttam-studio-viewer`}
      >
        <div className="widthHalf">
          <p className="weight500 text14 grey">작업 전</p>
          <div className="px-3">
            <img src={selectedUrl} className="allttam-studio-img" />
          </div>
        </div>
        <div className="widthHalf weight500 text14 gret">
          <p className="weight500 text14 grey">작업 후</p>
          <div className="px-3 relative">
            {pannelList[index!]?.resultUrl !== "" ? (
              <div>
                <img
                  src={pannelList[index!]?.resultUrl}
                  className="allttam-studio-img"
                />
                <button
                  onClick={clickX}
                  className="absolute img-pannel-xicon madebutton"
                >
                  <XIcon color="#D9D9D9" />
                </button>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center translate-result-container">
                <YellowMiddleButton onClick={clickGetResult}>
                  번역 결과
                </YellowMiddleButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <LoadingModal />}
    </>
  );
};
