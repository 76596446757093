import { useEffect, useState } from "react";
import { DetailOptionType, OptionGroupType } from "@/interface/uploadinterface";
import { OptionDefaultImgIcon } from "@/components/svg/OptionDefaultImgIcon";
import { OptionImageEdit } from "./OptionImageEdit";
import { useTooltip } from "@/hooks/useTooltip";

interface Props {
  setDetailOptionList: React.Dispatch<React.SetStateAction<DetailOptionType[]>>;
  option: DetailOptionType;
  selectedDetailOption: DetailOptionType[];
  setSelectedDetailOption: React.Dispatch<
    React.SetStateAction<DetailOptionType[]>
  >;
  detailOptionList: DetailOptionType[];
  optionIndex: number;
  optionGroups: OptionGroupType[];
}

export const OptionListItem = ({
  optionGroups,
  optionIndex,
  detailOptionList,
  setDetailOptionList,
  option,
  selectedDetailOption,
  setSelectedDetailOption,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [hoverImg, enterImg, leaveImg] = useTooltip();

  useEffect(() => {
    if (Boolean(selectedDetailOption.find((item) => item.id === option.id))) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedDetailOption]);

  const selectOption = () => {
    if (isSelect) {
      setSelectedDetailOption((prev) =>
        prev.filter((item) => item.id !== option.id)
      );
    } else {
      setSelectedDetailOption((prev) => [...prev, option]);
    }
  };

  const inputOptionName = (idx: number, value: string) => {
    const newOptionList = [...detailOptionList];
    const optionName = newOptionList[optionIndex].optionName.filter(
      (item) => item.language === "KR"
    )[0];
    const newOptionName = { ...optionName }; // 내부 객체의 참조를 복사하여 새로운 객체 생성
    newOptionName.text = newOptionName.text
      .split("//")
      .map((v, i) => (i === idx ? value : v))
      .join("//"); // 내부 객체의 속성값 변경
    newOptionList[optionIndex].optionName = [
      ...newOptionList[optionIndex].optionName.filter(
        (item) => item.language !== "KR"
      ), // 기존 KR 언어 객체를 제외한 객체들을 복사
      newOptionName, // 수정된 KR 언어 객체를 추가
    ];
    setDetailOptionList(newOptionList);
  };

  const inputPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedString = parseInt(e.target.value.replace(/,/g, ""));
    const newOptionList = [...detailOptionList];
    if (
      newOptionList[optionIndex].price.filter(
        (item) => item.currency === "KRW"
      )[0]
    ) {
      const optionPrice = newOptionList[optionIndex].price.filter(
        (item) => item.currency === "KRW"
      )[0];
      const newOptionPrice = { ...optionPrice };
      newOptionPrice.originalPrice = parsedString ? parsedString : 0;
      newOptionPrice.salePrice = parsedString ? parsedString : 0;
      newOptionList[optionIndex].price = [
        ...newOptionList[optionIndex].price.filter(
          (item) => item.currency !== "KRW"
        ),
        newOptionPrice,
      ];
      setDetailOptionList(newOptionList);
    } else {
      const newPrice = {
        currency: "KRW",
        originalPrice: parsedString ? parsedString : 0,
        salePrice: parsedString ? parsedString : 0,
      };
      newOptionList[optionIndex].price = [
        ...newOptionList[optionIndex].price.filter(
          (item) => item.currency !== "KRW"
        ),
        newPrice,
      ];
      setDetailOptionList(newOptionList);
    }
  };
  const inputQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptionList = [...detailOptionList];
    newOptionList[optionIndex].quantity = parseInt(e.target.value);
    setDetailOptionList(newOptionList);
  };

  const findImg = () => {
    try {
      const img = optionGroups[0].groups.filter((item) => {
        const groupKorean = item.optionName.filter(
          (group) => group.language === "KR"
        )[0].text;
        const optionKorean = option.optionName
          .filter((opt) => opt.language === "KR")[0]
          .text.split("//")[0];
        if (groupKorean === optionKorean) {
          return item.optionImage;
        }
      });
      return img[0].optionImage;
    } catch {
      return null;
    }
  };

  return (
    <tr onClick={selectOption}>
      <td className="width5">
        <div
          className={`border-left height45 form-group d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <input
            checked={Boolean(
              selectedDetailOption.find((item) => item.id === option.id)
            )}
            type="checkbox"
            readOnly
          />
          <label></label>
        </div>
      </td>
      <td className="width5">
        <div
          onClick={(e) => e.stopPropagation()}
          className={`height45 click-img d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          {hoverImg && (
            <img
              src={option.optionImage || findImg()!}
              className="absolute option-thumb-big2"
            />
          )}
          {option.optionImage && option.optionImage !== "" ? (
            <img
              className="optionThumbnail"
              src={option.optionImage}
              onMouseEnter={enterImg}
              onMouseLeave={leaveImg}
            />
          ) : findImg() ? (
            <img
              className="optionThumbnail"
              src={findImg()!}
              onMouseEnter={enterImg}
              onMouseLeave={leaveImg}
            />
          ) : (
            <OptionDefaultImgIcon />
          )}
          {/* {<OptionDefaultImgIcon />} */}
        </div>
      </td>
      {detailOptionList[optionIndex].optionName
        .filter((item) => item.language === "KR")[0]
        .text.split("//")
        .map((value, idx) => (
          <td key={idx}>
            <div
              className={`px-2 height45 d-flex align-items-center justify-content-center ${
                isSelect && "selectedItem"
              }`}
            >
              <input
                onClick={(e) => e.stopPropagation()}
                type="text"
                className="allttam-input input-border-2"
                placeholder="옵션 값을 입력해주세요"
                value={value}
                onChange={(e) => inputOptionName(idx, e.target.value)}
              />
            </div>
          </td>
        ))}
      <td className="width15">
        <div
          className={`px-2 height45 d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <input
            onChange={inputPrice}
            onClick={(e) => e.stopPropagation()}
            type="string"
            className="allttam-input input-border-2"
            placeholder="가격"
            value={
              option.price.filter((item) => item?.currency === "KRW")[0]
                ?.salePrice
                ? option.price
                    .filter((item) => item?.currency === "KRW")[0]
                    .salePrice.toLocaleString()
                : ""
            }
          />
        </div>
      </td>
      <td className="width15">
        <div
          className={`px-2 height45 d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <input
            className="allttam-input input-border-2"
            onChange={inputQuantity}
            onClick={(e) => e.stopPropagation()}
            type="number"
            placeholder="재고"
            value={option.quantity}
          />
        </div>
      </td>
      <td className="width10">
        <div
          className={`border-right height45 d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          {option.isSoldOut ? (
            <p className="textYellow margin0">판매중지</p>
          ) : (
            <p className="textGreen margin0">판매중</p>
          )}
        </div>
      </td>
    </tr>
  );
};
