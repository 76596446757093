import { ApplyThumbNailButton } from "../../editMainImg/ApplyThumbNailButton";
import { ResetEditorButton } from "../../editMainImg/ResetEditorButton";
import { ReactCropperElement } from "react-cropper";

interface Props {
  editedImg: string;
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
  applyImage: (img: string) => Promise<void>;
  infoText: string;
}

export const Viewer = ({
  editedImg,
  cropperRef,
  mouseHover,
  mouseLeave,
  applyImage,
  infoText,
}: Props) => {
  return (
    <>
      <div className="d-flex widthFull align-items-center gap-3 mb-3">
        <h3 className="h3 oneLine margin0">뷰어</h3>
        <hr className="hr4 widthFull" />
      </div>
      <div className="widthFull d-flex justify-content-between">
        <div className="width60 viewerContainer">
          <img className="thumbnailHeight" src={editedImg} />
        </div>
        <div className="width40 d-flex flex-col gap-3 align-items-center">
          <ResetEditorButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <ApplyThumbNailButton
            editedImg={editedImg}
            applyImage={applyImage}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
        </div>
      </div>
      <div className="thumbToolPlace">
        {infoText !== "" && <div className="thumbtooltip">{infoText}</div>}
      </div>
    </>
  );
};
