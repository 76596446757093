import CryptoJS from "crypto-js";

const key = process.env.REACT_APP_CRYPTOKEY;

export const encryptByDES = (message: string) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key!);
  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const decryptByDES = (ciphertext: string) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key!);

  const decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(ciphertext),
    } as any,
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};
