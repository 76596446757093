import useFetch from "../../../hooks/useFetch";
import { useGetCoin } from "../../../hooks/useGetCoin";
import { Link, useNavigate } from "react-router-dom";
import { RightArrowIcon } from "@/components/svg/RightArrowIcon";
import { InfoWhiteBox } from "./InfoWhiteBox";
import { PlanType } from "@/interface/payment";
import { ReaminingIcon } from "@/components/Home/HomeUserInfo/svg/RemainingDayIcon";
import { CoinIcon } from "@/components/svg/CoinIcon";
import { SubscribeIcon } from "@/components/svg/SubscribeIcon";
import { AccountIcon } from "@/components/svg/AccountIcon";
import { ReverseLeftRightIcon } from "@/components/svg/ReverseLeftRightIcon";
import useUser from "@/hooks/useUser";

interface Props {
  tab: string | null;
}

export const PaymentUserInfo = ({ tab }: Props) => {
  const userInfo = useUser();
  const [plan] = useFetch("plan", "/Plan/GetAllddamPlanList");
  const [userPlanInfo] = useFetch("userPlanInfo", "/Log/GetUserPlanSummary");
  const navigate = useNavigate();
  const coin = useGetCoin();
  const remainingDays = userPlanInfo?.usePerioud
    ? userPlanInfo.usePerioud.split("일")[0]
    : "0";
  const coinAmount = Math.floor(coin);
  const planInfo = userInfo?.plan || "N/A";
  const subscribedAccounts =
    (plan?.[0] as PlanType)?.planOptionInfo[0].openmarketInfo[1].Value.now || 0;

  return (
    <div className="col-md-6 col-12" style={{ maxWidth: "810px" }}>
      <div className="heading-two history-col">
        <div className="heading-two-inner" style={{ maxWidth: "810px" }}>
          <h3 className="h3 weight600">결제 정보</h3>
          <div className="d-flex">
            <button
              onClick={() => {
                {
                  tab === "coin" ||
                    (tab === null && navigate("/payment?tab=starter"));
                }
              }}
              className="heading-two-inner-1 madebutton"
            >
              <p>{tab === "coin" || tab === null ? "패키지" : "코인충전"}</p>
              <RightArrowIcon />
            </button>
          </div>
        </div>
      </div>
      <div
        className="d-flex gap-4 px-4 justify-content-between flex-wrap"
        style={{ maxWidth: "810px" }}
      >
        <InfoWhiteBox
          icon={<ReaminingIcon />}
          infoNumber={remainingDays}
          infoText="남은 일 수"
          color="#00b8ba"
        />
        <InfoWhiteBox
          icon={<CoinIcon />}
          infoNumber={isNaN(coinAmount) ? "0" : coinAmount}
          infoText="보유 코인"
          color="#FFB500"
        />
        <InfoWhiteBox
          icon={<SubscribeIcon />}
          infoNumber={planInfo}
          infoText="구독중인 플랜"
          color="#FF8957"
        />
        <InfoWhiteBox
          icon={<AccountIcon />}
          infoNumber={subscribedAccounts}
          infoText="구독중인 계정 수"
          color="#39415c"
        />
      </div>
    </div>
  );
};
