import { useEffect, useState } from "react";
import { MultiSelectedCollect } from "../../../MultiSelectedCollect/MultiSelectedCollect";
import { AliItem } from "@/interface/collectInterface";
import useSelectHelperComponent from "@/hooks/useSelectHelperComponent";

interface Props {
  totalData: number;
  itemList: any;
  setSelectedCollectItem: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCollectItem?: string[];
  site?: string;
}

export const CollectMultiSubMenu = ({
  site,
  totalData,
  itemList,
  selectedCollectItem,
  setSelectedCollectItem,
}: Props) => {
  const openHelper = useSelectHelperComponent();
  const [isAll, setIsAll] = useState<boolean>(false);

  useEffect(() => {
    setIsAll(false);
  }, [itemList]);

  useEffect(() => {
    if (isAll) {
      if (site === "ali") {
        itemList?.forEach((item: AliItem) => {
          if (selectedCollectItem?.includes(item.productId)) return;
          else setSelectedCollectItem((prev) => [...prev, item.productId]);
        });
      }
      if (site === "taobao") {
        itemList?.forEach(async (item: any) => {
          setSelectedCollectItem((prev) => [...prev, item.item_id]);
        });
      }
      if (site === "1688") {
        itemList?.forEach(async (item: any) => {
          setSelectedCollectItem((prev) => [...prev, item.id]);
        });
      }
      if (site === "vvic") {
        itemList?.forEach(async (item: any) => {
          setSelectedCollectItem((prev) => [...prev, item.id]);
        });
      }
    }
    if (!isAll) {
      if (site === "ali") {
        itemList?.forEach((item: AliItem) => {
          setSelectedCollectItem((prev) =>
            prev.filter((select) => select !== item.productId)
          );
        });
      }
      if (site === "taobao") {
        itemList?.forEach((item: any) => {
          setSelectedCollectItem((prev) =>
            prev.filter((select) => select !== item.nid)
          );
        });
      } else {
        itemList?.forEach((item: any) => {
          setSelectedCollectItem((prev) =>
            prev.filter((select) => select !== item.id)
          );
        });
      }
    }
  }, [isAll]);

  const clickSelectAll = () => {
    setIsAll!((prev) => !prev);
  };

  return (
    <div className="row subHeadSticky">
      <div className="col-12">
        <div className="total-block">
          <div className="total-block-inner">
            <div className="form-group">
              <input
                onClick={clickSelectAll}
                type="checkbox"
                id="html"
                checked={isAll}
                readOnly
              />
              <label htmlFor="html"></label>
            </div>
            <h4>선택 {itemList?.length}개</h4>
            <p className="margin0 collectAll">
              (검색 {totalData ? totalData : ""}개)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
