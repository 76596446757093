import { useTooltip } from "@/hooks/useTooltip";
import { ExpectListType } from "@/interface/uploadinterface";

interface Props {
  item: ExpectListType;
}

export const ExpectMarginItem = ({ item }: Props) => {
  const [isOnImg, enterImg, leaveImg] = useTooltip();
  return (
    <tr>
      <td className="width10">
        {isOnImg && (
          <img src={item.thumbnail} className="expect-thumb-big absolute" />
        )}
        <span className="d-flex justify-content-center">
          <img
            src={item.thumbnail}
            className="expect-thumb"
            onMouseEnter={enterImg}
            onMouseLeave={leaveImg}
          />
        </span>
      </td>
      <td className="width20">
        <div
          className="weight500 text12"
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {item.productName}
        </div>
      </td>
      <td className="width20">
        <div className="weight500 text12">
          {item.defaultPrice.min} ~ {item.defaultPrice.max}{" "}
          {CURRENCY_INDEX[item.currency]}
        </div>
      </td>
      <td className="width20">
        <div className="weight500 text12 oneLine">
          {item.settingPrice.min.toLocaleString()} ~{" "}
          {item.settingPrice.max.toLocaleString()} \
        </div>
      </td>
      <td className="width10">
        <div className="weight500 text12">{item.expectMarinRate} % </div>
      </td>
      <td className="width20">
        <div className="weight500 text12 oneLine">
          {item.expectMargin.min.toLocaleString()} ~{" "}
          {item.expectMargin.max.toLocaleString()}
        </div>
      </td>
    </tr>
  );
};

const CURRENCY_INDEX = {
  KRW: "\\",
  CNY: "￥",
  USD: "$",
  WON: "\\",
};
