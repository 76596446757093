import { WhiteGreenMiddleButton } from "@/components/Button/allttamButton/WhiteGreenMiddleButton";
import { useTooltip } from "../../../../../hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";

interface Props {
  handleToastEditor: () => void;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const ImportToastButton = ({
  handleToastEditor,
  mouseHover,
  mouseLeave,
}: Props) => {
  const [isTooltipVisible, handleMouseEnter, handleMouseLeave] = useTooltip();
  return (
    <WhiteGreenMiddleButton
      onClick={handleToastEditor}
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("이미지 보정·텍스트 삽입하기")}
    >
      에디터 +
    </WhiteGreenMiddleButton>
  );
};
