import AdminGuard from "@/components/AuthRoute/AdminGuard";
import AuthRoute from "@/components/AuthRoute/AuthRoute";
import { InfoWindow } from "@/components/InfoWindow/InfoWindow";
import { Developer } from "./components/Admin/Developer/Developer";
import { PaymentResult } from "./components/Payment/PaymentResult";
import useAnimation from "@/hooks/useAnimation";
import useChannelTalk from "@/hooks/useChannelTalk";
import Home from "./pages/Home";
import Collect from "./pages/Collect";
import useUser from "@/hooks/useUser";
import ProductManage from "./pages/ProductManage";
import { Suspense, lazy, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Navigate, Route, Routes, redirect } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import SideBarRouter from "./SideBarRouter";
import { infoWindowState, isDeskTop } from "./atom/atom";
import Upload from "./pages/Upload";
import OrderManage from "./pages/OrderManage";
import Payment from "./pages/Payment";
import PaymentLog from "./pages/PaymentLog";

const Index = lazy(() => import("./pages/Landing/Index"));
const Inquiries = lazy(() => import("./pages/Inquiries"));
const Price = lazy(() => import("./pages/Landing/Price"));
const Notice = lazy(() => import("./pages/Notice"));
const UserInfo = lazy(() => import("@/pages/UserInfo"));
const Login = lazy(() => import("@/pages/Login"));
const Signup = lazy(() => import("@/components/Login/Signup"));

import {
  CategoryScale,
  Chart,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Spinner } from "./components/Spinner/Spinner";

const NaverLoginCallBack = lazy(
  () => import("./components/Login/NaverLoginCallBack/NaverLoginCallBack")
);
const Kmc = lazy(() => import("./components/Login/SignupComponent/Kmc"));
const BoostAdmin = lazy(() => import("@/pages/BoostAdmin"));
const Boost = lazy(() => import("@/pages/Boost"));
const DashBoard = lazy(() => import("@/components/Admin/DashBoard/DashBoard"));
const Admin = lazy(() => import("@/pages/Admin"));
const ManualPreparePage = lazy(
  () => import("@/components/Manual/component/ManualPreparePage")
);
const ManualSetting = lazy(
  () => import("@/components/Manual/SettingSequence/ManualSetting")
);
const Manual = lazy(() => import("@/pages/Manual"));
const ManualUsing = lazy(() => import("@/components/Manual/Using/ManualUsing"));
const Setting = lazy(() => import("@/pages/Setting"));

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Tooltip);

export const Router = () => {
  const userInfo = useUser();
  const $alertPortal = document.getElementById("alert-portal");
  const [isInfoWindow, setIsInfoWindow] = useRecoilState(infoWindowState);

  const setIsDeskTop = useSetRecoilState(isDeskTop);
  useAnimation();
  useChannelTalk(userInfo);

  //기기별 높이 설정
  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenSize();
      if (window.innerWidth < 768) {
        setIsDeskTop(false);
      } else {
        setIsDeskTop(true);
      }
    }
  }, []);

  return (
    <div className="notranslate" translate="no">
      <Suspense
        fallback={
          <div className="center">
            <Spinner />
          </div>
        }
      >
        {$alertPortal != null && isInfoWindow.isOpen
          ? createPortal(
              <InfoWindow message={isInfoWindow.message} />,
              $alertPortal
            )
          : null}
        <Routes>
          <Route path="/kmc" element={<Kmc />} />
          <Route path="/" element={<Index />} />
          <Route path="/price" element={<Price />} />
          <Route path="/manual" element={<Manual />} />
          <Route path="manual/setting" element={<ManualSetting />} />
          <Route path="manual/using" element={<ManualUsing />} />
          <Route path="manual/prepare" element={<ManualPreparePage />} />
          <Route path="/user/naverLogin" element={<NaverLoginCallBack />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route element={<SideBarRouter />}>
            <Route element={<AuthRoute />}>
              <Route path="/notice" element={<Notice />} />
              <Route path="/inquiries" element={<Inquiries />} />
              <Route path="/home" element={<Home />} />
              <Route path="/collect" element={<Collect />} />
              <Route path="/productmanage" element={<ProductManage />} />
              <Route path="/ordermanage" element={<OrderManage />} />
              <Route path="/admin/developer" element={<Developer />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/payment/log" element={<PaymentLog />} />
              <Route path="/payment/result" element={<PaymentResult />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/userinfo" element={<UserInfo />} />
              <Route path="/boost/admin" element={<BoostAdmin />} />
              <Route path="/boost" element={<Boost />} />
              <Route element={<AdminGuard {...userInfo} />}>
                <Route path="/admin" element={<DashBoard />} />
                <Route path="/admin/:component" element={<Admin />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};
