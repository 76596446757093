import { useAlert } from "@/hooks/useAlert";
import useToggle from "@/hooks/useToggle";
import { SelectCS } from "../OrderManageCS";
import { CSModal } from "../../modal/CSModal";
import { AlertOnlyClose } from "@/components/Alert/AlertOnlyClose";
import { useInfoWindow } from "@/hooks/useInfoWindow";

interface Props {
  selectedCS: SelectCS | undefined;
  length: number;
}

export const CSSubMenu = ({ selectedCS, length }: Props) => {
  const { state: isCSModal, handleToggle: handleCSModal } = useToggle();
  const openPopup = useInfoWindow();

  const clickHandleCS = () => {
    if (selectedCS && selectedCS.cs) {
      handleCSModal();
    } else {
      openPopup("선택된 문의글이 없습니다.");
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="total-block">
          <div className="total-block-inner">
            <h4>전체 {length}</h4>
          </div>
          <div className="d-flex align-items-center gap-4">
            <button
              onClick={clickHandleCS}
              className="cancel-btn save-btn reset-btn name-set-btn"
            >
              문의 답변
            </button>
          </div>
        </div>
      </div>
      {isCSModal && (
        <CSModal closeModal={handleCSModal} selectedCS={selectedCS} />
      )}
    </div>
  );
};
