interface Props {
  up: boolean;
  down: boolean;
}

export const UpDownArrow = ({ up, down }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path data-name="사각형 74" fill="none" d="M0 0h20v20H0z" />
      <path
        data-name="패스 10533"
        d="M5.679 0v5.679H0"
        transform="rotate(-135 7.243 3.345)"
        fill="none"
        stroke={`${up ? "#000" : "#a7b0c7"}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        data-name="패스 1"
        d="M5.679 5.679V0H0"
        transform="rotate(135 4.278 9.495)"
        fill="none"
        stroke={`${down ? "#000" : "#a7b0c7"}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
    </svg>
  );
};
