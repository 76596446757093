import { CollectValue } from "@/interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  collectWay: string;
}

export const SearchingKeyword1688 = ({
  collectValue,
  inputCollectValue,
  collectWay,
}: Props) => {
  return (
    <div className="product-label product-label-1 col-md-9 col-12">
      <label>{collectWay === "one" ? "URL" : "키워드"}</label>
      <input
        value={collectValue.url}
        onChange={inputCollectValue}
        type="text"
        className="form-control"
        name="url"
        placeholder={
          collectWay === "one"
            ? "URL을 입력해주세요."
            : "키워드를 입력해주세요."
        }
      />
    </div>
  );
};
