import { UseMutationResult } from "react-query";
import { copyFunction } from "@/utils/functions/copyFunction";
import { ProdcutManageResultItem } from "@/interface/productmanageinterface";

interface Props {
  result: ProdcutManageResultItem;
  title: { text: string };
  inputTitle: () => void;
  memo: { memo: string };
  inputMemo: () => void;
  changeMemo: UseMutationResult<any, unknown, void, unknown>;
  isSelect: boolean;
}

export const ResultItemTitleMemo = ({
  isSelect,
  result,
  title,
  inputTitle,
  memo,
  inputMemo,
  changeMemo,
}: Props) => {
  return (
    <td>
      <div
        className={`collect-inner-1 collect-inner-2 ${
          isSelect && "selectedItem"
        }`}
      >
        <div>
          <div className="input-collect gap-3">
            <div>
              <img
                onClick={(e) => e.stopPropagation()}
                className="rounded-md componentItemImg"
                src={result.mainImages[0]}
                alt=""
              />
            </div>
            <div>
              <div className="input-collect-inner mb-2 ">
                <input
                  onClick={(e) => e.stopPropagation()}
                  type="text"
                  name="text"
                  placeholder={title.text}
                  value={title.text}
                  onChange={inputTitle}
                />
                <div className="input-collect-btn">
                  <button
                    onClick={(e) => e.stopPropagation()}
                    className="cancel-btn save-btn correction-btn"
                  >
                    수정
                  </button>
                </div>
              </div>
              <div className="input-collect-inner mb-2 ">
                <input
                  onClick={(e) => e.stopPropagation()}
                  type="text"
                  placeholder={result.memo}
                  value={memo.memo}
                  name="memo"
                  onChange={inputMemo}
                />
                <div className="input-collect-btn">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      changeMemo.mutate();
                    }}
                    className="cancel-btn save-btn correction-btn"
                  >
                    수정
                  </button>
                </div>
              </div>
              <div className="coupan-text">
                <div className="d-flex align-items-center">
                  <h6>작업번호 : {result?.workNumber}</h6>
                  <span></span>
                  <h5>원본상품코드 : {result?.code}</h5>
                  <button
                    value={result?.code}
                    onClick={(e) => {
                      e.stopPropagation();
                      copyFunction(e.currentTarget.value);
                    }}
                    className="d-flex align-items-center madebutton"
                  >
                    <span className="copy-1"></span>
                    <span className="copy-1 copy-2"></span>
                  </button>
                </div>
                <h4>상품 그룹명 : {result?.groupName}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
  );
};
