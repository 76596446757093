import { useInfoWindow } from "@/hooks/useInfoWindow";
import { orderManage } from "../../../../utils/functions/postApi";
import { DeliveryInfo } from "../OrderInfoModal";
import { useQueryClient } from "react-query";

interface Props {
  closeModal: () => void;
  deliveryInfo: DeliveryInfo;
  isCheckPcc: boolean;
  idx: number;
}

export const OrderInfoSetButtonBox = ({
  idx,
  closeModal,
  deliveryInfo,
  isCheckPcc,
}: Props) => {
  const openWindow = useInfoWindow();
  const queryClient = useQueryClient();
  //FIXME 확인!! 비정상적인 접근
  const clickSetButton = async () => {
    const updateRes = await orderManage.updateDeliveryInfo({
      idx: deliveryInfo.idx,
      receiverName: deliveryInfo.receiverName,
      receiverContactTelNo: deliveryInfo.receiverContactTelNo,
      receiverZipCode: deliveryInfo.receiverZipCode,
      receiverAddress: deliveryInfo.receiverAddress,
      // orderMemo: deliveryInfo.orderMemo,
      pcc: deliveryInfo.pcc,
      pccStatus: isCheckPcc ? "일치" : deliveryInfo.pccStatus,
    });

    if (updateRes.code === 401) openWindow("통관부호 검증이 필요합니다.");
    if (updateRes.code === 200) {
      openWindow("성공적으로 저장되었습니다.");
      queryClient.invalidateQueries(`productBill${idx}`);
      closeModal();
    }
  };

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
          <button className="cancel-btn" onClick={closeModal}>
            닫기
          </button>
          <button onClick={clickSetButton} className="cancel-btn save-btn">
            저장
          </button>
        </div>
      </div>
    </div>
  );
};
