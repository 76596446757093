import { DownArrowIcon } from "@/components/svg/DownArrowIcon";
import useToggle from "@/hooks/useToggle";
import { CollectValue } from "@/interface/collectInterface";

import useSelectHelperComponent from "@/hooks/useSelectHelperComponent";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import { GroupNameSelect } from "@/components/Collect/GroupNameSelect/GroupNameSelect";
import { QuestionButton } from "@/components/Button/QuestionButton";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const SearchingGroupName1688 = ({
  collectValue,
  inputCollectValue,
  setCollectValue,
}: Props) => {
  const { state: isSelect, handleToggle: handleSelect } = useToggle();
  const openHelper = useSelectHelperComponent();

  return (
    <div className="product-label col-md-3 col-12">
      <div className="d-flex align-items-center gap-3 mb-2">
        <label htmlFor="product-name" className="paddingZero">
          상품 그룹명
        </label>

        <QuestionButton openId="66755bb0604d73de56fa569f" />
      </div>
      <div className="relative">
        <input
          name="groupName"
          type="text"
          value={collectValue.groupName}
          onChange={inputCollectValue}
          className="form-control"
          placeholder="상품 그룹명을 입력해주세요."
        />
        <button
          className="madebutton collect-group-select-btn"
          onClick={handleSelect}
        >
          <DownArrowIcon />
        </button>
        {isSelect && (
          <GroupNameSelect
            setCollectValue={setCollectValue}
            closeMenu={handleSelect}
          />
        )}
      </div>
    </div>
  );
};
