import {
  OrderResult,
  OrdermanageSerachingValue,
  ResultItem,
} from "@/interface/orderManageInterface";
import { useQuery } from "react-query";
import { orderApis } from "@/apis/orderApis";

interface Props {
  searchValue: OrdermanageSerachingValue;
  enabled: boolean;
  onSuccess: (data: ResultItem[]) => void;
  onError: () => void;
  onSettled: () => void;
}

const useOrderList = ({
  searchValue,
  enabled,
  onError,
  onSettled,
  onSuccess,
}: Props) => {
  const values = Object.values(searchValue);

  return useQuery<OrderResult>(
    ["orderList", ...values],
    () => orderApis.getOrderManage(searchValue),
    {
      enabled: enabled,
      onSuccess: ({ data }) => {
        onSuccess(data);
      },
      onError: () => {
        onError();
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
};

export default useOrderList;
