import { SearchinGreenCircleIcon } from "@/components/svg/SearchingGreenCircleIcon";

export const ExpectTableHead = () => {
  return (
    <thead className="widthFull">
      <tr className="widthFull table-sticky">
        <th className="width10">
          <div className="weight600 text14 grey oneLine d-flex align-items-center gap-2">
            <span>썸네일</span>
            <SearchinGreenCircleIcon />
          </div>
        </th>
        <th className="width20">
          <div className="weight600 text14 grey oneLine">상품명</div>
        </th>
        <th className="width20">
          <div className="weight600 text14 grey oneLine">원본 할인가</div>
        </th>
        <th className="width20">
          <div className="weight600 text14 grey oneLine">설정 상품가 (\)</div>
        </th>
        <th className="width10">
          <div className="weight600 text14 grey oneLine">예상 마진율</div>
        </th>
        <th className="width20">
          <div className="weight600 text14 grey oneLine">예상 마진</div>
        </th>
      </tr>
    </thead>
  );
};
