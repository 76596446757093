import axiosInstance from "@/utils/axiosInstance";
import { useMemo } from "react";
import { useQuery } from "react-query";

const usePlanList = () => {
  const { data } = useQuery<Plan[]>("allttamPlanList", async () => {
    const res = await axiosInstance.get("/AllddamPay/GetPlanList");

    return res.data.data;
  });

  const plan = useMemo(() => {
    return data?.filter((plan) => plan.type === "PLAN" && plan.name !== "Free");
  }, [data]);

  const coin = useMemo(() => {
    return data?.filter((plan) => plan.type === "COIN");
  }, [data]);

  const slot = useMemo(() => {
    return data?.filter((plan) => plan.type === "SLOT");
  }, [data]);

  return { plan, coin, slot };
};

export default usePlanList;

export interface Plan {
  idx: number;
  type: "PLAN" | "COIN" | "SLOT";
  name: string;
  price: number;
  point: number;
}
