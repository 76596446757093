import { isDeskTop } from "@/atom/atom";
import NavBar from "@/components/NavBar/NavBar";
import { NavAdmin } from "@/components/NavBar/component/NavAdmin";
import { User } from "@/interface/user";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";

const AdminGuard = (user: User) => {
  const isAdmin = user.role === "Admin";
  const isDesktop = useRecoilValue(isDeskTop);

  return isAdmin ? (
    <>
      <NavBar>{isDesktop ? <NavAdmin /> : <div></div>}</NavBar>

      <Outlet />
    </>
  ) : (
    <Navigate to="/home" replace />
  );
};

export default AdminGuard;
