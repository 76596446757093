import { PagingButton } from "@/components/PagingButton/PagingButton";
import { MouseEvent, useCallback, useEffect, useState } from "react";

interface Props {
  totalPage: number;
  submitPage: (page: number) => void;
  currentPage: number;
}

export const OrderManagePaging = ({
  totalPage,
  submitPage,
  currentPage,
}: Props) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!currentPage) return;
    submitPage(page);
  }, [page]);

  const clickNextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const clickPrevPage = useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  const pageButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setPage(Number(e.currentTarget.value));
  }, []);

  return (
    <PagingButton
      totalPages={totalPage}
      currentPage={currentPage}
      onClick={pageButton as (e: React.MouseEvent<HTMLButtonElement>) => void}
      clickNextPage={clickNextPage}
      clickPrevPage={clickPrevPage}
    />
  );
};
