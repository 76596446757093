import axiosInstance from "../axiosInstance";
import { checkImgTranslate } from "./checkImgTranslate";

export const ohoo = {
  getEditor: async (data: {}) => {
    const transCount = await checkImgTranslate();

    // if (transCount < 200) {
    const res = await axiosInstance.post("/Ohoo/GetOhooEditor", data);

    const link = res.data.data;
    if (link) {
      window.open(`${link}&client=allttam`, "_blank", "width=1400, height=800");
      return {
        id: res.data.data
          .split("https://editor.ohoolabs.com/")[1]
          .split("?")[0],
        link,
      };
    }
    if (!link) {
      alert(res.data.message);
    }
  },

  getTranslateResult: async (id: string, site: string, code: string) => {
    const transCount = await checkImgTranslate();
    // if (transCount < 200) {
    const res = await axiosInstance.get(
      `/Ohoo/SaveTranslateImage?editorId=${id}&site=${site}&code=${code}`
    );

    return res.data;
    // } else {
    //   return alert("번역기 호출오류");
    // }
  },
};
