import { useMemo } from "react";
import { Revenue } from "../../../../interface/orderManageInterface";

interface Props {
  profit: number;
}

export const RevenueProfit = ({ profit }: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-2">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">예상수익</p>
        <p className="oneLine margin0">{profit}원</p>
      </div>
    </div>
  );
};
