export const SearchingIconGreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 794" fill="none" d="M0 0h30v30H0z" />
      <path
        d="m22.8 23.247-3-3.17a6.9 6.9 0 0 0 1.1-8.8A7 7 0 1 0 15 22a7.277 7.277 0 0 0 3.144-.784l3.2 3.411a1 1 0 1 0 1.46-1.38zM10 15a5 5 0 1 1 5 5 5 5 0 0 1-5-5z"
        transform="translate(-.978 -1.999)"
        fill="#00b8ba"
      />
    </svg>
  );
};
