import {
  EditProductTitleValueType,
  ProdcutManageResultItem,
} from "../../../../interface/productmanageinterface";
import { useState } from "react";
import { UseMutationResult } from "react-query";
import { EditDeliveryPriceComponent } from "./component/EditDeliveryPriceComponent";
import { EidtProductTitleTable } from "./component/EditProductTitleTable";
import { EditSalePriceComponent } from "./component/EditSalePriceComponent";
import { EditWordChange } from "./component/EditWordChange";
import { EditWordLengthComponent } from "./component/EditWordLengthComponent";
import { SelectDeliveryPrice } from "./component/SelectDeliverPrice";
import { SelectSalePrice } from "./component/SelectSalePrice";
import { SubmitButtonComponent } from "./component/SubmitButtonComponent";
import { EditProductKeyWordSetting } from "./component/EditProductKeyWordSetting";
import { EditProductCheckBox } from "./component/EditProductCheckBox";
import { EditProductTagSetting } from "./component/EditProductTagSetting";
import { Keyword } from "@/interface/uploadinterface";

interface Props {
  setProductTitleValue: React.Dispatch<
    React.SetStateAction<EditProductTitleValueType[]>
  >;
  setIsEdit: React.Dispatch<
    React.SetStateAction<{
      salePrice: boolean;
      deliverPrice: boolean;
      detailPage: boolean;
    }>
  >;
  inputDeleveryPrice: () => void;
  inputSalePrice: () => void;
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
  submitProductEdit: UseMutationResult<any, unknown, void, unknown>;
  selectedItem: ProdcutManageResultItem[];
  isEdit: {
    salePrice: boolean;
    deliverPrice: boolean;
    detailPage: boolean;
  };
  productTitleValue: EditProductTitleValueType[];
  salePrice: { type: string; value: number };
  selectedTag: string[];
  deliveryPrice: { deliveryFee: number; returnFee: number };
  editCheckBoxValue: {
    name: boolean;
    price: boolean;
    detail: boolean;
    tag: boolean;
  };
  setEditCheckBoxValue: React.Dispatch<
    React.SetStateAction<{
      name: boolean;
      price: boolean;
      detail: boolean;
      tag: boolean;
    }>
  >;
  setFunction: () => void;
}

export const ProductEditModalBody = ({
  setProductTitleValue,
  setIsEdit,
  inputDeleveryPrice,
  inputSalePrice,
  selectedTag,
  submitProductEdit,
  selectedItem,
  isEdit,
  productTitleValue,
  salePrice,
  setSelectedTag,
  deliveryPrice,
  setEditCheckBoxValue,
  editCheckBoxValue,
  setFunction,
}: Props) => {
  const [currentTab, setCurrentTab] = useState("");

  const changeCheckBoxValue = (name: string, value: boolean) => {
    setEditCheckBoxValue({
      ...editCheckBoxValue,
      [name]: !value,
    });
  };

  const [wordLengthLimit, setWordLengthLimit] = useState(49);
  const [settingKeyWordValue, setSettingKeywordValue] = useState({
    forwardKeyword: "",
    backKeyword: "",
  });

  const clickGetBack = () => {
    setProductTitleValue(
      selectedItem.map((item) => {
        return {
          objectId: item.id,
          code: item.code,
          title: item.title.filter((item) => item.language === "KR")[0].text,
        };
      })
    );
    setWordLengthLimit(49);
    setSettingKeywordValue({ forwardKeyword: "", backKeyword: "" });
  };

  const checkEdit = (name: string) => {
    setIsEdit({
      ...isEdit,
      [name]: !isEdit[name as "salePrice" | "deliverPrice" | "detailPage"],
    });
  };

  const clickTab = (name: string) => {
    if (currentTab === name) {
      setCurrentTab("");
    } else {
      setCurrentTab(name);
    }
  };

  const clickPriceTab = () => {
    if (editCheckBoxValue.price) {
      setEditCheckBoxValue({
        ...editCheckBoxValue,
        price: false,
      });
      setIsEdit({
        ...isEdit,
        salePrice: false,
        deliverPrice: false,
      });
    } else {
      setEditCheckBoxValue({
        ...editCheckBoxValue,
        price: true,
      });
      setIsEdit({
        ...isEdit,
        salePrice: true,
        deliverPrice: true,
      });
    }
  };

  return (
    <div className="modal-body">
      <EditProductCheckBox
        isChecked={editCheckBoxValue.name}
        informationText="일부 오픈마켓 정책에 따라 상품명 변경이 어려울 수 있습니다."
        name="name"
        changeCheckBoxValue={changeCheckBoxValue}
        clickTab={clickTab}
      >
        상품명 수정
      </EditProductCheckBox>
      {currentTab === "name" && (
        <div className="inner-contents-padding">
          <EditProductKeyWordSetting
            settingKeyWordValue={settingKeyWordValue}
            setSettingKeywordValue={setSettingKeywordValue}
            setProductTitleValue={setProductTitleValue}
            productTitleValue={productTitleValue}
          />
          <EditWordLengthComponent
            setProductTitleValue={setProductTitleValue}
            setWordLengthLimit={setWordLengthLimit}
            wordLengthLimit={wordLengthLimit}
          />
          <EditWordChange setProductTitleValue={setProductTitleValue} />
          <hr className="mt-4 mb-4" />
          <div className="row align-items-center">
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <h6 className="function-text m-0">상품 정보</h6>
            </div>
          </div>
          <EidtProductTitleTable
            selectedItem={selectedItem}
            setProductTitleValue={setProductTitleValue}
            productTitleValue={productTitleValue}
            wordLengthLimit={wordLengthLimit}
          />
        </div>
      )}
      <EditProductCheckBox
        clickTab={clickTab}
        informationText=""
        isChecked={isEdit.salePrice || isEdit.deliverPrice}
        name="price"
        changeCheckBoxValue={changeCheckBoxValue}
        clickPriceTab={clickPriceTab}
      >
        가격 수정
      </EditProductCheckBox>
      {currentTab === "price" && (
        <>
          <div className="product-edit-price-box">
            <SelectSalePrice isEdit={isEdit.salePrice} checkEdit={checkEdit} />
            {isEdit.salePrice && (
              <EditSalePriceComponent
                salePrice={salePrice}
                inputSalePrice={inputSalePrice}
              />
            )}
            <SelectDeliveryPrice
              isEdit={isEdit.deliverPrice}
              checkEdit={checkEdit}
            />
            {isEdit.deliverPrice && (
              <EditDeliveryPriceComponent
                deliveryPrice={deliveryPrice}
                inputDeleveryPrice={inputDeleveryPrice}
              />
            )}
          </div>
        </>
      )}
      <EditProductCheckBox
        clickTab={clickTab}
        informationText="올땀 상품등록페이지의 상품 상세페이지 정보를 업데이트 합니다."
        isChecked={editCheckBoxValue.detail}
        name="detail"
        changeCheckBoxValue={changeCheckBoxValue}
        noPinter={true}
      >
        상세페이지 수정
      </EditProductCheckBox>
      <EditProductCheckBox
        clickTab={clickTab}
        informationText="기존 태그값은 삭제되고, 새롭게 키워드 검색·입력으로 태그 값을 업데이트 합니다."
        isChecked={editCheckBoxValue.tag}
        name="tag"
        changeCheckBoxValue={changeCheckBoxValue}
      >
        태그 수정
      </EditProductCheckBox>
      {currentTab === "tag" && (
        <EditProductTagSetting
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />
      )}
      <SubmitButtonComponent
        setFunction={setFunction}
        clickGetBack={clickGetBack}
        submitProductEdit={submitProductEdit}
      />
    </div>
  );
};
