export const ExchangeRateTableHead = () => {
  return (
    <thead>
      <tr>
        <th>
          <div className="weight600 text14 grey">기준</div>
        </th>
        <th>
          <div className="weight600 text14 grey">일일 고시환율</div>
        </th>
        <th>
          <div className="weight600 text14 grey">관세 주간환율</div>
        </th>
        <th>
          <div className="weight600 text14 grey">올땀 적용환율</div>
        </th>
      </tr>
    </thead>
  );
};
