import { Link, useLocation } from "react-router-dom";
import { UserInfoType } from "../../../interface/defaultinterface";
import { UsingGuide } from "../../svg/UsingGuideIcon";
import { ManagerIcon } from "../../svg/ManagerIcon";
import { useTooltip } from "@/hooks/useTooltip";

interface Props {
  userInfo: UserInfoType;
  isNav: boolean;
}

export const SideBarItemBottom = ({ userInfo, isNav }: Props) => {
  const [isManualTooltip, enterManual, leaveManual] = useTooltip();
  const location = useLocation();

  return (
    <ul>
      <li
        onMouseEnter={enterManual as () => void}
        onMouseLeave={leaveManual as () => void}
        className={location.pathname === "/guide" ? "activeItem" : ""}
      >
        {!isNav && isManualTooltip && <div className="nav-tooltip">가이드</div>}

        <Link to={"/manual"} className="Link" target="_blank">
          <UsingGuide />
          <span className="navItemListText">사용가이드</span>
        </Link>
      </li>
      <li className={`${location.pathname === "/admin" && "side-nav-top-bg"}`}>
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/admin" && "side-nav-top-radius"}`}
        ></div>
      </li>
      {userInfo?.role === "Admin" && (
        <li className={location.pathname === "/admin" ? "activeItem" : ""}>
          <Link to="/admin">
            <ManagerIcon />
            <span className="navItemListText">관리자</span>
          </Link>
        </li>
      )}
      <li className={`${location.pathname === "/admin" && "side-nav-top-bg"}`}>
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/admin" && "side-nav-bottom-radius"}`}
        ></div>
      </li>
    </ul>
  );
};
