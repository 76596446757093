interface Props {
  isSelect: boolean;
}

export const CoinSideIcon = ({ isSelect }: Props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className="relative"
      style={{
        top: "5px",
        left: "6px",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <>
        <path
          d="M7.77268 17.6818C3.47992 17.6818 -4.3869e-05 14.0186 -4.3869e-05 9.49993C-4.3869e-05 4.98124 3.47992 1.31812 7.77268 1.31812C12.0654 1.31812 15.5454 4.98124 15.5454 9.49993C15.5454 14.0186 12.0654 17.6818 7.77268 17.6818Z"
          stroke={`${isSelect ? "#00b8ba" : "#fff"}`}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.81827 6.88184C9.29753 6.45957 8.64779 6.22855 7.97736 6.22729C6.28291 6.22729 4.90918 7.69184 4.90918 9.50002C4.90918 11.3082 6.28291 12.7727 7.97736 12.7727C8.66791 12.7727 9.30527 12.5289 9.81827 12.1182"
          stroke={`${isSelect ? "#00b8ba" : "#fff"}`}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.36353 1.31812C10.9087 1.31812 17.9999 2.1363 17.9999 9.49993C17.9999 16.8636 10.9087 17.6818 7.36353 17.6818"
          stroke={`${isSelect ? "#00b8ba" : "#fff"}`}
          strokeWidth="1.5"
        />
      </>
    </svg>
  );
};
