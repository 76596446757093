import useToggle from "@/hooks/useToggle";
import { UploadSummary } from "@/interface/uploadinterface";
import { UseMutationResult } from "react-query";
import { EditMainImgModal } from "../../modal/EditMainImgModal";
import useModal from "@/hooks/useModal";

interface Props {
  item: UploadSummary;
  isViewWayDetail: boolean;
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
}

export const ItemMainImg = ({
  item,
  isViewWayDetail,
  collectProductSummary,
}: Props) => {
  const { openModal } = useModal();
  return (
    <div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          openModal(
            <EditMainImgModal
              item={item}
              collectProductSummary={collectProductSummary}
            />
          );
        }}
        className="madebutton"
      >
        <img
          className={`componentItemMainImg rounded-md ${
            !isViewWayDetail && "itemImg"
          }`}
          src={item.mainImages[0]}
        />
      </button>
    </div>
  );
};
