export const FourSquareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="그룹 10907">
        <path
          data-name="패스 10510"
          d="M5.585 0H2.021A1.959 1.959 0 0 0 0 2.124v3.358a1.957 1.957 0 0 0 2.021 2.124h3.564a1.957 1.957 0 0 0 2.021-2.124V2.124A1.957 1.957 0 0 0 5.585 0z"
          transform="translate(6 6)"
          stroke="#a7b0c7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          fillRule="evenodd"
          fill="none"
        ></path>
        <path
          data-name="패스 10513"
          d="M5.585 0H2.021A1.959 1.959 0 0 0 0 2.124v3.358a1.957 1.957 0 0 0 2.021 2.124h3.564a1.957 1.957 0 0 0 2.021-2.124V2.124A1.957 1.957 0 0 0 5.585 0z"
          transform="translate(6 16.394)"
          stroke="#a7b0c7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          fillRule="evenodd"
          fill="none"
        ></path>
        <path
          data-name="패스 10511"
          d="M5.585 0H2.021A1.959 1.959 0 0 0 0 2.124v3.358a1.957 1.957 0 0 0 2.021 2.124h3.564a1.957 1.957 0 0 0 2.021-2.124V2.124A1.957 1.957 0 0 0 5.585 0z"
          transform="translate(16.394 6)"
          stroke="#a7b0c7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          fillRule="evenodd"
          fill="none"
        ></path>
        <path
          data-name="패스 10512"
          d="M5.585 0H2.021A1.959 1.959 0 0 0 0 2.124v3.358a1.957 1.957 0 0 0 2.021 2.124h3.564a1.957 1.957 0 0 0 2.021-2.124V2.124A1.957 1.957 0 0 0 5.585 0z"
          transform="translate(16.394 16.394)"
          stroke="#a7b0c7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          fillRule="evenodd"
          fill="none"
        ></path>
      </g>
      <path data-name="사각형 3824" fill="none" d="M0 0h30v30H0z"></path>
    </svg>
  );
};
