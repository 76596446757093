import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";
import { Keyword } from "../../../../../interface/uploadinterface";
import { useTooltip } from "@/hooks/useTooltip";
import { TagSettingTableHead } from "./TagSettingTableHead";
import { TagSettingTableItem } from "./TagSettingTableItem";
import { NoTagMessage } from "./NoTagMessage";

interface Props {
  keywordList: Keyword[];
  selectedTag: string[];
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
  clickSort: (keyword: string, sortWay: "desc" | "asc") => void;
  scrollEvent: (e: React.UIEvent<HTMLElement, UIEvent>) => void;
  clickSortLiteral: (keyword: string, sortWay: "desc" | "asc") => void;
  clickSortGrade: (keyword: string, sortWay: "desc" | "asc") => void;
}

export const TagSettingTable = ({
  keywordList,
  selectedTag,
  setSelectedTag,
  clickSort,
  scrollEvent,
  clickSortLiteral,
  clickSortGrade,
}: Props) => {
  const [isCompetitionTooltip, enterCompetition, leaveCompetition] =
    useTooltip();
  const [isAverCountTooltip, enterAverCount, leaveAverCount] = useTooltip();
  const [isCountTooltip, enterCount, leaveCount] = useTooltip();
  const [isRateTooltip, enterRate, leaveRate] = useTooltip();
  const [isRelKeywordTooltip, enterRelKeyword, leaveRelKeyword] = useTooltip();
  return (
    <div className="row total-table-col mt-4 widthFull">
      <div className="col-12 relative widthFull">
        <GlobalTooltip
          style={{ top: "-2rem", right: "-10px", zIndex: "10" }}
          isVisible={isCompetitionTooltip as boolean}
        >
          <p className="margin0">
            최근 한달간 해당 키워드에 대한 경쟁정도를
            <br /> PC통합 검색영역 기준으로 높음/중간/낮음으로 <br /> 구분한
            지표입니다. 다수의 광고주가 추가한 <br /> 광고일수록 경쟁정도는 높을
            수 있습니다.
          </p>
        </GlobalTooltip>
        <GlobalTooltip
          style={{ top: "-4rem", right: "200px", zIndex: "10" }}
          isVisible={isAverCountTooltip as boolean}
        >
          <p className="margin0">
            최근 한 달간 사용자가 해당 키워드를 <br />
            검색했을 때, 통합검색 영역에 노출된 <br />
            광고가 받은 평균 클릭수입니다.
          </p>
        </GlobalTooltip>
        <GlobalTooltip
          style={{ top: "-3rem", left: "300px", zIndex: "10" }}
          isVisible={isCountTooltip as boolean}
        >
          <p className="margin0">
            최근 한 달간 네이버를 이용한 사용자가 해당 <br />
            키워드를 검색한 횟수입니다. (통합검색 기준)
          </p>
        </GlobalTooltip>
        <GlobalTooltip
          style={{ top: "-6.5rem", right: "140px", zIndex: "10" }}
          isVisible={isRateTooltip as boolean}
        >
          <p className="margin0">
            최근 한 달간 해당 키워드로 통합검색 영역에 노출된 <br />
            광고가 받은 평균 클릭률을 의미합니다. <br />
            <br />
            클릭률의 의미 : 광고가 노출되었을 때 노출된 광고가 <br />
            검색사용자로부터 클릭을 받는 비율을 말합니다.
          </p>
        </GlobalTooltip>
        <GlobalTooltip
          style={{ top: "2rem", left: "90px", zIndex: "10" }}
          isVisible={isRelKeywordTooltip as boolean}
        >
          선택한 기준과 연관도가 높은 키워드입니다.
        </GlobalTooltip>
        <div
          onScroll={scrollEvent}
          className="heading-two heading-three heading-five heading-five1 mt-0 tagSettingTable relative widthFull"
        >
          <table
            className="keyword-competition-rate1 keyWordSettingTable"
            width="100%"
            border={0}
            align="center"
          >
            <TagSettingTableHead
              leaveRelKeyword={leaveRelKeyword as () => void}
              enterCompetition={enterCompetition as () => void}
              leaveCompetition={leaveCompetition as () => void}
              enterAverCount={enterAverCount as () => void}
              leaveAverCount={leaveAverCount as () => void}
              enterCount={enterCount as () => void}
              leaveCount={leaveCount as () => void}
              enterRate={enterRate as () => void}
              leaveRate={leaveRate as () => void}
              enterRelKeyword={enterRelKeyword as () => void}
              clickSortGrade={clickSortGrade}
              clickSort={clickSort}
              clickSortLiteral={clickSortLiteral}
            />
            <tbody className="body-one keyword-compete-body gap-2">
              {keywordList?.map((keyword: Keyword) => (
                <TagSettingTableItem
                  key={keyword.relKeyword}
                  keyword={keyword}
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                />
              ))}
              {(!keywordList || keywordList.length === 0) && (
                <NoTagMessage colSpan={8} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
