import { isDeskTop } from "@/atom/atom";
import { OrderInfoModal } from "@/components/Ordermanage/modal/OrderInfoModal";
import useFetch from "@/hooks/useFetch";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import useToggle from "@/hooks/useToggle";
import {
  Delivery,
  ResultItem,
} from "../../../../../interface/orderManageInterface";

import { getEasyque, getSBLink } from "@/utils/functions/getSBLink";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import OrderManageTableItemInvocieModalMobile from "../../../modal/OrderManageTableItemInvoiceModal";

interface Props {
  item: ResultItem;
  isSelect: boolean;
  orderList?: ResultItem[];
  setOrderList?: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  idx?: number;
  selectedOrder?: any;
  selectOrder?: any;
}

export const ItemProductInfo = ({
  idx,
  item,
  isSelect,
  orderList,
  setOrderList,
  selectedOrder,
  selectOrder,
}: Props) => {
  const openWindow = useInfoWindow();
  const { state: isOrderInfoModal, handleToggle: handleOrderInfoModal } =
    useToggle();
  const isDesktop = useRecoilValue(isDeskTop);
  const [active, setActive] = useState(false);

  const onCloseInvocieModal = () => {
    selectOrder();
    setActive((prev) => !prev);
  };

  console.log(isDesktop);

  return (
    <td className="width30">
      <div
        className={`justify-content-start noScroll collect-inner-1 ${
          isSelect && "selectedItem"
        } orderProductInfo`}
      >
        <div className="textStart">
          <div
            className="input-collect gap-3"
            style={{
              display: 'flex"',
              alignItems: "start",
            }}
          >
            {item.mainImageUrl ? (
              <div className="noneImg">
                <img
                  className="componentItemImg rounded-md "
                  src={item.mainImageUrl}
                />
                {!isDesktop && <span>{item.productName}</span>}
              </div>
            ) : (
              <div>
                <div className="noneImg d-flex justify-content-center align-items-center ">
                  <p className="text8 textWhite weight500 navy margin0">
                    이미지가 없습니다
                  </p>
                </div>
                {!isDesktop && <span className="">{item.productName}</span>}
              </div>
            )}
            <div className="moblie-d-flex">
              <div
                className="input-collect-inner mb-2 "
                onClick={(e) => e.stopPropagation()}
              >
                {isDesktop && (
                  <>
                    <p className="font-bold margin0">
                      [{item.marketAccount}] {item.orderNumber}
                    </p>
                    <p className="font-bold margin0">
                      상품주문번호 :{item.orderCode}
                    </p>
                    <p className="margin0 mt-2 mobile-maxheight-productInfo">
                      [{item.originalSite}] {item.productName}
                    </p>
                  </>
                )}

                {isDesktop && (
                  <p className="textGreen mt-2">{item?.optionName}</p>
                )}
              </div>

              <div className="" onClick={(e) => e.stopPropagation()}>
                <button
                  onClick={handleOrderInfoModal}
                  className="tableItemInnerButton madebutton mr-2 mb-2"
                >
                  주문정보
                </button>
                <button
                  onClick={async () => {
                    if (item.originalProductUrl != "") {
                      getSBLink(item.code, item.originalProductUrl);
                    }
                    if (item.originalProductUrl == "") {
                      const url = await getEasyque(
                        item.openmarketProductNumber
                      );

                      if (url == null || url.originalLink == "") {
                        return openWindow("원본상품링크가 없습니다.");
                      }

                      getSBLink(item.code, url.originalLink);
                    }
                  }}
                  className="tableItemInnerButton madebutton mr-2 mb-2"
                >
                  원본상품링크
                </button>
                <button
                  onClick={() => {
                    window.open(item.openmarketProductUrl, "_blank");
                  }}
                  className="tableItemInnerButton madebutton mr-2 mb-2"
                >
                  판매상품링크
                </button>
                {!isDesktop && (
                  <button
                    onClick={() => {
                      selectOrder();
                      setActive((prev) => !prev);
                    }}
                    className="tableItemInnerButton madebutton mr-2"
                  >
                    송장 입력
                  </button>
                )}
              </div>
              <div className="coupan-text"></div>
            </div>
          </div>
        </div>
      </div>

      {active && (
        <OrderManageTableItemInvocieModalMobile
          item={item}
          orderList={orderList}
          setOrderList={setOrderList}
          setClose={onCloseInvocieModal}
          selectedOrder={selectedOrder}
          idx={idx}
        />
      )}
      {isOrderInfoModal && (
        <OrderInfoModal idx={item?.idx} closeModal={handleOrderInfoModal} />
      )}
    </td>
  );
};
