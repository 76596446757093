interface Props {
  checkEdit: (name: string) => void;
  isEdit: boolean;
}

export const SelectSalePrice = ({ checkEdit, isEdit }: Props) => {
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="d-flex align-items-center gap-3 attributes1 m-0">
          <div
            className="d-flex align-items-center gap-3 pointer"
            onClick={() => {
              checkEdit("salePrice");
            }}
          >
            <div className="form-group">
              <input
                name="salePrice"
                checked={isEdit}
                type="checkbox"
                id="salePrice"
                readOnly
              />
              <label
                onClick={() => {
                  checkEdit("salePrice");
                }}
              ></label>
            </div>
            <p>판매가 수정</p>
          </div>
          <span className="being-sold-span">
            오픈마켓에 등록되어진 상품 판매가를 기준으로 가격을 수정할 수
            있습니다.
          </span>
        </div>
      </div>
    </div>
  );
};
