export const PayInfo = () => {
  return (
    <>
      <div className="col-12 mt-3">
        <div className="coin-info">
          <h6>코인 안내 사항</h6>
          <p>
            - 수집 글자 수 100자 당 3코인 사용
            <br />- 이미지 번역 편집기 접속 1회 당 3코인 사용
          </p>
        </div>
      </div>
      <div className="col-12">
        <div className="coin-info">
          <h6>결제 안내 사항</h6>
          <p>
            - 정기 구독 결제 기능은 준비중입니다.
            <br />- 할인 / 증정 프로모션 등으로 인해 코인은 환불되지 않습니다.
            <br />- (중요) 유료 결제 후, operate@allttam.com 또는 채널톡을 통해
            반드시 구독 계정 셋팅을 요청해주세요
          </p>
        </div>
      </div>
    </>
  );
};
