import { GreenMiddleButton } from "@/components/Button/allttamButton/GreenMiddleButton";
import { YellowMiddleButton } from "@/components/Button/allttamButton/yellowMiddleButton";
import { ToastEditor } from "@/components/ToastEditor";
import useToggle from "@/hooks/useToggle";
import { base64toBlob } from "@/utils/functions/base64toBlob";
import { fileRequest } from "@/utils/functions/fileRequest";
import { EditorId } from "../../EditDetailPageModal";

interface Props {
  selectedUrl: string;
  model: string;
  setModel: React.Dispatch<React.SetStateAction<string>>;
  setPannelList: React.Dispatch<React.SetStateAction<EditorId[]>>;
}

export const DetailEditorPlus = ({
  selectedUrl,
  model,
  setModel,
  setPannelList,
}: Props) => {
  const { state: isToast, handleToggle: handleToast } = useToggle();

  const applyImage = async (base64: string) => {
    const blob = await base64toBlob(base64);
    const res = await fileRequest("/Image/UploadImage", blob);
    if (model.includes(selectedUrl)) {
      setModel(model.replace(selectedUrl, res?.link));
    } else if (model.includes(selectedUrl.replace("https", "http"))) {
      setModel(model.replace(selectedUrl.replace("https", "http"), res?.link));
    }

    setPannelList((prev) =>
      prev.map((item) => {
        if (item.originalUrl === selectedUrl) {
          return res.link;
        } else {
          return item;
        }
      })
    );
  };

  return (
    <>
      {" "}
      <div className="d-flex gap-3">
        {/* <OrangeMiddleButton>일괄 이미지 번역</OrangeMiddleButton> */}
        <GreenMiddleButton onClick={handleToast}>
          올땀 에디터 +
        </GreenMiddleButton>
      </div>
      {isToast && (
        <ToastEditor
          applyImage={applyImage}
          cropImg={selectedUrl}
          closeEditor={handleToast}
        />
      )}
    </>
  );
};
