import { useEffect } from "react";
import { usePost } from "@/hooks/usePost";
import { MarketAccount } from "@/interface/productmanageinterface";
import { useTooltip } from "@/hooks/useTooltip";
import { MarketEnglish } from "@/interface/uploadinterface";
import coupangLogo from "@/assets/images/marketIcon/coupang.svg";
import smartStoreLogo from "@/assets/images/marketIcon/smartstore.svg";
import auctionLogo from "@/assets/images/marketIcon/auction.svg";
import gmakretLogo from "@/assets/images/marketIcon/gmarket.svg";
import elevenstLogo from "@/assets/images/marketIcon/elevenst.svg";

interface Props {
  result: { market: string; url: string }[];
  account: string;
  selectedMarket: MarketAccount | undefined;
  isSelect: boolean;
}

export const ItemUploadMarketButtonBox = ({
  result,
  account,
  selectedMarket,
  isSelect,
}: Props) => {
  const [isTooltipVisible, handleMouseEnter, handleMouseLeave] = useTooltip();

  const getCoupangUrl = usePost("/ProductManagement/GetCoupangProductUrl", {
    accessKey: selectedMarket?.account.accessKey,
    marketAccount: selectedMarket?.account.account,
    secretKey: selectedMarket?.account.secretKey,
    sellerProductId: result.filter((item) => item.market === "Coupang")[0]?.url,
    vendorId: selectedMarket?.account.vendorId,
  });

  const clickToCoupnagLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    getCoupangUrl.mutate();
  };

  useEffect(() => {
    if (getCoupangUrl?.data) {
      window.open(`${getCoupangUrl.data}`);
    }
  }, [getCoupangUrl?.data]);

  return (
    <td>
      <div
        className={`collect-inner-1 gap-3 tooltipRel ${
          isSelect && "selectedItem"
        }`}
      >
        {isTooltipVisible && (
          <div className="productManageIconTooltip oneLine">
            상품 URL로 이동
          </div>
        )}
        {result.map((item, idx) => (
          <button
            key={idx}
            onClick={(e) => {
              e.stopPropagation();
              if (item.market === "Coupang") {
                clickToCoupnagLink(e as any);
              } else {
                window.open(item.url);
              }
            }}
            disabled={
              selectedMarket?.market !== "Coupang" && item.market === "Coupang"
            }
            onMouseEnter={handleMouseEnter as () => void}
            onMouseLeave={handleMouseLeave as () => void}
            className="toMarketAnchor madebutton paddingZero"
          >
            <img
              className="logoBtnBg"
              src={IMG_INDEX[item.market as MarketEnglish]}
              alt=""
            />
          </button>
        ))}
      </div>
    </td>
  );
};

const IMG_INDEX = {
  SmartStore: smartStoreLogo,
  Coupang: coupangLogo,
  Auction: auctionLogo,
  Gmarket: gmakretLogo,
  Elevenst: elevenstLogo,
};
