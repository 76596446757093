import HTMLReactParser from "html-react-parser";
import { useEffect, useMemo, useState } from "react";

interface Props {
  item: any;
  setSelectedCollectItem: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCollectItem: string[];
}

export const CollectListItem = ({
  item,
  setSelectedCollectItem,
  selectedCollectItem,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCollectItem.includes(item.item_id)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedCollectItem]);
  // const isSelect = useMemo(() => {
  //   return selectedCollectItem.includes(item.auctionURL);
  // }, [selectedCollectItem]);

  const selectItem = () => {
    if (!isSelect) {
      setSelectedCollectItem((prev) => [...prev, item.item_id]);
    } else {
      setSelectedCollectItem((prev) =>
        prev.filter((collect) => collect !== item.item_id)
      );
    }
  };

  return (
    <div className="custom-col-product" onClick={selectItem}>
      <div
        className={`product-col  collectItem ${
          isSelect && "selectedCollectItem"
        }`}
      >
        <img className="collectItemImg" src={item.pic_path} />
        <h4>{HTMLReactParser(item.title)}</h4>
        <div className="product-col-inner">
          <h6>판매가</h6>
          <h5>{item.priceWithRate || item.price}</h5>
        </div>
        <a href={item.auctionURL} target="_blank">
          <button className="cancel-btn short-btn">바로가기</button>
        </a>
      </div>
    </div>
  );
};
