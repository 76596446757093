export const SquarePlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <g data-name="그룹 9820">
        <path
          data-name="패스 10357"
          d="m0 0 7.793 7.793"
          fill="none"
          stroke="#00b8ba"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          transform="rotate(-45 17.73 -1.833)"
        />
        <path
          data-name="패스 10358"
          d="M7.793 0 0 7.793"
          fill="none"
          stroke="#00b8ba"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          transform="rotate(-45 17.73 -1.833)"
        />
      </g>
      <path data-name="사각형 3654" fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};
