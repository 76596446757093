import { FourSquareIcon } from "@/components/svg/FourSquareIcon";
import {
  ProdcutManageResultItem,
  ProductManageSearchingValue,
} from "@/interface/productmanageinterface";
import { UseMutationResult } from "react-query";

interface Props {
  setSearchingValue: React.Dispatch<
    React.SetStateAction<{
      pageLimit: number;
      code: string;
      productName: string;
      memo: string;
      groupName: string;
      uploadWorkNumber: string;
      sort: boolean;
    }>
  >;
  getSearchResult: UseMutationResult<any, unknown, void, unknown>;
  searchingValue: ProductManageSearchingValue;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<ProdcutManageResultItem[]>
  >;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
}

export const SelectCount = ({
  setSearchingValue,
  searchingValue,
  getSearchResult,
  setSelectedItem,
  setPageNum,
}: Props) => {
  const selectCount = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSelectedItem([]);
    setSearchingValue({
      ...(searchingValue as any),
      [name]: parseInt(value),
    });
  };
  return (
    <div className="state-selection total-select mobile-dp-none">
      <FourSquareIcon />
      <select
        onChange={async (e) => {
          await selectCount(e);
          await setPageNum(1);
          getSearchResult.mutate();
        }}
        className="form-select"
        defaultValue="30"
        name="pageLimit"
      >
        <option value="30">30개</option>
        <option value="50">50개</option>
        <option value="100">100개</option>
        <option value="300">300개</option>
      </select>
    </div>
  );
};
