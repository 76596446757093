export const ManagerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 3750" fill="none" d="M0 0h30v30H0z" />
      <path
        className="icon"
        d="M3.8 16.012v2.68a.811.811 0 0 0 .8.808h10.81a.811.811 0 0 0 .809-.809v-2.68l3.238-8.562a.808.808 0 0 0-1.136-1L13.392 9.1l-2.017-3.729a2.633 2.633 0 1 0-2.737 0L6.621 9.095 1.7 6.451a.808.808 0 0 0-1.136 1zm10.8 1.872H5.412v-1.213H14.6v1.213zM10 2.117a1.011 1.011 0 1 1-1.006 1.011A1.011 1.011 0 0 1 10 2.117zM6.568 10.9a.812.812 0 0 0 1.1-.327L10 6.241l2.341 4.334a.812.812 0 0 0 1.1.327L17.176 8.9l-2.329 6.157H5.162L2.833 8.9z"
        transform="translate(4.993 5)"
        fill="#fff"
      />
    </svg>
  );
};
