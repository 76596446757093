import { WhiteGreenSmallButton } from "@/components/Button/allttamButton/WhiteGreenSmallButton";
import { WhiteMiddleButton } from "@/components/Button/allttamButton/WhiteMiddleButton";
import { ReactCropperElement } from "react-cropper";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const ResetEditorButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const clickResetEditor = () => {
    cropperRef.current?.cropper.reset();
  };
  return (
    <WhiteMiddleButton
      onClick={clickResetEditor}
      onMouseLeave={mouseLeave}
      onMouseEnter={() => {
        mouseHover("레이어 이미지 작업을 초기화");
      }}
    >
      레이어 초기화
    </WhiteMiddleButton>
  );
};
