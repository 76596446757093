export const ModifyProductButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal14"
      className="cancel-btn save-btn reset-btn name-set-btn mobile-dp-none"
    >
      상품 간략 수정
    </button>
  );
};
