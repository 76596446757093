export const UploadMarketSelectSubtitle = () => {
  return (
    <div className="row mt-5">
      <div className="col-12">
        <div>
          <h6 className="function-text m-0">마켓 계정 선택</h6>
        </div>
      </div>
    </div>
  );
};
