import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import { UpDownArrow } from "@/components/svg/UpDownArrow";
import { useTooltip } from "@/hooks/useTooltip";
import { useState } from "react";

interface Props {
  clickSort: (keyword: string, sortWay: "desc" | "asc") => void;
  clickSortLiteral: (keyword: string, sortWay: "desc" | "asc") => void;
  clickSortGrade: (keyword: string, sortWay: "desc" | "asc") => void;
  leaveCompetition: () => void;
  enterCompetition: () => void;
  enterAverCount: () => void;
  leaveAverCount: () => void;
  enterCount: () => void;
  leaveCount: () => void;
  enterRate: () => void;
  leaveRate: () => void;
  enterRelKeyword: () => void;
  leaveRelKeyword: () => void;
}

export const TagSettingTableHead = ({
  leaveRelKeyword,
  leaveRate,
  enterRelKeyword,
  clickSort,
  enterCount,
  leaveCount,
  enterRate,
  enterAverCount,
  leaveAverCount,
  clickSortLiteral,
  clickSortGrade,
  leaveCompetition,
  enterCompetition,
}: Props) => {
  const [recentClickState, setRecentClickState] = useState({
    name: "",
    value: "desc",
  });

  const clickSortingButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name, value } = e.currentTarget;
    clickSort(name, value as "desc" | "asc");
    if (value === "desc") {
      setRecentClickState({
        name,
        value: "asc",
      });
    } else {
      setRecentClickState({
        name,
        value: "desc",
      });
    }
  };

  const clickSortGradeButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name, value } = e.currentTarget;
    clickSortGrade(name, value as "desc" | "asc");
    if (value === "desc") {
      setRecentClickState({
        name,
        value: "asc",
      });
    } else {
      setRecentClickState({
        name,
        value: "desc",
      });
    }
  };

  const clickSortLiteralButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name, value } = e.currentTarget;
    clickSortLiteral(name, value as "desc" | "asc");
    if (value === "desc") {
      setRecentClickState({
        name,
        value: "asc",
      });
    } else {
      setRecentClickState({
        name,
        value: "desc",
      });
    }
  };

  return (
    <thead className="head-one">
      <tr className="keyword-compete1 relKeyword">
        <th></th>
        <th colSpan={2}>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <p className="margin0 tag-setting-table-head">월간 검색수</p>
            <QuestionMarkIcon
              onMouseEnter={enterCount}
              onMouseLeave={leaveCount}
            />
          </div>
        </th>
        <th colSpan={2}>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <p className="margin0 tag-setting-table-head">월평균 클릭수</p>
            <QuestionMarkIcon
              onMouseEnter={enterAverCount}
              onMouseLeave={leaveAverCount}
            />
          </div>
        </th>
        <th colSpan={3}>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <p className="margin0 tag-setting-table-head">월평균 클릭률</p>
            <QuestionMarkIcon
              onMouseEnter={enterRate}
              onMouseLeave={leaveRate}
            />
          </div>
        </th>
      </tr>
      <tr className="keyword-compete2 oneLine relKeyword table-sticky">
        <th>
          <div className="mob-key">
            <button
              onClick={clickSortLiteralButton}
              name="relKeyword"
              value={recentClickState.value}
              className="madebutton d-flex gap-3 align-items-center"
            >
              연관 키워드
              <QuestionMarkIcon
                onMouseEnter={enterRelKeyword}
                onMouseLeave={leaveRelKeyword}
              />
              <UpDownArrow
                up={
                  recentClickState.name === "relKeyword" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "relKeyword" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
        <th>
          <div className="mob-key">
            <button
              name="monthlyPcQcCnt"
              value={recentClickState.value}
              className="madebutton d-flex gap-3 align-items-center"
              onClick={clickSortingButton}
            >
              PC
              <UpDownArrow
                up={
                  recentClickState.name === "monthlyPcQcCnt" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "monthlyPcQcCnt" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
        <th>
          <div className="mob-key">
            <button
              value={recentClickState.value}
              onClick={clickSortingButton}
              name="monthlyMobileQcCnt"
              className="madebutton d-flex gap-3 align-items-center"
            >
              Mobile
              <UpDownArrow
                up={
                  recentClickState.name === "monthlyMobileQcCnt" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "monthlyMobileQcCnt" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
        <th>
          <div className="mob-key">
            <button
              value={recentClickState.value}
              onClick={clickSortingButton}
              name="monthlyAvePcClkCnt"
              className="madebutton d-flex gap-3 align-items-center"
            >
              PC
              <UpDownArrow
                up={
                  recentClickState.name === "monthlyAvePcClkCnt" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "monthlyAvePcClkCnt" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
        <th>
          <div className="mob-key">
            <button
              value={recentClickState.value}
              onClick={clickSortingButton}
              name="monthlyAveMobileClkCnt"
              className="madebutton d-flex gap-3 align-items-center"
            >
              Mobile
              <UpDownArrow
                up={
                  recentClickState.name === "monthlyAveMobileClkCnt" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "monthlyAveMobileClkCnt" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
        <th>
          <div className="mob-key">
            <button
              onClick={clickSortGradeButton}
              value={recentClickState.value}
              name="compIdx"
              className="madebutton d-flex gap-3 align-items-center"
            >
              경쟁 정도
              <QuestionMarkIcon
                onMouseEnter={enterCompetition}
                onMouseLeave={leaveCompetition}
              />
              <UpDownArrow
                up={
                  recentClickState.name === "compIdx" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "compIdx" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
        <th>
          <div className="mob-key">
            <button
              value={recentClickState.value}
              onClick={clickSortingButton}
              name="monthlyAvePcCtr"
              className="madebutton d-flex gap-3 align-items-center"
            >
              PC
              <UpDownArrow
                up={
                  recentClickState.name === "monthlyAvePcCtr" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "monthlyAvePcCtr" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
        <th>
          <div className="mob-key">
            <button
              value={recentClickState.value}
              onClick={clickSortingButton}
              name="monthlyAveMobileCtr"
              className="madebutton d-flex gap-3 align-items-center"
            >
              Mobile
              <UpDownArrow
                up={
                  recentClickState.name === "monthlyAveMobileCtr" &&
                  recentClickState.value === "desc"
                }
                down={
                  recentClickState.name === "monthlyAveMobileCtr" &&
                  recentClickState.value === "asc"
                }
              />
            </button>
          </div>
        </th>
      </tr>
    </thead>
  );
};
