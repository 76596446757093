import { SaleStopIcon } from "@/components/svg/SaleStopIcon";

interface Props {
  value: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SaleStopButton = ({ onClick, value }: Props) => {
  return (
    <button
      value={value}
      onClick={onClick}
      className="cancel-btn save-btn reset-btn del-btn sale-reg-btn play-btn mobile-width"
    >
      <SaleStopIcon />
      판매 중지
    </button>
  );
};
