export const ORDER_STATUS = [
  { id: 0, title: "주문접수", value: "RECEPTION" },
  { id: 1, title: "결제완료", value: "PAYMENT" },
  { id: 2, title: "배송준비중", value: "READY" },
  { id: 3, title: "해외현지 배송중", value: "OVERSEASDELIVERY" },
  { id: 4, title: "해외현지 배송완료", value: "OVERSEASCOMPLETE" },
  { id: 5, title: "배송지 입고완료", value: "OVERSEASSTORED" },
  { id: 6, title: "오류배송", value: "OVERSEASERROR" },
  { id: 7, title: "국내배송중", value: "DELIVERY" },
  { id: 8, title: "배송완료", value: "COMPLETE" },
  { id: 9, title: "취소/교환/반품 진행중", value: "CSPROCEEDING" },
  { id: 10, title: "취소/교환/반품 완료", value: "CSCOMPLETE" },
];

export const ORDER_SEARCHING_WAY = [
  { id: 0, title: "주문자명", value: "ORDERERNAME" },
  { id: 1, title: "주문번호", value: "ORDERNUMBER" },
  { id: 2, title: "전화번호", value: "TELNO" },
  { id: 3, title: "상품명", value: "PRODUCTNAME" },
  { id: 4, title: "해외주문번호", value: "OVERSEASORDER" },
  { id: 5, title: "트랙킹넘버", value: "TRACKING" },
  { id: 6, title: "해외상품번호", value: "PRODUCTCODE" },
  { id: 7, title: "국내송장번호", value: "INVOICE" },
  { id: 8, title: "오픈마켓상품번호", value: "OPENMARKETPRODUCTNUMBER" },
  { id: 9, title: "간단메모", value: "SIMPLEMEMO" },
];
