import { useEffect, useState } from "react";
import { CS } from "../CSTable/CSTable";
import { SelectCS } from "../OrderManageCS";

interface Props {
  cs: CS;
  selectedCS: SelectCS | undefined;
  setSelectedCS: React.Dispatch<React.SetStateAction<SelectCS | undefined>>;
}

export const CSTableItem = ({ cs, selectedCS, setSelectedCS }: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCS?.cs?.no === cs.no) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedCS]);

  const selectCs = () => {
    if (isSelect) {
      setSelectedCS({
        ...selectedCS!,
        cs: undefined,
      });
    } else {
      setSelectedCS({
        ...selectedCS!,
        cs: cs,
      });
    }
  };

  return (
    <tr onClick={selectCs}>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0 oneLine">{cs.market}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex flex-column align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0 oneLine paddingZero">
            {cs.regDate.split(" ")[0]}
          </p>
          <p className="margin0 oneLine paddingZero">
            {cs.regDate.split(" ")[1]}
          </p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0">
            {cs.orderNo === "" ? "X" : cs.orderNo === ""}
          </p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0">{cs.title}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0">{cs.content}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0">{cs.productNo}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0 ellipsis">{cs.productName}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0">{cs.questioner}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center ${
            isSelect && "selectedItem"
          }`}
        >
          <a
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={cs.marketProductUrl}
            target="_blank"
          >
            <button type="button" className="cut-btn">
              바로가기
            </button>
          </a>
        </div>
      </td>
    </tr>
  );
};
