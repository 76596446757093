import useFetch from "@/hooks/useFetch";
import { Banner } from "./Banner";
import banner1 from "@/assets/images/banner/banner1.jpg";
import banner2 from "@/assets/images/banner/banner2.jpg";
import { useRecoilState } from "recoil";
import { allttamCare } from "@/atom/atom";
import { Spinner } from "@/components/Spinner/Spinner";
import { memo } from "react";

const Homebanner = () => {
  const [bannerList] = useFetch(
    "banner",
    "https://center.allttam.com/api/banner",
    1000 * 60 * 5,
    true,
    undefined,
    1000 * 60 * 10
  );
  const [isOpenCare, setIsOpenCare] = useRecoilState(allttamCare);

  return (
    <div className="col-md-6 col-12 md-d-none">
      <div className="slideBanner">
        {bannerList && <Banner bannerArray={bannerList.imgs} />}
        <div className="fixedBanner">
          <img
            onClick={() => {
              window.open("https://center.allttam.com", "_blank");
            }}
            src={banner1}
            className="smallbanner pointer"
          />
          <img
            onClick={(e) => {
              setIsOpenCare((prev) => !prev);
            }}
            src={banner2}
            className="smallbanner pointer"
          />
        </div>
      </div>
    </div>
  );
};

export const HomeBanner = memo(Homebanner);
