import { upload } from "../../../../../utils/functions/postApi";
import { replaceWord } from "../../../../../utils/functions/replaceWord";
import { sliceProhibitWord } from "../../../../../utils/functions/sliceProhibitWord";
import { usePost } from "../../../../../hooks/usePost";
import { ProhibitReplaceWord } from "../../../../../interface/uploadinterface";

export const ProductNameSubtitleAndButton = () => {
  return (
    <div className="row align-items-center mt-5">
      <div className="d-flex align-items-center gap-3 justify-content-between">
        <h6 className="function-text m-0">상품 정보</h6>
      </div>
    </div>
  );
};
