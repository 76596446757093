import { useRecoilValue } from "recoil";
import { infoWindowState } from "@/atom/atom";
import { useEffect, useState } from "react";

interface Props {
  message: string;
}

export const InfoWindow = ({ message }: Props) => {
  const isWindowOpen = useRecoilValue(infoWindowState);
  const messageArray = message.split("\n");
  const [alarmArray, setAlarmArray] = useState<Array<string[]>>([]);

  useEffect(() => {
    if (isWindowOpen.isOpen) {
      setAlarmArray((prevAlarmArray) => [...prevAlarmArray, messageArray]);
    } else {
      setAlarmArray([]);
    }
  }, [isWindowOpen]);

  return (
    <>
      {alarmArray.map((message, index) => (
        <div
          key={index}
          className={`information-window ${isWindowOpen.isOpen ? "open" : ""}
          ${!isWindowOpen.animation ? "closed" : ""}`}
          style={{ position: "fixed" }}
        >
          <div>{message[0]}</div>
          <p className="">{message[1]}</p>
        </div>
      ))}
    </>
  );
};
