import { UseMutationResult } from "react-query";
import { ProhibitReplaceWord } from "../../../../../interface/uploadinterface";
import { ProhibitWordTableItem } from "./ProhibitWordTableItem";
import { useTooltip } from "@/hooks/useTooltip";
import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";
import { NoneItemMessage } from "@/components/Admin/AdminCategoryManage/component/openmarketcategorymanage/openmarket/NoneItemMessage";

interface Props {
  prohibitWord: ProhibitReplaceWord[];
  selectedProhibitWordList: ProhibitReplaceWord[];
  setSelectedProhibitWordList: React.Dispatch<
    React.SetStateAction<ProhibitReplaceWord[]>
  >;
  getProhibitWord: UseMutationResult<any, unknown, void, unknown>;
  clickAll: () => Promise<void>;
  isAll: boolean;
  setSearchProhibitValue: React.Dispatch<React.SetStateAction<string>>;
  searchProhibitValue: string;
}

export const ProhibitWordTable = ({
  prohibitWord,
  selectedProhibitWordList,
  setSelectedProhibitWordList,
  getProhibitWord,
  clickAll,
  isAll,
  searchProhibitValue,
  setSearchProhibitValue,
}: Props) => {
  const [isInputTooltip, enterInput, leaveInput] = useTooltip();
  const [isFunctionTooltip, enterFunction, leaveFunction] = useTooltip();

  const inputSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProhibitValue(e.target.value);
  };

  return (
    <div className="row total-table-col mt-4 collect-market">
      <div className="col-12">
        <div className="heading-two heading-three heading-five heading-five1 m-0 relative prohibitWordTableMinHeight">
          <GlobalTooltip
            isVisible={isInputTooltip as boolean}
            style={{ left: "130px" }}
          >
            등록된 금지 단어를 검색할 수 있습니다.
          </GlobalTooltip>
          <GlobalTooltip
            isVisible={isFunctionTooltip as boolean}
            style={{ right: "0" }}
          >
            선택한 금지 단어를 등록에서 제거합니다.
          </GlobalTooltip>
          <table
            className="productNameTable"
            align="center"
            width="100%"
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <thead className="head-one">
              <tr>
                <th>
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="htmlaxs1"
                      checked={isAll}
                      onClick={clickAll}
                      readOnly
                    />
                    <label htmlFor="htmlaxs1"></label>
                  </div>
                </th>
                <th
                  onMouseEnter={enterInput as () => void}
                  onMouseLeave={leaveInput as () => void}
                >
                  <div>
                    <input
                      value={searchProhibitValue}
                      onChange={inputSearchValue}
                      className="prohibitSearchInput"
                      placeholder="금지 단어"
                    />
                  </div>
                </th>
                <th>등록일자</th>
                <th
                  onMouseEnter={enterFunction as () => void}
                  onMouseLeave={leaveFunction as () => void}
                >
                  기능
                </th>
              </tr>
            </thead>
            <tbody className="body-one price-one">
              {prohibitWord?.map((word) => (
                <ProhibitWordTableItem
                  getProhibitWord={getProhibitWord}
                  key={word.idx}
                  word={word}
                  setSelectedProhibitWordList={setSelectedProhibitWordList}
                  selectedProhibitWordList={selectedProhibitWordList}
                />
              ))}
              {prohibitWord.length === 0 && <NoneItemMessage />}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
