import { useQueryClient } from "react-query";
import { upload } from "@/utils/functions/postApi";
import { useAlert } from "@/hooks/useAlert";
import { DefaultInfo, Desc } from "@/interface/uploadinterface";
import { AlertOnlyClose } from "@/components/Alert/AlertOnlyClose";

interface Props {
  closeModal: () => void;
  editValue: {
    productName: string;
    productPrice: string;
    memo: string;
  };
  tagList: string[];
  descList: Desc[];
  imgList: string[];
  getItemDefaultInfo: DefaultInfo;
}

export const EditDefaultSetButton = ({
  closeModal,
  getItemDefaultInfo,
  imgList,
  descList,
  editValue,
  tagList,
}: Props) => {
  const queryClient = useQueryClient();
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const clickConfirmEdit = async () => {
    const editRes = await upload.editDefaultInfo({
      code: getItemDefaultInfo.code,
      currency: getItemDefaultInfo.currency,
      descs: getItemDefaultInfo.descs
        ? getItemDefaultInfo.descs
            .filter((item) => item.language === "CN")
            .concat(descList)
        : descList,
      filterDescs: descList,
      detailpageHtml: getItemDefaultInfo.detailPageHtml,
      groupName: getItemDefaultInfo.groupName,
      mainImages: imgList,
      memo: editValue.memo,
      option: getItemDefaultInfo.option,
      originalDetailPageUrls: getItemDefaultInfo.originalDetailPageUrls,
      price: [
        getItemDefaultInfo.price[0],
        {
          currency: "KRW",
          originalPrice: editValue.productPrice,
          salePrice: editValue.productPrice,
        },
      ],
      regDate: getItemDefaultInfo.regDate,
      site: getItemDefaultInfo.site,
      tags: tagList,
      translateDetailPageUrls: getItemDefaultInfo.translateDetailPageUrls,
      url: getItemDefaultInfo.url,
      videoUrl: getItemDefaultInfo.videoUrl,
      workNumber: getItemDefaultInfo.workNumber,
      title: [
        getItemDefaultInfo.title[0],
        {
          language: "KR",
          text: editValue.productName,
        },
      ],
    });
    if (editRes === "성공하였습니다") {
      queryClient.invalidateQueries(`itemInfo${getItemDefaultInfo.Id}`);
      closeModal();
    } else {
      handleAlert("수정 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
          <button onClick={closeModal} className="cancel-btn">
            취소
          </button>
          <button onClick={clickConfirmEdit} className="cancel-btn save-btn">
            저장
          </button>
        </div>
      </div>
      <div>
        {isAlert && (
          <AlertOnlyClose
            message={alertMessage}
            closeAlert={handleAlert as () => void}
          />
        )}
      </div>
    </div>
  );
};
