import useUser from "@/hooks/useUser";
import axiosInstance from "@/utils/axiosInstance";
import { useQuery } from "react-query";

const useVvicId = () => {
  const user = useUser();
  const getUserSourcingMarketId = async () => {
    const res = await axiosInstance.post(
      `/Product/GetSourcingAccount`,
      {},
      {
        params: {
          userId: user.username,
          sourcingSite: "vvic",
        },
      }
    );

    if (res.data.code === 503) {
      return false;
    }

    return res.data;
  };

  const { data } = useQuery(
    ["sourcingMarketId", user.username],
    getUserSourcingMarketId
  );

  return { isUploadId: data };
};

export default useVvicId;
