import { GreenMiddleButton } from "@/components/Button/allttamButton/GreenMiddleButton";

interface Props {
  applyImage: (img: string) => Promise<void>;
  editedImg: string;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const ApplyThumbNailButton = ({
  applyImage,
  editedImg,
  mouseHover,
  mouseLeave,
}: Props) => {
  return (
    <GreenMiddleButton
      onMouseLeave={mouseLeave}
      onClick={() => applyImage(editedImg)}
      onMouseEnter={() => mouseHover("이미지 작업물을 썸네일 패널에 적용")}
    >
      패널 적용
    </GreenMiddleButton>
  );
};
