import { Revenue } from "../../../../interface/orderManageInterface";

interface Props {
  inputRevenueValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  revenueValue: Revenue;
}

export const RevenueAdjustPrice = ({
  inputRevenueValue,
  revenueValue,
}: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-3">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">정산예정금액</p>
        <div className="widthHalf d-flex align-items-center">
          <input
            value={revenueValue.adjustPrice}
            name="adjustPrice"
            className="orderInfoModalInput"
            type="number"
            disabled
            readOnly
          />
          <p className="margin0 pl-1">원</p>
        </div>
      </div>
    </div>
  );
};
