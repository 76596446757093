export const UsingGuide = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        className="guideIcon"
        data-name="패스 10527"
        d="M16.727 21.273v-4.782a4.085 4.085 0 0 0 0-7.491v4.091l-1.636.818-1.636-.818V9a4.085 4.085 0 0 0 0 7.491v4.781"
        transform="translate(-.091 -.091)"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth="1.5px"
        fill="none"
      />
      <path
        className="guideIcon"
        data-name="패스 10528"
        d="M23 20.181a2.809 2.809 0 0 0-.818 2.046h0A2.808 2.808 0 0 0 23 24.272H7.045A2.045 2.045 0 0 1 5 22.227h0a2.045 2.045 0 0 1 2.045-2.045H23V3H7.045A2.045 2.045 0 0 0 5 5.045v17.182"
        transform="translate(1 1)"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth="1.5px"
        fill="none"
      />
      <path data-name="사각형 3849" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
