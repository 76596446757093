import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import useSelectHelperComponent from "@/hooks/useSelectHelperComponent";

interface Props {
  openId?: string;
}

export const QuestionButton = ({ openId }: Props) => {
  const openHelper = useSelectHelperComponent();

  return (
    <button
      onClick={openId ? () => openHelper("detail", openId) : () => {}}
      className="madebutton d-flex align-items-center paddingZero"
    >
      <QuestionMarkIcon color="#39415c" />
    </button>
  );
};
