import {
  OrderResult,
  OrdermanageSerachingValue,
} from "@/interface/orderManageInterface";
import axiosInstance from "@/utils/axiosInstance";

export const orderApis = {
  getOrderManage: async (searchValues: OrdermanageSerachingValue) => {
    const res = await axiosInstance.post(
      "/OrderManagement/SearchOrder",
      searchValues
    );

    return res.data.data as OrderResult;
  },
};
