import { CollectValue, CollectWay } from "@/interface/collectInterface";
import { VvicSearchingDuplicateSelect } from "./VvicSearchingDuplicateSelect";
import { VvicSearchingGroupName } from "./VvicSearchingGroupName";
import { VvicSearchingImgSelect } from "./VvicSearchingImgSelect";
import { VvicSearchingMemo } from "./VvicSearchingMemo";
import { VvicSearchingUrl } from "./VvicSearchingUrl";
import { SearchingTab } from "@/components/Collect/CollectSearchingTab/SearchingTab";
import { ResetIcon } from "@/components/svg/ResetIcon";
import { SearchingIcon } from "@/components/svg/SeachingIcon";
import { OrangeMiddleButton } from "@/components/Button/allttamButton/OrangeMiddleButton";
import useVvicId from "../hooks/useVvicId";
import { useEffect } from "react";
import useModal from "@/hooks/useModal";
import UploadAccountModal from "./UploadAccountModal";

interface Props {
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectValue: CollectValue;
  clickCollect: (page: number) => Promise<void>;
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const VvicSearchingBoard = ({
  setCollectValue,
  collectWay,
  setCollectWay,
  inputCollectValue,
  collectValue,
  clickCollect,
}: Props) => {
  const { isUploadId } = useVvicId();
  const { openModal } = useModal();

  useEffect(() => {
    if (isUploadId === false) {
      openModal(<UploadAccountModal />);
    }
  }, [isUploadId]);

  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="tabs-col-btn">
          <SearchingTab
            clickReset={() => {}}
            setCollectWay={setCollectWay}
            collectWay={collectWay}
          />
          <div className="tab-content">
            <div className="first-product-tab serachingBoard shadow">
              <div className="row">
                <VvicSearchingGroupName
                  setCollectValue={setCollectValue}
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
                <VvicSearchingUrl
                  collectWay={collectWay}
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
              </div>
              <div className="row mt-3">
                <VvicSearchingMemo
                  setCollectValue={setCollectValue}
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
                <VvicSearchingImgSelect
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
                <VvicSearchingDuplicateSelect
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
              </div>
              <hr className="hr1 mt-4" />
              <div className="row pt-3 pb-1 align-items-center justify-content-end">
                <div className="col-md-6 col-12">
                  <div className="search-btn d-flex justify-content-end gap-3 align-items-center">
                    <OrangeMiddleButton
                      onClick={() => openModal(<UploadAccountModal />)}
                    >
                      계정설정
                    </OrangeMiddleButton>
                    <button className="cancel-btn save-btn reset-btn">
                      <ResetIcon />
                      초기화
                    </button>
                    <button
                      onClick={() => clickCollect(1)}
                      className="cancel-btn save-btn"
                    >
                      <SearchingIcon />
                      {collectWay === "one" ? "수집" : "검색"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
