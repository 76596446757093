import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";

interface Props {
  offer: any;
  selectedCollectItem: string[];
  setSelectedCollectItem: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CollectItem1688 = ({
  offer,
  setSelectedCollectItem,
  selectedCollectItem,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCollectItem.includes(offer.id)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedCollectItem]);

  const selectItem = () => {
    if (isSelect) {
      setSelectedCollectItem((prev) =>
        prev.filter((item) => item !== offer.id)
      );
    } else {
      setSelectedCollectItem((prev) => [...prev, offer.id]);
    }
  };

  return (
    <div className="custom-col-product" onClick={selectItem}>
      <div
        className={`product-col  collectItem ${
          isSelect && "selectedCollectItem"
        }`}
      >
        <div className="d-flex justify-content-center">
          <img className="collectItemImg" src={offer.image.imgUrl} />
        </div>
        <h4>{offer.information.simpleSubject}</h4>
        <div className="product-col-inner">
          <h6>판매가</h6>
          <h5>{offer.tradePrice.offerPrice.valueString}</h5>
        </div>
        <a href={offer.information.detailUrl} target="_blank">
          <button className="cancel-btn short-btn">바로가기</button>
        </a>
      </div>
    </div>
  );
};
