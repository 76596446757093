import { GreyMiddleButton } from "@/components/Button/allttamButton/GreyMiddleButton";
import { CheckBoxInput } from "@/components/Input/CheckBoxInput";
import { ToggleInput } from "@/components/Input/ToggleInput";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import { Keyword } from "@/interface/uploadinterface";
import { useState } from "react";
import { FilteringSearchValue } from "./FilteringSearchValue";
import { TagSettingSelectTopButton } from "./TagSettingSelectTopButton";

interface Props {
  rawData: any;
  setSortData: React.Dispatch<React.SetStateAction<Keyword[]>>;
  sortData: Keyword[];
  selectedTag: string[];
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TagFiltering = ({
  rawData,
  sortData,
  selectedTag,
  setSelectedTag,
  setSortData,
}: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [sortValue, setSortValue] = useState({
    isMonthlySearch: false,
    isMonthAverClick: false,
    isMonthClickRate: false,
    isCompetition: false,
  });

  const [searchValue, setSearchValue] = useState<FilteringType>({
    start: 0,
    end: 0,
  });
  const [clickCountValue, setClickCountValue] = useState<FilteringType>({
    start: 0,
    end: 0,
  });
  const [clickRateValue, setClickRateValue] = useState<FilteringType>({
    start: 0,
    end: 0,
  });
  const [competitionValue, setCompetitionValue] = useState("낮음");

  const clickCheckSortValue = (name: SortType) => {
    setSortValue({
      ...sortValue,
      [name]: !sortValue[name],
    });
  };

  const checkMobile = () => {
    setIsMobile((prev) => !prev);
  };

  const applyFilter = () => {
    let newArray = [...rawData];
    if (!isMobile) {
      if (sortValue.isMonthlySearch) {
        newArray = newArray.filter((item) => {
          return (
            item.monthlyPcQcCnt > searchValue.start &&
            item.monthlyPcQcCnt < searchValue.end
          );
        });
      }
      if (sortValue.isMonthAverClick) {
        newArray = newArray.filter((item) => {
          return (
            item.monthlyAvePcClkCnt > clickCountValue.start &&
            item.monthlyAvePcClkCnt < clickCountValue.end
          );
        });
      }
      if (sortValue.isMonthClickRate) {
        newArray = newArray.filter((item) => {
          return (
            item.monthlyAvePcCtr > clickRateValue.start &&
            item.monthlyAvePcCtr < clickRateValue.end
          );
        });
      }
    }
    if (isMobile) {
      if (sortValue.isMonthlySearch) {
        newArray = newArray.filter((item) => {
          return (
            item.monthlyMobileQcCnt > searchValue.start &&
            item.monthlyMobileQcCnt < searchValue.end
          );
        });
      }
      if (sortValue.isMonthAverClick) {
        newArray = newArray.filter((item) => {
          return (
            item.monthlyAveMobileClkCnt > clickCountValue.start &&
            item.monthlyAveMobileClkCnt < clickCountValue.end
          );
        });
      }
      if (sortValue.isMonthClickRate) {
        newArray = newArray.filter((item) => {
          return (
            item.monthlyAveMobileCtr > clickRateValue.start &&
            item.monthlyAveMobileCtr < clickRateValue.end
          );
        });
      }
    }
    if (sortValue.isCompetition) {
      newArray = newArray.filter((item) => item.compIdx === competitionValue);
    }

    setSortData(newArray);
  };

  const selectCompetition = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCompetitionValue(e.target.value);
  };

  return (
    <>
      <div className="d-flex justify-content-between widthFull">
        <div className="width85 d-flex justify-content-between">
          <div className="d-flex gap-3 align-items-center">
            <p className="text16 weight500 margin0">검색광고 키워드</p>
            <QuestionMarkIcon />
            <div className="d-flex gap-2">
              <p
                className="weight500 text14 margin0 pointer"
                onClick={() => setIsMobile(false)}
              >
                PC 기준
              </p>
              <ToggleInput
                defaultBackGround="#00b8ba"
                checked={isMobile}
                onClick={checkMobile}
              />
              <p
                className="weight500 text14 margin0 pointer"
                onClick={() => setIsMobile(true)}
              >
                모바일 기준
              </p>
            </div>
          </div>
          <TagSettingSelectTopButton
            selectedTag={selectedTag}
            sortData={sortData}
            setSelectedTag={setSelectedTag}
          />
        </div>
      </div>
      <div className="gap-3 mt-3 d-flex justify-content-between">
        <div className="width85">
          <table className="exchangeTable" width="100%">
            <thead>
              <tr>
                <th>
                  <div className="d-flex align-items-center gap-3">
                    <CheckBoxInput
                      onClick={() => {
                        clickCheckSortValue("isMonthlySearch");
                      }}
                      checked={sortValue.isMonthlySearch}
                    />
                    <p className="margin0 weight600 grey text14">월간 검색수</p>
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center gap-3">
                    <CheckBoxInput
                      onClick={() => {
                        clickCheckSortValue("isMonthAverClick");
                      }}
                      checked={sortValue.isMonthAverClick}
                    />
                    <p className="margin0 weight600 grey text14">
                      월평균 클릭 수
                    </p>
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center gap-3">
                    <CheckBoxInput
                      onClick={() => {
                        clickCheckSortValue("isMonthClickRate");
                      }}
                      checked={sortValue.isMonthClickRate}
                    />
                    <p className="margin0 weight600 grey text14">
                      월평균 클릭률
                    </p>
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center gap-3">
                    <CheckBoxInput
                      onClick={() => {
                        clickCheckSortValue("isCompetition");
                      }}
                      checked={sortValue.isCompetition}
                    />
                    <p className="margin0 weight600 grey text14">경쟁강도</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <FilteringSearchValue
                  stateValue={searchValue}
                  setFunction={setSearchValue}
                />
                <FilteringSearchValue
                  stateValue={clickCountValue}
                  setFunction={setClickCountValue}
                />
                <FilteringSearchValue
                  stateValue={clickRateValue}
                  setFunction={setClickRateValue}
                />
                <td className="width15 px-3">
                  <div className="d-flex align-items-center mt-2">
                    <select
                      className="allttam-input"
                      onChange={selectCompetition}
                      value={competitionValue}
                    >
                      <option value="낮음">낮음</option>
                      <option value="중간">중간</option>
                      <option value="높음">높음</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="width15">
          <GreyMiddleButton onClick={applyFilter}>적용</GreyMiddleButton>
        </div>
      </div>
    </>
  );
};

type SortType =
  | "isMonthlySearch"
  | "isMonthAverClick"
  | "isMonthClickRate"
  | "isCompetition";

export interface FilteringType {
  start: number;
  end: number;
}
