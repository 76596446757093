import { useEffect, useState } from "react";
import { ohoo } from "../utils/functions/ohooStudioApi";
import { UploadSummary } from "../interface/uploadinterface";
import { ModalBg } from "./ModalLayout/ModalBg";
import { urlToBase64 } from "../utils/functions/urlToBase64";
import { ModalInnerTop } from "./ModalLayout/ModalInnerTop";
import { Spinner } from "./Spinner/Spinner";

interface Props {
  closeModal: () => void;
  originalImg: string;
  ohooEditorId: {
    id: string;
    link: string;
  };
  item: UploadSummary;
  applyImage: (img: string) => void;
  isPrev?: boolean;
}

export const OhooTranslateResult = ({
  isPrev,
  applyImage,
  item,
  closeModal,
  originalImg,
  ohooEditorId,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isPrev) {
      setIsLoading(true);
      ohoo
        .getTranslateResult(ohooEditorId.id, item!.site, item!.code)
        .then((res) => setResultImg(res.data))
        .then((res) => setIsLoading(false));
    }
  }, []);

  const [resultImg, setResultImg] = useState("");

  const clickGetResult = async () => {
    setIsLoading(true);
    const res = await ohoo.getTranslateResult(
      ohooEditorId.id,
      item.site,
      item.code
    );
    setResultImg(res.data);
    setIsLoading(false);
  };

  const clickSave = async () => {
    const base64 = await urlToBase64(resultImg);
    applyImage(base64 as string);
    closeModal();
  };

  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop width={1500}>
        <>
          <div>
            <div className="modal-header modal-sticky">
              <h5
                className="modal-title d-flex align-items-center"
                id="exampleModalLabel"
              >
                번역결과
              </h5>
              <div className="d-flex align-items-center justify-content-center gap-5 mt-4">
                <button onClick={clickSave} className="cancel-btn save-btn">
                  적용
                </button>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="widthHalf text-center">
                  <p>원본 이미지</p>
                  <img className="originalImg" src={originalImg} />
                </div>
                <div className="widthHalf text-center">
                  <p>번역결과</p>
                  {resultImg === "" && !isPrev && (
                    <button
                      onClick={clickGetResult}
                      className="madebutton save-btn transelateGetButton"
                    >
                      가져오기
                    </button>
                  )}
                  {resultImg !== "" && (
                    <img className="originalImg" src={resultImg} />
                  )}
                  {isLoading && (
                    <div className="d-flex margintoplarge justify-content-center">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
