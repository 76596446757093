export const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="calendar (1)">
        <path
          data-name="선 6"
          transform="translate(6.093 12.404)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0h17.824"
        ></path>
        <path
          data-name="선 7"
          transform="translate(19.442 16.31)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0h.009"
        ></path>
        <path
          data-name="선 8"
          transform="translate(15.005 16.31)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0h.009"
        ></path>
        <path
          data-name="선 9"
          transform="translate(10.558 16.31)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0h.009"
        ></path>
        <path
          data-name="선 10"
          transform="translate(19.442 20.196)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0h.009"
        ></path>
        <path
          data-name="선 11"
          transform="translate(15.005 20.196)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0h.009"
        ></path>
        <path
          data-name="선 12"
          transform="translate(10.558 20.196)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0h.009"
        ></path>
        <path
          data-name="선 13"
          transform="translate(19.044 5)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0v3.291"
        ></path>
        <path
          data-name="선 14"
          transform="translate(10.966 5)"
          stroke="#39415c"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
          d="M0 0v3.291"
        ></path>
        <path
          data-name="패스 10496"
          d="M13.238 1.579H4.771C1.834 1.579 0 3.215 0 6.222v9.05C0 18.326 1.834 20 4.771 20h8.458C16.175 20 18 18.355 18 15.348V6.222c.009-3.007-1.816-4.643-4.762-4.643z"
          transform="translate(6 5)"
          fillRule="evenodd"
          stroke="#39415c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          fill="none"
        ></path>
      </g>
      <path data-name="사각형 3773" fill="none" d="M0 0h30v30H0z"></path>
    </svg>
  );
};
