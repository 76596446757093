import { useEffect, useState } from "react";
import { productManage } from "@/utils/functions/postApi";
import { Line } from "react-chartjs-2";

import { useRecoilValue } from "recoil";
import { productManagePeriod } from "@/atom/atom";
import { TooltipItem } from "chart.js";

interface Props {
  code: string;
  market: string;
}

const PERIOD_INDEX = {
  aWeek: "7D",
  aMonth: "12W",
  threeMonth: "12W",
  sixMonth: "12W",
  aYear: "12M",
};

export const ViewCountChart = ({ code, market }: Props) => {
  const period = useRecoilValue(productManagePeriod);
  const [chartPeriod, setChartPeriod] = useState<string>(
    PERIOD_INDEX[period as PERIOD]
  );

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        label: "My First dataset",
        tension: 0.4,
        borderColor: "#00b8ba",
        pointStyle: "circle",
        backgroundColor: "#39415c",
        pointRadius: 8,
        pointHoverRadius: 15,
      },
    ],
  });

  const getViews = async () => {
    const viewsRes = await productManage.getViewCount(
      chartPeriod,
      code,
      market
    );
    setData({
      labels: viewsRes?.xAxis.categories!,
      datasets: [
        {
          data: viewsRes?.series[0].data!,
          label: "My First dataset",
          tension: 0.4,
          borderColor: "#00b8ba",
          backgroundColor: "#39415c",
          pointStyle: "circle",
          pointRadius: 8,
          pointHoverRadius: 15,
        },
      ],
    });
  };

  useEffect(() => {
    getViews();
  }, [chartPeriod]);

  const changePeriod = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setChartPeriod(e.target.value);
  };

  return (
    <div className="viewModalRightSide d-flex flex-column align-items-center">
      <h4 className="mb-5">
        {TITLE_INDEX[chartPeriod as "12W" | "7D" | "12M"]} 단위 유입수
      </h4>
      <div className="d-flex widthFull justify-content-end mb-3">
        <select
          value={chartPeriod}
          onChange={changePeriod}
          className="viewsSelectPeriod"
        >
          <option value="12M">최근 1년</option>
          <option value="12W">최근 12주</option>
          <option value="7D">최근 7일</option>
        </select>
      </div>
      <Line
        data={data}
        options={{
          scales: {
            y: {
              ticks: {
                callback: (tickValue: string | number): string => {
                  if (Number.isInteger(Number(tickValue))) {
                    return tickValue.toString();
                  }
                  return "";
                },
              },
            },
          },
          plugins: {
            tooltip: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              callbacks: {
                label: (tooltipItem: TooltipItem<"line">): string => {
                  const label = tooltipItem.label;
                  const value = tooltipItem.parsed?.y ?? tooltipItem.label;
                  return `${label}: ${value}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

const TITLE_INDEX = {
  "12W": "주",
  "12M": "월",
  "7D": "일",
};

type PERIOD = "aWeek" | "aMonth" | "threeMonth" | "sixMonth" | "aYear";
