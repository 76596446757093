import { GreyMiddleButton } from "@/components/Button/allttamButton/GreyMiddleButton";
import { WordSettingType } from "./OptionNameSetting";
import {
  OptionGroupType,
  ProhibitReplaceWord,
} from "@/interface/uploadinterface";
import { OptionValue } from "./OptionValueSetting";
import { upload } from "@/utils/functions/postApi";
import { sliceProhibitWord } from "@/utils/functions/sliceProhibitWord";
import { replaceWord as replaceFunction } from "@/utils/functions/replaceWord";
import { ALPHABET_INDEX } from "../../function/alphabetIndex";

interface Props {
  wordSettingValue: WordSettingType;
  optionGroups: OptionGroupType[];
  setOptionGroups: React.Dispatch<React.SetStateAction<OptionGroupType[]>>;
  optionWordValue: OptionValue;
  groupOptionIndex: number;
  replaceWord: {
    prevWord: string;
    nextWord: string;
  };
}

export const OptionWordApplyBtn = ({
  wordSettingValue,
  optionGroups,
  setOptionGroups,
  optionWordValue,
  groupOptionIndex,
  replaceWord,
}: Props) => {
  const optionWordSettingApply = async () => {
    const newGroups = [...optionGroups];
    const nowGroups = newGroups[groupOptionIndex];

    // 기본 설정 치환
    let newNameList = nowGroups.groups.map((item) => {
      const forignName = item.optionName.filter(
        (name) => name.language !== "KR"
      )[0];
      const prevName = item.optionName.filter(
        (name) => name.language === "KR"
      )[0];

      prevName.text = prevName.text.replaceAll(
        replaceWord.prevWord ? replaceWord.prevWord : " ",
        replaceWord.nextWord
      );

      return {
        ...item,
        optionName: [forignName, prevName],
      };
    });

    if (wordSettingValue.isProhibit) {
      const getRes = await upload.getProhibitReplaceWord("Remove");

      newNameList = newNameList.map((item) => {
        const forignName = item.optionName.filter(
          (name) => name.language !== "KR"
        )[0];
        const prevName = item.optionName.filter(
          (name) => name.language === "KR"
        )[0];

        prevName.text = sliceProhibitWord(
          prevName.text,
          getRes.map((item: ProhibitReplaceWord) => item.targetWord)
        );

        return {
          ...item,
          optionName: [forignName, prevName],
        };
      });
    }

    if (wordSettingValue.isReplace) {
      const getRes = await upload.getProhibitReplaceWord("Replace");
      const replaceWordArr = getRes.map((item: ProhibitReplaceWord) => {
        return {
          before: item.targetWord,
          after: item.convertWord,
        };
      });

      newNameList = newNameList.map((item) => {
        const forignName = item.optionName.filter(
          (name) => name.language !== "KR"
        )[0];
        const prevName = item.optionName.filter(
          (name) => name.language === "KR"
        )[0];

        prevName.text = replaceFunction(prevName.text, replaceWordArr);

        return {
          ...item,
          optionName: [forignName, prevName],
        };
      });
    }

    if (optionWordValue.front === "alp") {
      newNameList = newNameList.map((item, idx) => {
        const forignName = item.optionName.filter(
          (name) => name.language !== "KR"
        )[0];
        const prevName = item.optionName.filter(
          (name) => name.language === "KR"
        )[0];

        prevName.text = `${ALPHABET_INDEX[idx]} ${prevName.text}`;

        return {
          ...item,
          optionName: [forignName, prevName],
        };
      });
    }
    if (optionWordValue.front === "num") {
      newNameList = newNameList.map((item, idx) => {
        const forignName = item.optionName.filter(
          (name) => name.language !== "KR"
        )[0];
        const prevName = item.optionName.filter(
          (name) => name.language === "KR"
        )[0];

        prevName.text = `${idx + 1} ${prevName.text}`;

        return {
          ...item,
          optionName: [forignName, prevName],
        };
      });
    }
    if (optionWordValue.back === "alp") {
      newNameList = newNameList.map((item, idx) => {
        const forignName = item.optionName.filter(
          (name) => name.language !== "KR"
        )[0];
        const prevName = item.optionName.filter(
          (name) => name.language === "KR"
        )[0];

        prevName.text = `${prevName.text} ${ALPHABET_INDEX[idx]} `;

        return {
          ...item,
          optionName: [forignName, prevName],
        };
      });
    }
    if (optionWordValue.back === "num") {
      newNameList = newNameList.map((item, idx) => {
        const forignName = item.optionName.filter(
          (name) => name.language !== "KR"
        )[0];
        const prevName = item.optionName.filter(
          (name) => name.language === "KR"
        )[0];

        prevName.text = `${prevName.text} ${idx + 1} `;

        return {
          ...item,
          optionName: [forignName, prevName],
        };
      });
    }

    nowGroups.groups = newNameList;
    newGroups[groupOptionIndex] = nowGroups;

    setOptionGroups(newGroups);
  };
  return (
    <GreyMiddleButton onClick={optionWordSettingApply}>적용</GreyMiddleButton>
  );
};
