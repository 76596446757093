interface Props {
  orderPrice: number;
}

export const RevenueOrderPrice = ({ orderPrice }: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-3">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">판매가</p>
        <p className="oneLine margin0">{orderPrice} 원</p>
      </div>
    </div>
  );
};
