import { Spinner } from "react-bootstrap";
import { CrawledKeyword } from "../../TagSettingModal";

interface Props {
  isLoading: boolean;
  naverAdKeyword: string[];
  relKeywordList: CrawledKeyword;
  productNameValueList: {
    code: string;
    text: string;
  }[];
  nameSelect: string[];
  setProductNameValueList: React.Dispatch<
    React.SetStateAction<
      {
        code: string;
        text: string;
      }[]
    >
  >;
}

export const ProductNameRelKeywordList = ({
  isLoading,
  naverAdKeyword,
  relKeywordList,
  setProductNameValueList,
  nameSelect,
}: Props) => {
  const clickKeyword = (keyword: string) => {
    setProductNameValueList((prev) =>
      prev.map((item) => {
        if (nameSelect.includes(item.code)) {
          return {
            ...item,
            text: `${item.text} ${keyword}`,
          };
        } else {
          return item;
        }
      })
    );
  };
  return (
    <div className="mt-4 d-flex flex-col">
      <div className="d-flex gap-3 mt-4 mb-3">
        <div className="width15">
          <p className="productNameModalSubtitle margin0">검색광고 키워드</p>
        </div>
        <div className="width85 d-flex gap-3 keyword-container">
          {!isLoading && naverAdKeyword.length ? (
            naverAdKeyword.map((item, idx) => (
              <button
                key={idx}
                onClick={() => clickKeyword(item)}
                className="madebutton weight200 text14 oneLine button-grey-border grey"
              >
                {item}
              </button>
            ))
          ) : isLoading ? (
            <></>
          ) : (
            <p className="weight500 text12 grey text-center margin0 widthFull">
              검색 결과가 없습니다.
            </p>
          )}
        </div>
      </div>
      <div className="d-flex gap-3 align-items-center mt-4 mb-3">
        <div className="width15">
          <p className="productNameModalSubtitle margin0">연관 검색어</p>
        </div>
        <div className="d-flex gap-3 keyword-container width85">
          {isLoading && (
            <div className="widthFull d-flex justify-content-center relative hidden py-3 bottom20">
              <Spinner />
            </div>
          )}
          {!isLoading && relKeywordList.auto.length ? (
            relKeywordList.rel.map((item, idx) => (
              <button
                key={idx}
                onClick={() => clickKeyword(item)}
                className="madebutton weight200 text14 oneLine button-grey-border grey"
              >
                {item}
              </button>
            ))
          ) : isLoading ? (
            <></>
          ) : (
            <p className="weight500 text12 grey text-center margin0 widthFull">
              검색 결과가 없습니다.
            </p>
          )}
        </div>
      </div>
      <div className="d-flex gap-3 align-items-center mt-4">
        <div className="width15">
          <p className="productNameModalSubtitle margin0">자동완성 키워드</p>
        </div>
        <div className="d-flex gap-3 keyword-container width85">
          {!isLoading && relKeywordList.auto.length ? (
            relKeywordList.auto.map((item, idx) => (
              <button
                key={idx}
                onClick={() => clickKeyword(item)}
                className="madebutton weight200 text14 oneLine button-grey-border grey"
              >
                {item}
              </button>
            ))
          ) : isLoading ? (
            <></>
          ) : (
            <p className="weight500 text12 grey text-center margin0 widthFull">
              검색 결과가 없습니다.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
