import { GreenBigButton } from "@/components/Button/allttamButton/GreenBigButton";
import { ChangeEvent, useState } from "react";
import usePlanList from "../hooks/usePlanList";
import useCardInfo from "@/hooks/useCardInfo";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import useModal from "@/hooks/useModal";
import CheckPayModal from "../modal/CheckPayModal";
import { useNavigate } from "react-router-dom";
import { paymentCoin } from "@/apis/payApis";

const CoinSelect = () => {
  const { coin: coinList } = usePlanList();
  const [coin, setCoin] = useState("C1");
  const { data } = useCardInfo();
  const navigate = useNavigate();
  const openWindow = useInfoWindow();
  const { openModal } = useModal();

  const selectCoin = (e: ChangeEvent<HTMLSelectElement>) => {
    setCoin(e.target.value);
  };

  const selectedCoin = COINS.find((el) => el.name === coin);

  const clickCoinPayment = () => {
    if (!data.data) {
      alert("카드등록이 필요합니다.");
      navigate("/userinfo");
    } else {
      openModal(
        <CheckPayModal
          type="COIN"
          planName={coin}
          price={coinList?.find((el) => el.name === coin)?.price!}
          goodsName={`올땀 ${selectedCoin?.coin}코인`}
        />
      );
    }
  };

  return (
    <div className="col-12 mt-3 ">
      <div className="heading-one payment-sec ">
        <h3 className="h3 weight600">충전하기</h3>
      </div>
      <div className="widthHalf d-flex align-items-center moblie-pay">
        <div className="width30">
          <select
            onChange={selectCoin}
            className="allttam-input input-border-2"
          >
            {COINS.map((coin) => (
              <option value={coin.name} key={coin.id}>
                {coin.coin.toLocaleString()} 코인
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="d-flex gap-5 px-4 justify-content-between">
            <p className="weight600 margin0 text14">상품금액</p>
            <p className="weight600 margin0">
              {"\\"} {selectedCoin?.originalPrice.toLocaleString()}
            </p>
          </div>
          <div className="d-flex gap-5 px-4 justify-content-between">
            <p className="weight600 margin0 text14">
              결제 금액 <span className="weight500 text8">(VAT 별도)</span>
            </p>
            <p className="weight600 text20 textGreen margin0">
              {"\\"} {selectedCoin?.purchasePrice.toLocaleString()}
            </p>
          </div>
        </div>
        <GreenBigButton onClick={clickCoinPayment}>코인충전</GreenBigButton>
      </div>
    </div>
  );
};

export default CoinSelect;

const COINS = [
  {
    id: 0,
    name: "C1",
    coin: 10000,
    originalPrice: 200000,
    purchasePrice: 160000,
  },
  {
    id: 1,
    name: "C2",
    coin: 20000,
    originalPrice: 400000,
    purchasePrice: 280000,
  },
];
