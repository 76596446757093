import { Desc } from "@/interface/uploadinterface";
import minusYellow from "@/assets/images/minusYellow.svg";
import plusGreen from "@/assets/images/plusGreen.svg";

interface Props {
  descList: Desc[];
  setDescList: React.Dispatch<React.SetStateAction<Desc[]>>;
}

export const EditDefaultSetDesc = ({ descList, setDescList }: Props) => {
  const editDesc = (idx: number, name: string, value: string) => {
    const newArr = [...descList];
    newArr[idx] = {
      ...newArr[idx],
      [name]: value,
    };
    setDescList(newArr);
  };

  const deleteDesc = (idx: number) => {
    const newArr = [...descList];
    newArr.splice(idx, 1);
    setDescList(newArr);
  };

  const addDesc = () => {
    const a = {
      index: descList[descList.length - 1]
        ? descList[descList.length - 1].index + 1
        : 0,
      language: "KR",
      title: "",
      value: "",
    };
    setDescList([...descList, a]);
  };
  return (
    <>
      <div className="d-flex justify descModalHeader">
        <p className="margin0 textCenter width47">속성명</p>
        <p className="margin0 textCenter width47">속성값</p>
        <p className="margin0 textCenter">+ / -</p>
      </div>
      <div className="descListBox">
        {descList?.map((item, idx) => (
          <div key={item.index} className="row mt-2 align-items-center">
            <div className="product-label col-md-6 col-12">
              <input
                onChange={(e) => editDesc(idx, e.target.name, e.target.value)}
                type="text"
                className="form-control"
                placeholder="속성 입력"
                name="title"
                value={item.title}
              />
            </div>
            <div className="product-label col-md-5 col-12 ps-0">
              <input
                onChange={(e) => editDesc(idx, e.target.name, e.target.value)}
                type="text"
                className="form-control"
                placeholder="속성 입력"
                name="value"
                value={item.value}
              />
            </div>
            <button
              onClick={() => deleteDesc(idx)}
              className=" madebutton col-12 col-md-1 ps-0 dustbin mt-0"
            >
              <img src={minusYellow} />
            </button>
          </div>
        ))}
      </div>
      <div className="row mt-2 justify-content-end mr-2">
        <button onClick={addDesc} className="madebutton">
          <img src={plusGreen} />
        </button>
      </div>
    </>
  );
};
