import { ProductManageSearchingValue } from "@/interface/productmanageinterface";

interface Props {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  value: ProductManageSearchingValue;
}

export const InputBoxMemo = ({ onChange, value }: Props) => {
  return (
    <div className="product-label product-label-1 col-md-3 col-12 width-devide-1 mobile-dp-none">
      <label htmlFor="memo">메모</label>
      <input
        value={value.memo}
        onChange={onChange}
        type="text"
        className="form-control"
        id="memo"
        placeholder="메모를 입력해주세요."
        name="memo"
      />
    </div>
  );
};
