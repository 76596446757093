export const SaleStartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        className="svg-stroke4"
        data-name="다각형 3"
        d="M8.3 2.714a2 2 0 0 1 3.392 0l6.396 10.226a2 2 0 0 1-1.7 3.06H3.608a2 2 0 0 1-1.7-3.06z"
        transform="rotate(90 9.5 14.5)"
        fill="#00b8ba"
      />
      <path
        data-name="사각형 3861"
        fill="none"
        className="svg-stroke1"
        d="M0 0h30v30H0z"
      />
    </svg>
  );
};
