import useToggle from "@/hooks/useToggle";
import { WhiteMiddleButton } from "../allttamButton/WhiteMiddleButton";
import { ProhibitionWordModal } from "@/components/Upload/modal/ProhibitionWordModal";

export const ProhibitWordButton = () => {
  const { state: isProhibitModal, handleToggle: handleProhibitModal } =
    useToggle();
  return (
    <div className="width30 d-flex flex-col gap-2 align-items-start">
      <p className="margin0 weight500 text14 grey">금지단어</p>
      <WhiteMiddleButton onClick={handleProhibitModal}>설정</WhiteMiddleButton>
      {isProhibitModal && (
        <ProhibitionWordModal closeModal={handleProhibitModal} />
      )}
    </div>
  );
};
