export const ResultTableHead = ({
  selectAll,
  isChecked,
}: {
  selectAll: () => void;
  isChecked: boolean;
}) => {
  return (
    <thead className="head-one tableHead ">
      <tr className="stickyHeader">
        <th>
          <div className="form-group">
            <input
              value=""
              onChange={selectAll}
              type="checkbox"
              id="htmla"
              checked={isChecked}
            />
            <label htmlFor="htmla"></label>
          </div>
        </th>
        <th>수집마켓</th>
        <th>상품정보</th>
        <th>상세정보</th>
        <th>업로드마켓</th>
        <th>부가정보</th>
      </tr>
    </thead>
  );
};
