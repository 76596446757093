import { orderManage } from "@/utils/functions/postApi";
import { ResultItem } from "@/interface/orderManageInterface";

interface Props {
  selectedOrder: ResultItem[];
}

export const OrderManageDownloadExcelButton = ({ selectedOrder }: Props) => {
  const clickDownloadExcel = async () => {
    const selectedIdx = selectedOrder.map((item) => item.idx.toString());
    const downloadRes = await orderManage.downloadExcel(selectedIdx);
    const url = URL.createObjectURL(downloadRes);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}`);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div>
      <button
        onClick={clickDownloadExcel}
        className="cancel-btn save-btn reset-btn name-set-btn save-excel"
      >
        엑셀 저장
      </button>
    </div>
  );
};
