import { ModalBody } from "@/components/ModalLayout/ModalBody";
import { ModalHead } from "@/components/ModalLayout/ModalHead";
import ModalContent from "@/components/shared/modal/ModalContent";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { highlightText } from "@/utils/highlightText";
import useCardInfo from "@/hooks/useCardInfo";
import { useQueryClient } from "react-query";
import styles from "@/styles/UserInfo.module.css";
import { businessesValidate, createBillKey } from "@/apis/payApis";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import CheckPayModal from "./CheckPayModal";
import useModal from "@/hooks/useModal";
import { unset } from "lodash";

interface Props {
  closeModal: () => void;
  planName: string;
  slot?: string;
  price: number;
  goodsName: string;
  type: "PLAN" | "COIN";
}

const CardInfoText = [
  "저장을 하면 올땀 !개인정보처리지침 · 서비스이용약관!에 동의하는 것으로 간주 되며, 구독 결제가 진행됩니다.",
];
const DEFAULT_CARDINFO = {
  cardNum: "",
  cardExpire: "",
  cardPwd: "",
  idNum: "",
};
const Alertmessages = {
  cardNum: "카드번호를 입력해 주세요.\n카드번호 16자리가 입력되지 않았습니다.",
  cardExpire: "만료일을 입력해 주세요.\n만료일이 입력되지 않았습니다.",
  cardPwd:
    "카드 비밀번호를 입력해 주세요.\n카드 비밀번호가 입력되지 않았습니다.",
  idNum:
    "생년월일을 입력해 주세요.\n생년월일 및 사업자 번호가 입력되지 않았습니다.",
};
interface DefaultCardInfoProps {
  cardNum: string;
  cardExpire: string;
  cardPwd: string;
  idNum: string;
}

const CardInfoModalMobile: FC<Props> = ({
  closeModal,
  planName,
  price,
  slot,
  goodsName,
  type,
}) => {
  const [iscorporation, setIscorporation] = useState<boolean>(false);
  const replaceNotInt = /[^0-9]/gi;
  const { data, isLoading } = useCardInfo();
  const queryClient = useQueryClient();
  const [cardInfo, setCardInfo] =
    useState<DefaultCardInfoProps>(DEFAULT_CARDINFO);
  const openWindow = useInfoWindow();
  const { openModal } = useModal();

  const inputCardInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value.replace(replaceNotInt, "");

    setCardInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeExpire = (ExpireNum: string) => {
    let changeNum = ExpireNum[2] + ExpireNum[3] + ExpireNum[0] + ExpireNum[1];
    return changeNum;
  };

  const submitForm = async () => {
    if (cardInfo.cardNum.length !== 19)
      return openWindow(Alertmessages.cardNum);
    if (!cardInfo.cardExpire) {
      return openWindow(Alertmessages.cardExpire);
    }
    if (!cardInfo.cardPwd) {
      return openWindow(Alertmessages.cardPwd);
    }
    if (!cardInfo.idNum.length) return openWindow(Alertmessages.idNum);

    if (cardInfo.idNum.length === 10) {
      const validateText = await businessesValidate(cardInfo.idNum);
      if (
        validateText[0]?.tax_type ===
        "사업자 번호를 확인해 주세요.\n등록되지 않은 사업자등록번호입니다."
      )
        return openWindow(
          "사업자 번호를 확인해 주세요.\n등록되지 않은 사업자등록번호입니다."
        );
      let SubmitData = {
        ...cardInfo,
        cardExpire: changeExpire(cardInfo.cardExpire.replace(/\D/g, "")),
      };

      const res = await createBillKey(SubmitData);

      if (res.status === "Success") {
        queryClient.invalidateQueries("billingInfo");
        openWindow("결제하기를 진행해 주세요.\n카드 등록 완료");
        setCardInfo(DEFAULT_CARDINFO);
        closeModal();
        openModal(
          <CheckPayModal
            type={type}
            planName={planName}
            price={price}
            slot={slot}
            goodsName={goodsName}
          />
        );
      } else {
        openWindow(res.message);
      }
    }

    //생년월일
    if (cardInfo.idNum.length === 6) {
      //만료일 재배열
      let SubmitData = {
        ...cardInfo,
        cardExpire: changeExpire(cardInfo.cardExpire.replace(/\D/g, "")),
      };

      const res = await createBillKey(SubmitData);
      if (res.status === "Success") {
        queryClient.invalidateQueries("billingInfo");
        openWindow("결제하기를 진행해 주세요.\n카드 등록 완료");
        setCardInfo(DEFAULT_CARDINFO);
        closeModal();
        openModal(
          <CheckPayModal
            type={type}
            planName={planName}
            price={price}
            slot={slot}
            goodsName={goodsName}
          />
        );
      } else {
        openWindow(`카드 정보가 등록되지 않았습니다.\n${res.message}`);
      }
    }
  };

  if (isLoading) return null;

  return (
    <ModalContent unset={false}>
      {/* <h1 className="text14 weight600" style={{ margin: "34px 0 0 30px" }}>
        구독 정보
      </h1> */}
      <ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="d-flex"
            style={{
              width: "350px",
              marginTop: "38.5px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex "
              style={{
                flexDirection: "column",
                marginRight: 0,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                className=""
                style={{
                  padding: "9px 10px 9px 10px",
                  height: "85.8px",
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "100%" }}
                >
                  <p
                    className="weight500 text16"
                    style={{ color: "rgba(89, 99, 128, 1)" }}
                  >
                    카드번호
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="form-group mb-3 d-flex align-items-center">
                      <input
                        type="checkbox"
                        id="corporation"
                        checked={iscorporation}
                        onChange={() => setIscorporation(!iscorporation)}
                      />
                      <label
                        htmlFor="corporation"
                        className="text12 weight500"
                        style={{
                          color: "rgba(89, 99, 128, 1)",
                          marginLeft: "10px",
                        }}
                      ></label>
                    </div>
                    <p
                      className="text12 weight500"
                      style={{
                        color: "rgba(89, 99, 128, 1)",
                        marginLeft: "10px",
                      }}
                    >
                      법인카드
                    </p>
                  </div>
                </div>

                <input
                  style={{ width: "60%", height: "40px" }}
                  type="text"
                  className="form-control"
                  placeholder="0000-0000-0000-0000"
                  pattern="\d{4}-\d{4}-\d{4}-\d{4}"
                  value={cardInfo.cardNum}
                  onChange={(e) =>
                    setCardInfo((prev) => ({
                      ...prev,
                      cardNum: e.target.value
                        .replace(/\D/g, "")
                        .replace(/(\d{4})(?=\d)/g, "$1-")
                        .slice(0, 19),
                    }))
                  }
                />
              </div>

              <div
                className=""
                style={{
                  width: "100%",
                  height: "85.8px",
                  padding: "9px 10px 9px 10px",
                }}
              >
                <p
                  className="weight500 text16"
                  style={{ color: "rgba(89, 99, 128, 1)" }}
                >
                  유효기간
                </p>
                <input
                  style={{ width: "60%", height: "40px" }}
                  type="text"
                  className="form-control"
                  placeholder="MM/YY"
                  name="cardExpire"
                  maxLength={5}
                  value={cardInfo.cardExpire}
                  onChange={(e) =>
                    setCardInfo((prev) => ({
                      ...prev,
                      cardExpire: e.target.value
                        .replace(/\D/g, "")
                        .replace(/(\d{2})(?=\d)/g, "$1/"),
                    }))
                  }
                />
              </div>
            </div>
            <div
              className="d-flex"
              style={{
                marginRight: 0,
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div
                className=""
                style={{
                  width: "100%",
                  height: "85.8px",
                  padding: "9px 10px 9px 10px",
                }}
              >
                <p
                  className="weight500 text16"
                  style={{ color: "rgba(89, 99, 128, 1)" }}
                >
                  비밀번호
                </p>
                <input
                  type="password"
                  className="form-control"
                  style={{ width: "60%", height: "40px" }}
                  placeholder="비밀번호 앞 2자리"
                  name="cardPwd"
                  maxLength={2}
                  value={cardInfo.cardPwd}
                  onChange={inputCardInfo}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "85.8px",
                  justifySelf: "end",
                  padding: "9px 10px 9px 10px",
                }}
              >
                <p
                  className="weight500 text16"
                  style={{ color: "rgba(89, 99, 128, 1)" }}
                >
                  {iscorporation ? "사업자번호" : "생년월일"}
                </p>
                <input
                  type="text"
                  className="form-control"
                  style={{ width: "60%", height: "40px" }}
                  placeholder={
                    iscorporation ? "사업자번호 10자리" : "생년월일 6자리"
                  }
                  name="idNum"
                  maxLength={iscorporation ? 10 : 6}
                  value={cardInfo.idNum}
                  onChange={inputCardInfo}
                />
              </div>
            </div>
          </div>

          <div
            className="text12 weight500"
            style={{ width: "100%", marginTop: "53px" }}
          >
            <span>{highlightText(CardInfoText[0])}</span>
            {/* <p>{highlightText(CardInfoText[1])}</p> */}
          </div>
          <div
            style={{
              marginTop: "21px",
              display: "flex",
              justifyContent: "center",
              gap: "3rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "30%", height: "40px" }}>
              <button className="cancel-btn save-btn" onClick={submitForm}>
                구독
              </button>
            </div>
            <div style={{ width: "30%", height: "40px" }}>
              <button className="cancel-btn" onClick={() => closeModal()}>
                취소
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </ModalContent>
  );
};

export default CardInfoModalMobile;
