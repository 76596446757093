import unlimit from "@/assets/images/payment/unlimit.png";
import silver from "@/assets/images/payment/silver.png";
import gold from "@/assets/images/payment/gold.png";
import { PlanItem } from "./PlanItem";
import usePlanList from "../hooks/usePlanList";
import CoinSelect from "./CoinSelect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useRecoilValue } from "recoil";
import { isDeskTop } from "@/atom/atom";

const PaymentCoin = () => {
  const { plan, slot } = usePlanList();
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const isDesktop = useRecoilValue(isDeskTop);

  return (
    <>
      <div>
        <p className="h3 weight600 mt-5">10코인 상한제</p>
        <div className=" px-4 mt-3">
          <p className="text14 weight500 widthhalf" style={{ minWidth: "" }}>
            상품 건당 텍스트 수집 시, 과다하게 코인 차감되지 않도록 10코인
            상한제를 적용하고 있습니다.
          </p>
          <div style={{ position: "relative" }}>
            <img
              src="https://center.allttam.com/api/media/banners/Line91716530056923.png"
              alt="화살표"
              style={{
                position: "absolute",
                top: 43,
                left: 172,
                width: "39px",
              }}
            />
            <img
              className="mt-3"
              src="https://image.allttam.com/api/image?id=71fe38f7-7871-4af8-a3de-51977fd3e983"
            />
            <img
              src="https://center.allttam.com/api/media/banners/Line81716529985880.png"
              alt="화살표"
              style={{
                position: "absolute",
                top: 43,
                left: 233,
                width: "39px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="heading-one payment-sec ">
          <h3 className="h3 weight600">구독하기</h3>
        </div>
      </div>
      {isDesktop ? (
        <div className="d-flex gap-5 mt-4 moblie-payment-info">
          {plan?.map((item, idx) => (
            <PlanItem
              planName={item.name}
              key={idx}
              idx={idx}
              productPrice={item.price}
              discountPercent={PLAN_PAYMENT[idx].discountPercent}
              originalPrice={PLAN_PAYMENT[idx].originalPrice}
              payPrice={item.price}
              priceWithVat={item.price * 1.1}
              img={PLAN_PAYMENT[idx].img}
              bonusCoin={PLAN_PAYMENT[idx].bonusCoin}
              goodsName={PLAN_PAYMENT[idx].goodsName}
            />
          ))}
        </div>
      ) : (
        <Slider {...settings} className="mt-4">
          {plan?.map((item, idx) => (
            <PlanItem
              planName={item.name}
              key={idx}
              idx={idx}
              productPrice={item.price}
              discountPercent={PLAN_PAYMENT[idx].discountPercent}
              originalPrice={PLAN_PAYMENT[idx].originalPrice}
              payPrice={item.price}
              priceWithVat={item.price * 1.1}
              img={PLAN_PAYMENT[idx].img}
              bonusCoin={PLAN_PAYMENT[idx].bonusCoin}
              goodsName={PLAN_PAYMENT[idx].goodsName}
            />
          ))}
        </Slider>
      )}

      <CoinSelect />
    </>
  );
};

export const PLAN_PAYMENT = [
  {
    id: 0,
    bonusCoin: 3000,
    originalPrice: 250000,
    discountPercent: 0,
    goodsName: "무제한 월요금제",
    img: unlimit,
    description: (
      <p className="text14 weight600 navy">
        상품을 발굴하는 <span className="textGreen">올땀 부스트</span> 기능{" "}
        <span className="textYellow">지원</span>
        <br />
        <span className="textGreen">코인 차감없이</span> 올땀 기능 ALL 무제한{" "}
        <span className="textYellow">가능</span>
      </p>
    ),
  },
  {
    id: 1,
    bonusCoin: 5000,
    goodsName: "5000코인 월요금제",
    originalPrice: 100000,
    discountPercent: 20,
    img: silver,
    description: (
      <p className="text14 weight600 navy">
        <span className="textGreen">초보 투잡/부업</span>이신 셀러님께{" "}
        <span className="textYellow">추천</span>
        <br />
        상품 수집 건수 최소 <span className="textGreen">1천~ 5천개</span>{" "}
        <span className="textYellow">가능</span>
      </p>
    ),
  },
  {
    id: 2,
    bonusCoin: 10000,
    discountPercent: 25,
    originalPrice: 200000,
    goodsName: "10000코인 월요금제",
    img: gold,
    description: (
      <p className="text14 weight600 navy">
        <span className="textGreen">알뜰한 투잡/부업</span>이신 셀러님께{" "}
        <span className="textYellow">추천</span>
        <br /> 상품 수집 건수 최소 <span className="textGreen">
          2천~1만개
        </span>{" "}
        <span className="textYellow">가능</span>
      </p>
    ),
  },
];

export default PaymentCoin;
