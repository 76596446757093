import { WhiteGreenBigButton } from "@/components/Button/allttamButton/WhiteGreenBigButton";
import usePlanList from "../hooks/usePlanList";
import { ChangeEvent, lazy, useState } from "react";
import useModal from "@/hooks/useModal";
import CheckPayModal from "../modal/CheckPayModal";
import useCardInfo from "@/hooks/useCardInfo";
import { useLocation, useNavigate } from "react-router-dom";
import { highlightText } from "@/utils/highlightText";
// const CardInfoModal = lazy(() => import("../modal/CardInfoModal"));
import CardInfoModal from "../modal/CardInfoModal";
import { useRecoilValue } from "recoil";
import { isDeskTop } from "@/atom/atom";
import CardInfoModalMobile from "../modal/CardInfoModalMobile";

interface Props {
  planName: string;
  productPrice: number;
  discountPercent: number;
  payPrice: number;
  priceWithVat: number;
  img: string;
  idx: number;
  bonusCoin: number;
  originalPrice: number;
  goodsName: string;
}

type SlotCount = "5" | "10" | "15" | "20" | "25";
const SLOTLIST = ["5", "10", "15", "20", "25"];
const planText = [
  [
    "상품을 발굴하는 @올땀 부스트@ 기능 !지원!",
    "@코인 차감없이@ 올땀 기능 ALL 무제한 !가능!",
  ],
  [
    "@초보 투잡/부업@이신 셀러님께 !추천!",
    "상품 수집 건수 최소 @1천~ 5천개@ !가능!",
  ],
  [
    "@알뜰한 투잡/부업@이신 셀러님께 !추천!",
    "상품 수집 건수 최소 @2천~1만개@ !가능!",
  ],
];

export const PlanItem = ({
  planName,
  img,
  idx,
  originalPrice,
  payPrice,
  discountPercent,
  bonusCoin,
  goodsName,
}: Props) => {
  const { slot } = usePlanList();
  const { data } = useCardInfo();
  const { openModal, closeModal } = useModal();

  const [slotCount, setSlotCount] = useState<SlotCount>("5");

  const slotPrice = (parseInt(slotCount) / 5 - 1) * 100000;

  const discountedPrice = originalPrice * (discountPercent * 0.01);
  const isDesktop = useRecoilValue(isDeskTop);

  const selectSlot = (e: ChangeEvent<HTMLSelectElement>) => {
    setSlotCount(e.target.value as SlotCount);
  };

  const location = useLocation();

  const clickSubscribe = () => {
    if (!data.data) {
      openModal(
        isDesktop ? (
          <CardInfoModal
            closeModal={closeModal}
            planName={planName}
            price={payPrice}
            slot={slotCount}
            goodsName={goodsName}
            type={"PLAN"}
          />
        ) : (
          <CardInfoModalMobile
            closeModal={closeModal}
            planName={planName}
            price={payPrice}
            slot={slotCount}
            goodsName={goodsName}
            type={"PLAN"}
          />
        )
      );
    } else {
      openModal(
        <CheckPayModal
          type="PLAN"
          planName={planName}
          price={payPrice}
          slot={slotCount}
          goodsName={goodsName}
        />
      );
    }
  };

  return (
    <div className="d-flex flex-col align-items-center">
      <div className="ml-2 text14 weight600 plan-text">
        <span>{highlightText(planText[idx][0])}</span>
        <p>{highlightText(planText[idx][1])}</p>
      </div>
      <div>
        <img className="relative z10" width={282} height={100} src={img} />
        <div className="coinBox ml-7">
          <div className="d-flex justify-content-between mt-1">
            <p className="text14 weight600 navy margin0">+ 보너스</p>
            <p className="text16 weight600 navy margin0">
              {bonusCoin.toLocaleString()} 코인
            </p>
          </div>
          <div className="d-flex justify-content-between mt-1">
            <p className="text14 weight600 navy margin0">상품금액</p>
            <p className="text16 weight600 navy margin0">
              {"\\"} {originalPrice.toLocaleString()}
            </p>
          </div>
          <div className="d-flex justify-content-between mt-1">
            <div className="d-flex align-items-end gap-3">
              <p className="text14 weight600 navy margin0">할인금액</p>
              {discountPercent !== 0 && (
                <p className="text14 weight800 discountBox margin0">
                  {discountPercent}% 할인
                </p>
              )}
            </div>
            <p className="text14 weight800 textOrange margin0">
              {"\\"}
              {discountedPrice.toLocaleString()}
              {}
            </p>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex align-items-end gap-3">
              <p className="text14 weight600 navy margin0">마켓슬롯</p>
              <select
                onChange={selectSlot}
                defaultValue={"5"}
                className="slot-select"
              >
                {SLOTLIST.map((slot) => (
                  <option key={slot} value={slot}>
                    {slot} 개
                  </option>
                ))}
              </select>
            </div>
            <p className="text14 weight800 textOrange margin0">
              {"\\"}
              {slotPrice.toLocaleString()}
              {}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-end gap-2">
              <p className="text14 weight600 navy margin0">결제 금액</p>
              <p className="margin0 vat">(vat 별도)</p>
            </div>
            <p className="text20 weight800 textGreen margin0">
              {"\\"}{" "}
              {(originalPrice - discountedPrice + slotPrice).toLocaleString()}
            </p>
          </div>
          {location.pathname !== "/price" && (
            <div className="d-flex justify-content-center mt-4">
              <WhiteGreenBigButton onClick={clickSubscribe}>
                구독하기
              </WhiteGreenBigButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
