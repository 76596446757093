import { ResultItem } from "@/interface/orderManageInterface";
import useToggle from "@/hooks/useToggle";
import { ExpectationRevenueModal } from "@/components/Ordermanage/modal/ExpectationRevenueModal";

interface Props {
  isSelect: boolean;
  item: ResultItem;
}

export const ItemExpectationRevenueButton = ({ isSelect, item }: Props) => {
  const { state: isRevenueModal, handleToggle: handleRevenuModal } =
    useToggle();
  return (
    <td className="width7">
      <div className={`collect-inner-1 ${isSelect && "selectedItem"}`}>
        <div>
          <p className="margin0">{item.payment?.toLocaleString("KR")}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleRevenuModal();
            }}
            className="tableItemInnerButton madebutton mt-2"
          >
            예상수익
          </button>
        </div>
        {isRevenueModal && (
          <ExpectationRevenueModal
            closeModal={handleRevenuModal}
            idx={item.idx}
          />
        )}
      </div>
    </td>
  );
};
