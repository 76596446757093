export const highlightText = (text: string) => {
  const parts = text.split(/(\@[^\@]+\@|![^!]+!)/g); // Split by /.../ or !...!
  return parts.map((part, index) => {
    if (part.startsWith("@") && part.endsWith("@")) {
      return (
        <span key={index} style={{ color: "rgba(0, 184, 186, 1)" }}>
          {part.slice(1, -1)}
        </span>
      );
    } else if (part.startsWith("!") && part.endsWith("!")) {
      return (
        <span key={index} style={{ color: "rgba(255, 181, 0, 1)" }}>
          {part.slice(1, -1)}
        </span>
      );
    }
    return part;
  });
};
