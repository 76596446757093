import axios from "axios";
import Cookies from "js-cookie";

export const fileRequest = async (url: string, data: any, type?: string) => {
  let formData = new FormData();
  if (type) {
    formData.append("type", type!);
  }
  if (Array.isArray(data)) {
    data.forEach((element: any) => {
      formData.append("files", element);
    });
  } else {
    formData.append("files", data);
  }

  try {
    const fileRes = await axios.post(
      `https://api.allttam.com/api${url}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + Cookies.get("accessToken"),
        },
      }
    );
    return fileRes.data;
  } catch (err) {}
};
