import { SearchingBoardPeriodComponent } from "@/components/Productmanage/ProductManageSearchingBoard/component/SearcingBoardPeriodComponent";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import { OrdermanageSerachingValue } from "@/interface/orderManageInterface";
import { memo, useState } from "react";
import { SearchingBoardSearchingButton } from "../component/item/SearchingBoradSeachingButton";
import { OrderLoginResult } from "./OrderLoginResult";
import { OrderSearchForm } from "./OrderSearchForm";

interface Props {
  onSubmit: (collectValue: Partial<OrdermanageSerachingValue>) => void;
}

const OrderManageSearchingBoard = ({ onSubmit }: Props) => {
  const openWindow = useInfoWindow();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<
    Omit<OrdermanageSerachingValue, "page" | "startDate" | "endDate" | "row">
  >({
    market: "",
    site: "",
    type: "PRODUCTNAME",
    value: "",
    status: "",
  });
  const [date, setDate] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  const clickButtonSubmit = () => {
    if (isLoading)
      return openWindow("마켓 로그인 진행중입니다.\n잠시만 기다려 주세요.");
    onSubmit({
      ...searchValue,
      ...date,
    });
  };

  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="first-product-tab">
          <div className="row">
            <OrderSearchForm
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <OrderLoginResult
              market={searchValue.market}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
          <hr className="hr1 mt-5" />
          <div className="col-12">
            <div className="payment-detail-1 gap-0 justify-content-between">
              <SearchingBoardPeriodComponent setDate={setDate} date={date} />
              <SearchingBoardSearchingButton
                clickButtonSubmit={clickButtonSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(OrderManageSearchingBoard);
