import { ModalBg } from "@/components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@/components/ModalLayout/ModalInnerTop";
import useFetch from "@/hooks/useFetch";

interface Props {
  idx: number;
  closeModal: () => void;
}

export const OrderLogModalMobile = ({ idx, closeModal }: Props) => {
  const [orderLog] = useFetch(
    `orderLog${idx}`,
    `/OrderManagement/GetOrderLogs?idx=${idx}`
  );

  return (
    <ModalBg onClick={closeModal}>
      <ModalInnerTop>
        <>
          <div className="modal-header">
            <h5 className="modal-title d-flex align-items-center">주문로그</h5>
            <button
              onClick={closeModal}
              type="button"
              className="btn-close"
            ></button>
          </div>
          <hr className="margin0" />
          <div className="modal-body">
            <div className="row mt-4">
              <div className="widthFull resultTableScroll">
                <div className="tableContainer">
                  <table>
                    <tr>
                      <th>날짜</th>
                      <td>
                        <div>{orderLog?.[0].regDate}</div>
                      </td>
                    </tr>
                    <tr>
                      <th>마켓</th>
                      <td>
                        <div>{orderLog?.[0].market}</div>
                      </td>
                    </tr>
                    <tr>
                      <th>아이디</th>
                      <td>
                        <div>{orderLog?.[0].marketAccount}</div>
                      </td>
                    </tr>
                    <tr>
                      <th>종류</th>
                      <td>
                        <div>{orderLog?.[0].type}</div>
                      </td>
                    </tr>
                    <tr>
                      <th>결과</th>
                      <td>
                        <div>{orderLog?.[0].result}</div>
                      </td>
                    </tr>
                    <tr>
                      <th>내용</th>
                      <td>
                        <div>{orderLog?.[0].contents}</div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                  <button onClick={closeModal} className="cancel-btn save-btn">
                    닫기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
