import { useEffect, useState } from "react";
import { ProductManagePagination } from "../components/Productmanage/ProductManagePagination/ProductManagePagination";
import { usePost } from "@/hooks/usePost";
import {
  MarketAccount,
  ProdcutManageResultItem,
  ProductManageSearchingValue,
} from "@/interface/productmanageinterface";
import "@/styles/ProductManage.css";
import NavBar from "@/components/NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import chromeAPI from "@/utils/functions/chromeAPI.js";
import { ProductManageSearchingBoard } from "../components/Productmanage/ProductManageSearchingBoard/ProductManageSearchingBoard";
import { ProductManageSubtitle } from "../components/Productmanage/ProductManageSubtitle/ProductManageSubtitle";
import { ProductManageResultBoard } from "../components/Productmanage/ProductManageResultBoard/ProductManageResultBoard";
import { NavProductManage } from "@/components/NavBar/component/NavProductManage";
import { useRecoilValue } from "recoil";
import { isDeskTop } from "@/atom/atom";

const ProductManage = () => {
  const navigate = useNavigate();
  const checkExtension = async () => {
    const res = await chromeAPI.exCheck();
    return res.data;
  };
  const isDesktop = useRecoilValue(isDeskTop);
  useEffect(() => {
    isDesktop &&
      checkExtension()
        .then((res) => {})
        .catch(() => {
          navigate("/home");
        });
  }, []);

  const [searchingValue, setSearchingValue] = useState({
    pageLimit: 30,
    code: "",
    productName: "",
    memo: "",
    groupName: "",
    uploadWorkNumber: "",
    sort: true,
  });

  const [marketAccountValue, setMarketAccountValue] = useState<MarketAccount>();
  const [pageNum, setPageNum] = useState(1);
  const [viewConditionValue, setViewConditionValue] = useState({
    isView: false,
    viewValue: { filter: "MORE", count: 0 },
  });
  const [date, setDate] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  const getSearchResult = usePost(
    "/ProductManagement/SearchMarketProductSummary",
    {
      pageNum,
      pageLimit: searchingValue.pageLimit,
      code: searchingValue.code,
      productName: searchingValue.productName,
      memo: searchingValue.memo,
      groupName: searchingValue.groupName,
      uploadWorkNumber:
        parseInt(searchingValue.uploadWorkNumber) !== 0
          ? parseInt(searchingValue.uploadWorkNumber)
          : null,
      sort: searchingValue.sort,
      market: marketAccountValue?.market,
      marketAccount: marketAccountValue?.account?.account,
      startDate: new Date(date.startDate as string),
      endDate: new Date(date.endDate as string),
      viewsCondition: viewConditionValue.isView
        ? viewConditionValue.viewValue
        : null,
    }
  );

  const [selectedMarket, setSelectedMarket] = useState<MarketAccount>();
  const [selectedItem, setSelectedItem] = useState<ProdcutManageResultItem[]>(
    []
  );

  return (
    <div>
      <NavBar>
        <NavProductManage />
      </NavBar>
      <ProductManageSearchingBoard
        selectedMarket={selectedMarket}
        marketAccountValue={marketAccountValue}
        setMarketAccountValue={setMarketAccountValue}
        searchingValue={searchingValue as ProductManageSearchingValue}
        setSearchingValue={setSearchingValue}
        getSearchResult={getSearchResult}
        setDate={setDate}
        date={date}
        setPageNum={setPageNum}
        setSelectedItem={setSelectedItem}
        setSelectedMarket={setSelectedMarket}
        viewConditionValue={viewConditionValue}
        setViewConditionValue={setViewConditionValue}
      />
      <ProductManageSubtitle
        searchLength={getSearchResult?.data?.summary?.length}
        marketAccountValue={marketAccountValue}
        selectedItem={selectedItem}
        totalCount={getSearchResult?.data?.totalCount}
        getSearchResult={getSearchResult}
        setSearchingValue={setSearchingValue}
        searchingValue={searchingValue as ProductManageSearchingValue}
        setSelectedItem={setSelectedItem}
        setPageNum={setPageNum}
      />
      <ProductManageResultBoard
        selectedMarket={selectedMarket}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        isLoading={getSearchResult?.isLoading}
        result={getSearchResult?.data?.summary}
        account={marketAccountValue?.account?.account!}
      />
      {getSearchResult.data && getSearchResult.data.summary?.length !== 0 && (
        <ProductManagePagination
          totalPage={getSearchResult?.data?.totalPage}
          setPageNum={setPageNum}
          pageNum={pageNum}
          getSearchResult={getSearchResult}
          setSelectedItem={setSelectedItem}
        />
      )}
    </div>
  );
};

export default ProductManage;
