import { CreateBillKey } from "@/interface/pay.interface";
import axiosInstance from "@/utils/axiosInstance";
import axios from "axios";
import { config } from "process";

export const createBillKey = async (cardValue: CreateBillKey) => {
  const res = await axiosInstance.post("AllddamPay/CreateBillKey", cardValue);

  return res.data;
};

export const getBillKey = async () => {
  const res = await axiosInstance.get("AllddamPay/GetBillingInfo");

  return res.data;
};

export const deleteBillKey = async () => {
  const res = await axiosInstance.delete("AllddamPay/DeleteBillKey");

  return res.data;
};

export const paymentCoin = async (coinName: "C1" | "C2") => {
  const res = await axiosInstance.post(
    "AllddamPay/PaymentAllddamCoin",
    coinName
  );

  return res.data;
};

export const subscribePlan = async (name: string, slot: number) => {
  const res = await axiosInstance.post("AllddamPay/SubscribeAllddamPlan", {
    name,
    slot,
  });

  return res.data;
};

export const getPayLog = async (page?: number, limit?: number) => {
  const res = await axiosInstance.get("AllddamPay/GetPaymentDetails", {
    params: {
      page,
      limit,
    },
  });
  return res.data;
};

export const businessesValidate = async (businessesNumber: string) => {
  try {
    const res = await axios.post(
      `/businessman/v1/status?serviceKey=${process.env.REACT_APP_BUISNESSNUMBER_KEY}`,
      {
        b_no: [businessesNumber],
      }
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};
