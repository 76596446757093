import useFetch from "@/hooks/useFetch";
import { LoginResult } from "@/interface/orderManageInterface";
import { Account, AccountList } from "@/interface/uploadinterface";
import { checkLoginAllOnce } from "../../../../utils/functions/checkLoginAll";
import { checkLoginFromAccountList } from "@/utils/functions/checkLoginFromAccountList";
import { orderManage } from "@/utils/functions/postApi";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "@/components/Spinner/Spinner";
import { GreySmallButton } from "@/components/Button/allttamButton/GreySmallButton";
import { useInfoWindow } from "@/hooks/useInfoWindow";

interface Props {
  isLoading: boolean;
  market: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OrderLoginResult = ({
  market,
  isLoading,
  setIsLoading,
}: Props) => {
  const openWindow = useInfoWindow();
  const [loginResultList, setLoginResultList] = useState<LoginResult[]>([]);

  const [accountList] = useFetch(
    "accountList",
    "/MarketInfo/GetUserAllMarketAccount"
  );

  const checkLoginAll = useCallback(async () => {
    try {
      setIsLoading(true);
      if (market === "" || market === null) {
        const result = await checkLoginFromAccountList(accountList);
        setLoginResultList(result);
        result?.forEach(async (item) => {
          const res = await orderManage?.getOrder(
            item.market === "SmartStore" ? "SmartStoreAPI" : item.market,
            item.account
          );
        });
      } else {
        const account = accountList?.find(
          (item: AccountList) => item.id === market
        );
        if (account) {
          for (const child of account?.children) {
            const res = await checkLoginAllOnce(
              child?.label.split("] ")[1],
              market
            );
            setLoginResultList((prev) => [
              ...prev,
              {
                market: market,
                account: child.label.split("] ")[1],
                result: res ? true : false,
              },
            ]);
          }
        }
      }
    } catch (err) {
      setIsLoading(false);
      // console.log(err);
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [accountList, setLoginResultList, setIsLoading, market]);

  useEffect(() => {
    if (!accountList) return;
    checkLoginAll();
  }, [accountList]);

  const clickLogin = useCallback((market: string) => {
    if (market === "SmartStore") {
      window.open("https://sell.smartstore.naver.com/#/login", "", "_blank");
    } else if (market === "Gmarket" || market === "Auction") {
      window.open("https://www.esmplus.com/Home/Home", "", "_blank");
    } else {
      openWindow("등록된 API를 확인해주세요.");
    }
  }, []);

  return (
    <div className="widthHalf">
      <label htmlFor="productName" className="searchingTableFont">
        로그인 기록
      </label>
      <div className={`loginLog mt-1 searchingTableFont relative `}>
        {isLoading && (
          <div className="order-loginresult-loading-box">
            <Spinner />
          </div>
        )}
        <div className="overflowY-scroll h-100 loginLogComplete">
          {loginResultList.length !== 0 && !isLoading ? (
            loginResultList.map((item, idx) => (
              <div className="mt-1" key={idx}>
                <span>
                  [{item.market}] {item.account}
                </span>{" "}
                {item.result ? (
                  <span className="textGreen">로그인 성공</span>
                ) : (
                  <span>
                    <span className="textRed">로그인 실패</span>
                    <button
                      onClick={() => clickLogin(item.market)}
                      className="madebutton loginbutton"
                    >
                      로그인
                    </button>
                  </span>
                )}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <GreySmallButton onClick={checkLoginAll}>다시시도</GreySmallButton>
      </div>
    </div>
  );
};
