export const DownArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 1442" fill="none" d="M0 0h30v30H0z"></path>
      <path
        data-name="패스 4064"
        d="m1131.574 93.9 4.757-5-5-4.757"
        transform="rotate(90 611.616 -507.212)"
        stroke="#39415c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        fill="none"
      ></path>
    </svg>
  );
};
