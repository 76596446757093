import { useEffect, useState } from "react";
import { ModalBg } from "@/components/ModalLayout/ModalBg";
import { fileRequest } from "@/utils/functions/fileRequest";
import { OptionGroupType, UploadSummary } from "@/interface/uploadinterface";
import { useQueryClient } from "react-query";
import { ModalInnerTop } from "@/components/ModalLayout/ModalInnerTop";
import { XCircleIcon } from "@/components/svg/XCircleIcon";
import { PlusImageIcon } from "@/components/svg/PlusImageIcon";
import { ImgEditor } from "../imgeditor/ImgEditor";
import { base64toBlob } from "@/utils/functions/base64toBlob";

interface Props {
  item: UploadSummary;
  closeModal: () => void;
  mainImg: string;
  optionGroups: OptionGroupType[];
  optionIndex: number;
  groupIndex: number;
  setOptionGroups: React.Dispatch<React.SetStateAction<OptionGroupType[]>>;
  code: string;
}

export const OptionImageEdit = ({
  item,
  code,
  setOptionGroups,
  optionIndex,
  groupIndex,
  closeModal,
  optionGroups,
  mainImg,
}: Props) => {
  const [thumbNail, setThumbNail] = useState<string>(mainImg);
  const [imgList, setImgList] = useState<string[]>([]);
  const [cropImg, setCropImg] = useState<string>("");
  const queryClient = useQueryClient();

  useEffect(() => {
    let imgArr: string[] = [];
    optionGroups.map((item) => {
      item.groups.map((item) => {
        if (item.optionImage !== null) {
          imgArr = [...imgArr, item.optionImage as string];
        }
      });
    });
    setImgList(imgArr.filter((item, idx) => imgArr.indexOf(item) === idx));
    setCropImg(mainImg);
  }, [optionGroups]);

  const clickMainThumbnail = (src: string) => {
    setThumbNail(src);
    setCropImg(src);
  };

  const uploadImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const uploadRes = await fileRequest("/Image/UploadImage", file);
    setImgList((prev) => [...prev, uploadRes.link]);
  };
  const clickDelete = (idx: number) => {
    const newArr = [...imgList];
    newArr.splice(idx, 1);
    setImgList(newArr);
  };

  const setOptionThumbnail = () => {
    const newArr = [...optionGroups];
    newArr[groupIndex].groups[optionIndex].optionImage = thumbNail;
    setOptionGroups(newArr);
    closeModal();
  };

  const applyImage = async (img: string) => {
    const uploadRes = await fileRequest(
      "/Image/UploadImage",
      base64toBlob(img)
    );
    const newArr = [...imgList];
    newArr[imgList.indexOf(cropImg)] = uploadRes.link;
    setImgList(newArr);
    setCropImg(uploadRes.link);
    setThumbNail(uploadRes.link);
  };

  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop>
        <>
          <div className="modal-header">
            <h5 className="modal-title d-flex align-items-center">
              옵션 이미지
            </h5>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row pt-3 imges-line-nine1x">
              <div className="imges-line-nine">
                <label className="imageInputLabel optionSmallThumbnail">
                  <PlusImageIcon />
                  <input
                    onChange={uploadImg}
                    className="imageInput"
                    type="file"
                    accept="image/*"
                  />
                </label>
              </div>
              {imgList.map((item, idx) => (
                <div key={idx} className="imges-line-nine relative">
                  <button
                    onClick={() => clickMainThumbnail(item)}
                    className="madebutton paddingZero"
                  >
                    <img className="optionSmallThumbnail" src={item} />
                  </button>
                  <button
                    onClick={() => clickDelete(idx)}
                    className="madebutton paddingZero optionImgDeleteButton"
                  >
                    <XCircleIcon />
                  </button>
                </div>
              ))}
            </div>
            <div className="textCenter">
              <ImgEditor
                setCropImg={setCropImg}
                item={item}
                cropImg={cropImg}
                applyImage={applyImage}
                imgList={imgList}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                  <button onClick={closeModal} className="cancel-btn">
                    취소
                  </button>
                  <button
                    onClick={setOptionThumbnail}
                    className="cancel-btn save-btn"
                  >
                    이미지 변경
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
