import { axios } from "../axioxFetch";
import chromeAPI from "./chromeAPI";

const getMarketLoginResult = async (account, market) => {
  switch (market) {
    case "SmartStore":
      return await checkSmartStoreLogin(account);
    case "SmartStore2":
      return await checkSmartStoreLogin2(account);
    case "Auction":
      return checkAuctionLogin(account);
    case "Gmarket":
      return checkGmarketLogin(account);
    case "Coupang":
      return checkCoupangLogin();
    case "Elevenst":
      return checkElevenstLogin();
  }
};

export const checkMarketLogin = async (account, market) => {
  if (!account) {
    return;
  }
  try {
    const isExCheck = await chromeAPI.exCheck();
    if (isExCheck.responseMsg.code !== 200) {
      alert("확장프로그램을 확인해주세요.");
      return;
    }
    const loginResult = await getMarketLoginResult(account, market);
    // if (loginResult.responseMsg.code !== 200) {
    //   window.open(loginResult.url, "", "_blank");
    //   return;
    // } else {
    //   const loginId = await axiosInstance.post(
    //     "/MarketInfo/GetSmartStoreLoginID",
    //     loginResult.responseMsg.data
    //   );s
    //   return loginId;
    // }
    return loginResult;
  } catch {
    alert("확장프로그램을 확인해주세요.");
  }
};

const checkSmartStoreLogin2 = async (account) => {
  const loginResult = await chromeAPI.NaverLoginCheck(account);
  if (loginResult.responseMsg.code === 200) {
    const result = await axios.post(
      `/MarketInfo/GetSmartStoreLoginID`,
      loginResult.responseMsg.data
    );
    return {
      account: result.data,
      data: loginResult.responseMsg.data,
    };
  } else {
    window.open("https://sell.smartstore.naver.com/#/login", "", "_blank");
  }
};

const checkSmartStoreLogin = async (account) => {
  const loginResult = await chromeAPI.NaverLoginCheck(account);

  if (loginResult.responseMsg.code === 200) {
    const result = await axios.post(
      `/MarketInfo/CheckSmartStoreLoginID?selectedAccount=${account}`,
      loginResult.responseMsg.data
    );
    if (!result) {
      return result;
    } else {
      // window.open("https://sell.smartstore.naver.com/#/login", "", "_blank");
    }
  } else {
    window.open("https://sell.smartstore.naver.com/#/login", "", "_blank");
  }
};

const checkAuctionLogin = async (account) => {
  const loginResult = await chromeAPI.AuctionLoginCheck(account);
  if (loginResult.responseMsg.code === 200) {
    const result = await axios.post(
      `/MarketInfo/CheckAuctionLoginID?selectedAccount=${account}`,
      loginResult.responseMsg.data
    );

    if (result.data === "[옥션] 로그인 성공") {
      return result;
    } else {
      window.open("https://www.esmplus.com/Home/Home", "", "_blank");
    }
  } else {
    window.open("https://www.esmplus.com/Home/Home", "", "_blank");
  }
};

const checkGmarketLogin = async (account) => {
  const loginResult = await chromeAPI.AuctionLoginCheck(account);
  if (loginResult.responseMsg.code === 200) {
    const result = await axios.post(
      `/MarketInfo/CheckGmarketLoginID?selectedAccount=${account}`,
      loginResult.responseMsg.data
    );
    if (result.data === "[지마켓] 로그인 성공") {
      const loginId = await axios.post(
        "MarketInfo/GetGmarketLoginId",
        loginResult.responseMsg.data
      );
      return { id: loginId.data, data: loginResult.responseMsg.data };
    } else {
      // window.open("https://www.esmplus.com/Home/Home", "", "_blank");
    }
  } else {
    window.open("https://www.esmplus.com/Home/Home", "", "_blank");
  }
};

const checkCoupangLogin = async (account) => {
  return;
};

const checkElevenstLogin = async (account) => {
  return;
};
