interface Props {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  color?: string;
}

export const QuestionMarkIcon = ({
  onMouseEnter,
  onMouseLeave,
  color,
}: Props) => {
  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1759_40519)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM4.927 4.99C4.642 5.419 4.5 5.843 4.5 6.26C4.5 6.463 4.59 6.652 4.77 6.826C4.95 7 5.17 7.086 5.431 7.086C5.874 7.086 6.175 6.838 6.334 6.34C6.502 5.865 6.707 5.505 6.95 5.26C7.193 5.016 7.57 4.894 8.084 4.894C8.523 4.894 8.881 5.014 9.159 5.257C9.436 5.499 9.575 5.797 9.575 6.149C9.57621 6.32559 9.52919 6.49917 9.439 6.651C9.34729 6.8058 9.23418 6.94686 9.103 7.07C8.89259 7.2624 8.6765 7.44848 8.455 7.628C8.115 7.91 7.844 8.153 7.643 8.358C7.443 8.563 7.281 8.801 7.16 9.071C6.838 10.316 8.51 10.416 8.896 9.527C8.943 9.441 9.014 9.347 9.109 9.243C9.205 9.14 9.332 9.02 9.491 8.883C9.89558 8.54601 10.2937 8.20128 10.685 7.849C10.906 7.645 11.097 7.401 11.258 7.119C11.424 6.81806 11.5075 6.47859 11.5 6.135C11.5 5.66 11.359 5.22 11.076 4.815C10.794 4.409 10.394 4.089 9.876 3.853C9.358 3.618 8.761 3.5 8.084 3.5C7.356 3.5 6.719 3.64 6.173 3.923C5.627 4.205 5.212 4.561 4.927 4.99ZM7.067 12.07C7.067 12.3352 7.17236 12.5896 7.35989 12.7771C7.54743 12.9646 7.80178 13.07 8.067 13.07C8.33222 13.07 8.58657 12.9646 8.77411 12.7771C8.96164 12.5896 9.067 12.3352 9.067 12.07C9.067 11.8048 8.96164 11.5504 8.77411 11.3629C8.58657 11.1754 8.33222 11.07 8.067 11.07C7.80178 11.07 7.54743 11.1754 7.35989 11.3629C7.17236 11.5504 7.067 11.8048 7.067 12.07Z"
          fill={`${color ? color : "#7E86A2"}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_1759_40519">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
