interface Props {
  className?: string;
}

export const ViewWayDefaultIcon = ({className}: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="그룹 11037" transform="translate(-1608 -571)">
        <rect
          data-name="사각형 3882"
          width="20"
          height="2"
          rx="1"
          transform="translate(1613 578)"
          fill="#a7b0c7"
        />
        <rect
          data-name="사각형 3883"
          width="20"
          height="2"
          rx="1"
          transform="translate(1613 585)"
          fill="#a7b0c7"
        />
        <rect
          data-name="사각형 3884"
          width="20"
          height="2"
          rx="1"
          transform="translate(1613 592)"
          fill="#a7b0c7"
        />
      </g>
      <path data-name="사각형 3885" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
