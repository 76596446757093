import useFetch from "@/hooks/useFetch";
import useUser from "@/hooks/useUser";

interface Props {
  setNavState: React.Dispatch<
    React.SetStateAction<"taobao" | "ali" | "1688" | "vvic" | "costco">
  >;
  navState: "taobao" | "ali" | "1688" | "vvic" | "costco";
}

export const NavCollect = ({ setNavState, navState }: Props) => {
  const userInfo = useUser();
  const selectComponent = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNavState(
      e.currentTarget.value as "taobao" | "ali" | "1688" | "vvic" | "costco"
    );
  };

  return (
    <div className="heading-one payment-sec detail-sec product-collect-nav">
      <ul className="d-flex align-items-center">
        <li className="nav-item">
          <button
            onClick={selectComponent}
            value="taobao"
            className={`oneLine nav-link madebutton ${
              navState === "taobao" && "selectedNavItem"
            }`}
          >
            타오바오
          </button>
          {navState !== "taobao" && <span />}
        </li>
        <li className="nav-item">
          <button
            onClick={selectComponent}
            value="ali"
            className={`oneLine  nav-link madebutton ${
              navState === "ali" && "selectedNavItem"
            }`}
          >
            알리익스프레스
          </button>
          {navState !== "ali" && <span />}
        </li>
        <li className="nav-item">
          <button
            onClick={selectComponent}
            value="1688"
            className={`oneLine  nav-link madebutton ${
              navState === "1688" && "selectedNavItem"
            }`}
          >
            1688
          </button>
          {navState !== "1688" && <span />}
        </li>
        <li className="nav-item">
          <button
            onClick={selectComponent}
            value="vvic"
            className={`oneLine nav-link madebutton ${
              navState === "vvic" && "selectedNavItem"
            }`}
          >
            VVIC
          </button>
          {navState !== "vvic" && <span />}
        </li>
        {userInfo && userInfo.role === "Admin" && (
          <li className="nav-item">
            <button
              onClick={selectComponent}
              value="costco"
              className={`oneLine nav-link madebutton ${
                navState === "costco" && "selectedNavItem"
              }`}
            >
              코스트코
            </button>
            {navState !== "costco" && <span />}
          </li>
        )}
      </ul>
    </div>
  );
};
