import useFetch from "@/hooks/useFetch";
import { CollectValue } from "@/interface/collectInterface";

interface Props {
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
  closeMenu: () => void;
}

export const GroupNameSelect = ({ setCollectValue, closeMenu }: Props) => {
  const [productGroupList] = useFetch(
    "productGroup",
    "/Product/GetProductGroupNames"
  );

  const selectGroupName = (value: string) => {
    setCollectValue((prev) => {
      return {
        ...prev,
        groupName: value,
      };
    });
  };

  return (
    <div className="d-flex flex-col widthFull white-box collect-select-box">
      {productGroupList?.map((item: string, idx: number) => (
        <button
          onClick={() => {
            selectGroupName(item);
            closeMenu();
          }}
          key={idx}
          className="madebutton weight500 navy text12 margin0 mt-2 collect-select-text"
        >
          {item}
        </button>
      ))}
      <div className="menuoutside" onClick={closeMenu}></div>
    </div>
  );
};
