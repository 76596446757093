import styles from "./Input.module.css";

interface Props {
  onClick: () => void;
  checked: boolean;
}

export const CheckBoxInput = ({ onClick, checked }: Props) => {
  return (
    <div className="d-flex align-items-center gap-1">
      <div
        className={`relative ${styles["allttam-input-check"]}`}
        onClick={onClick}
      >
        <input readOnly type="checkbox" checked={checked} />
        <label></label>
      </div>
    </div>
  );
};
