import {
  MarketAccount,
  ProdcutManageResultItem,
} from "@/interface/productmanageinterface";
import { ResultTableHead } from "./compoent/ResultTableHead";
import { ResultTableItemComponent } from "./compoent/ResultTableItemComponent/ResultTableItemComponent";
import { UploadNoneResult } from "@/components/Upload/Uploadresulttable/UploadNoneResult";
import { Spinner } from "@/components/Spinner/Spinner";

interface Props {
  result: ProdcutManageResultItem[];
  account: string;
  selectedItem: ProdcutManageResultItem[];
  setSelectedItem: React.Dispatch<
    React.SetStateAction<ProdcutManageResultItem[]>
  >;
  selectedMarket: MarketAccount | undefined;
  isLoading: boolean;
}

export const ProductManageResultBoard = ({
  isLoading,
  result,
  account,
  selectedItem,
  setSelectedItem,
  selectedMarket,
}: Props) => {
  const selectAll = () => {
    if (result?.length === selectedItem.length) {
      setSelectedItem([]);
    } else {
      let tempArr: any = [];
      setSelectedItem([]);
      result.map((item) => tempArr.push(item));
      setSelectedItem(tempArr);
    }
  };

  return (
    <div className="row total-table-col collect-market">
      <div className="col-12">
        <div className="heading-two heading-three heading-five heading-five2">
          <table
            className="productManageTable"
            width="100%"
            cellPadding={0}
            cellSpacing={0}
            border={0}
            align="center"
          >
            <ResultTableHead
              selectAll={selectAll}
              isChecked={
                result?.length !== 0 && result?.length === selectedItem.length
              }
            />
            <tbody className="body-one">
              {isLoading && (
                <tr className="">
                  <td colSpan={6}>
                    <div className="mt-5 pt-5">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}
              {result &&
                result.map((result) => (
                  <ResultTableItemComponent
                    selectedMarket={selectedMarket}
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                    key={result.id}
                    result={result}
                    account={account}
                  />
                ))}
              {(!result || result.length === 0) && !isLoading && (
                <UploadNoneResult colSpan={6} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
