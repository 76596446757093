import { useTooltip } from "@/hooks/useTooltip";
import taobaoIcon from "@/assets/images/marketIcon/taobao.svg";
import aliIcon from "@/assets/images/marketIcon/ali.svg";
import vvicIcon from "@/assets/images/marketIcon/vvic.svg";
import costcoIcon from "@/assets/images/marketIcon/costco.svg";
import icon1688 from "@/assets/images/marketIcon/1688.svg";
import { getSBLink } from "@/utils/functions/getSBLink";

interface Props {
  url: string;
  site: string;
  isSelect: boolean;
  code: string;
}

export const ResultItemTumbnail = ({ url, site, isSelect, code }: Props) => {
  const [isTooltipVisible, handleMouseEnter, handleMouseLeave] = useTooltip();
  return (
    <td className="tooltipRel">
      <div className={`collect-inner-1 hidden ${isSelect && "selectedItem"}`}>
        {isTooltipVisible && (
          <div className="productManageTooltip2 oneLine">상품 URL로 이동</div>
        )}
        <div className="d-flex align-items-center tooltipTd ">
          <button
            onMouseEnter={handleMouseEnter as () => void}
            onMouseLeave={handleMouseLeave as () => void}
            onClick={(e) => {
              e.stopPropagation();
              getSBLink(code, url);
            }}
            className="toMarketAnchor d-flex align-items-center gap-2 madebutton"
          >
            <img
              className="logoBtnBg"
              src={
                SITE_INDEX[
                  site as "Taobao" | "Costco" | "AliExpress" | "1688" | "VVIC"
                ]
              }
            />
            {site}
          </button>
        </div>
      </div>
    </td>
  );
};

const SITE_INDEX = {
  Taobao: taobaoIcon,
  AliExpress: aliIcon,
  Costco: costcoIcon,
  1688: icon1688,
  VVIC: vvicIcon,
};
