import { ReactCropperElement } from "react-cropper";

import { useTooltip } from "@/hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";
import { ReductionIcon } from "@/components/svg/ReductionIcon";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const ReductionImgButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleScaleDown = () => {
    cropperRef.current?.cropper.zoom(-0.1);
  };
  return (
    <button
      onClick={handleScaleDown}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("축소 보기")}
    >
      <ReductionIcon />
    </button>
  );
};
