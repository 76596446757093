import promote1 from "@/assets/images/payment/promote1.png";
import promote2 from "@/assets/images/payment/promote2.png";
import promote3 from "@/assets/images/payment/promote3.png";
import { GreySmallButton } from "@/components/Button/allttamButton/GreySmallButton";

export const Promote = () => {
  return (
    <>
      <div className="col-32 mt-3 moblie-hidden">
        <div className="heading-one payment-sec">
          <h3 className="h3 weight600">프로모션</h3>
        </div>
      </div>
      <div className="d-flex gap-5 mt-4 moblie-hidden">
        {PROMOTE.map((item) => (
          <div className="d-flex flex-col" key={item.id}>
            <img src={item.img} className="widthFull" />
            <div className="promoteBox">
              <p className="weight600 navy text14 oneLine">{item.text}</p>
              <div className="d-flex justify-content-between">
                <p className="weight700 text35 margin0">
                  {item.coin.toLocaleString()}
                  <span className="text14 weight600 pl2">코인</span>
                </p>
                <GreySmallButton
                  onClick={() => {
                    window.open(item.clickUrl, "_blank");
                  }}
                >
                  Free
                </GreySmallButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const PROMOTE = [
  {
    id: 0,
    img: promote1,
    text: "올땀 N카페에서 [무료사용 신청] 작성시,",
    coin: 1000,
    clickUrl: "https://cafe.naver.com/mandeunydle/442",
  },
  {
    id: 1,
    img: promote2,
    text: "올땀 N카페에서 [혜택신청] 작성시, 최대",
    coin: 2500,
    clickUrl: "https://cafe.naver.com/mandeunydle/442",
  },
  {
    id: 2,
    img: promote3,
    text: "올땀 N카페에서 [사업일기] 작성시, 월 최대",
    coin: 3000,
    clickUrl: "https://cafe.naver.com/mandeunydle/442",
  },
];
