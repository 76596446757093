export const SaleStopIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="그룹 10956" transform="translate(-1485 -244)">
        <rect
          data-name="사각형 3862"
          width="4"
          height="16"
          rx="2"
          transform="translate(1494 251)"
          fill="#ffb500"
        />
        <rect
          data-name="사각형 3863"
          width="4"
          height="16"
          rx="2"
          transform="translate(1503 251)"
          fill="#ffb500"
        />
      </g>
      <path
        data-name="사각형 3864"
        fill="none"
        className="svg-stroke3"
        d="M0 0h30v30H0z"
      />
    </svg>
  );
};
