import { useState } from "react";
import { usePost } from "../../../hooks/usePost";
import { CSSearchValue, Date } from "../../../interface/orderManageInterface";
import { CS, CSTable } from "./CSTable/CSTable";
import { CSSearchingBoard } from "./CSSearchingBoard/CSSearchingBoard";
import { CSSubMenu } from "./CSSubMenu/CSSubMenu";

export const OrderManageCS = () => {
  const [searchValue, setSearchValue] = useState<CSSearchValue>({
    market: "",
    marketAccount: "",
  });

  const [selectedCS, setSelectedCS] = useState<SelectCS>();

  const [date, setDate] = useState<Date>({
    startDate: "",
    endDate: "",
  });

  const CSList = usePost("/MarketCS/GetMarketCS", {
    market: searchValue.market,
    marketAccount: searchValue.marketAccount,
    startDate: date.startDate,
    endDate: date.endDate,
  });

  return (
    <div>
      <CSSearchingBoard
        CSList={CSList}
        date={date}
        setDate={setDate}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setSelectedCS={setSelectedCS}
      />
      <CSSubMenu
        length={Array.isArray(CSList.data) ? CSList.data.length : 0}
        selectedCS={selectedCS}
      />
      <CSTable
        CSList={CSList}
        setSelectedCS={setSelectedCS}
        selectedCS={selectedCS}
      />
    </div>
  );
};

export interface SelectCS {
  account: string;
  cs: CS | undefined;
}
