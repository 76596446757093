import { PlusImageIcon } from "@/components/svg/PlusImageIcon";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PluseImgInput = ({ onChange }: Props) => {
  return (
    <label className="imageInputLabel">
      <PlusImageIcon />
      <input
        onChange={onChange}
        className="imageInput"
        type="file"
        accept="image/jpeg, image/png"
      />
    </label>
  );
};
