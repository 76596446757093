import useFetch from "@/hooks/useFetch";
import useUser from "@/hooks/useUser";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Developer = () => {
  const userInfo = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role !== "Admin") {
        alert("권한이 없습니다.");
        navigate("/home");
      }
    }
  }, [userInfo]);

  return (
    <>
      <></>
    </>
  );
};
