import { AlertConfirmAndClose } from "@/components/Alert/AlertConfirmAndClose";
import { AlertOnlyClose } from "@/components/Alert/AlertOnlyClose";
import { useAlert } from "@/hooks/useAlert";
import useToggle from "@/hooks/useToggle";
import { ProhibitReplaceWord } from "@/interface/uploadinterface";
import { changeTimeFormat } from "@/utils/functions/changeTimeFormat";
import { upload } from "@/utils/functions/postApi";
import { useEffect, useState } from "react";
import { UseMutationResult } from "react-query";
import { XIcon } from "@/components/Admin/AdminCategoryMapping/component/svg/XIcon";

interface Props {
  word: ProhibitReplaceWord;
  selectedProhibitWordList: ProhibitReplaceWord[];
  setSelectedProhibitWordList: React.Dispatch<
    React.SetStateAction<ProhibitReplaceWord[]>
  >;
  getProhibitWord: UseMutationResult<any, unknown, void, unknown>;
}

export const ProhibitWordTableItem = ({
  word,
  selectedProhibitWordList,
  setSelectedProhibitWordList,
  getProhibitWord,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>();
  const { state: isDeleteModal, handleToggle: handleDeleteModal } = useToggle();
  const { isAlert, alertMessage, handleAlert } = useAlert();

  useEffect(() => {
    if (selectedProhibitWordList.find((item) => item.idx === word.idx)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedProhibitWordList]);

  const selectProhibitWord = () => {
    if (isSelect) {
      setSelectedProhibitWordList(
        selectedProhibitWordList.filter((item) => item.idx !== word.idx)
      );
    } else {
      setSelectedProhibitWordList((prev) => [...prev, word]);
    }
  };

  const confirmRemove = async () => {
    handleDeleteModal();
    const deleteRes = await upload.removeProhibitReplaceWord([word.idx]);
    if (deleteRes === "완료했습니다.") {
      handleAlert("성공적으로 삭제했습니다.");
    }
  };

  return (
    <tr onClick={selectProhibitWord}>
      <td>
        <div
          className={`border-left form-group d-flex align-items-center justify-content-center height45 ${
            isSelect && "selectedItem"
          }`}
        >
          <input type="checkbox" checked={isSelect} readOnly />
          <label></label>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center height45 ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0 weight500">{word.targetWord}</p>
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center height45 ${
            isSelect && "selectedItem"
          }`}
        >
          <p className="margin0">
            {changeTimeFormat(word.regDate.split("T")[0])}
          </p>
          {isDeleteModal && (
            <AlertConfirmAndClose
              message={`${word.targetWord}를 금지단어에서 삭제하시겠습니까?`}
              confirmDoing={confirmRemove}
              closeAlert={handleDeleteModal}
            />
          )}
          {isAlert && (
            <AlertOnlyClose
              closeAlert={handleAlert as () => void}
              message={alertMessage}
              additionalCleanup={() => getProhibitWord.mutate()}
            />
          )}
        </div>
      </td>
      <td>
        <div
          className={`d-flex align-items-center justify-content-center height45 border-right ${
            isSelect && "selectedItem"
          }`}
        >
          <button
            className="madebutton d-flex align-items-center"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteModal();
            }}
          >
            <XIcon />
          </button>
        </div>
      </td>
    </tr>
  );
};
