import { useEffect, useState } from "react";
import { getCookie, setCookie } from "./function/cookieFunction";
import styles from "./Popup.module.css";

interface Props {
  img: string;
  link: string;
  name: string;
}

export const Popup = ({ img, link, name }: Props) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupClosedDate = getCookie(name);

    if (!popupClosedDate) {
      return setShowPopup(true);
    }

    if (popupClosedDate) {
      const today = new Date();
      const sevenDaysAgo = new Date(popupClosedDate);

      sevenDaysAgo.setDate(sevenDaysAgo.getDate() + 7);

      if (today >= sevenDaysAgo) {
        setShowPopup(true);
      }
    }
    if (showPopup) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const closePopup = (expireDate?: number) => {
    if (!expireDate) return setShowPopup(false);
    if (expireDate) {
      setShowPopup(false);
      setCookie(name, new Date().toUTCString(), expireDate);
    }
  };

  return showPopup ? (
    <div className="agree-guard-background" onClick={() => closePopup()}>
      <div
        className={`${styles["popup-container"]}`}
        onClick={(e) => e.stopPropagation()}
      >
        <img src={img} className={`${styles["popup-img"]}`} />
        <div className="w-full d-flex flex-col justify-content-center align-items-center gap-3">
          <a
            href={link}
            target="_blank"
            className={`${styles["popup-link-btn"]}`}
          >
            자세히 보기
          </a>
          <button
            onClick={() => closePopup(7)}
            className="weight400 grey madebutton"
          >
            7일간 보지 않기
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
