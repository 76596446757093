import NavBar from "@/components/NavBar/NavBar";
import { NavPayment } from "@/components/NavBar/component/NavPayment";
import { PaymentPayLog } from "@/components/Payment/PaymentPayLog/PaymentPayLog";
import { PaymentUserInfo } from "@/components/Payment/PaymentUserInfo/PaymentUserInfo";
import "@/styles/Payment.css";
import { useAlert } from "@/hooks/useAlert";
import { AlertOnlyClose } from "@/components/Alert/AlertOnlyClose";
import { CoinTab } from "@/components/Payment/Tab/CoinTab";
import { PayInfo } from "@/components/Payment/PayInfo/PayInfo";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Payment = () => {
  const { isAlert, alertMessage, handleAlert } = useAlert();
  const [searchParams, setSearchParam] = useSearchParams();
  // const [tab, setTab] = useState<"coin" | "plan" | "starter">("starter");
  const tab = searchParams.get("tab");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  const TAB_INDEX = {
    coin: <CoinTab />,
    starter: isMobile ? <></> : <></>,
  };

  return (
    <div>
      <NavBar>
        <NavPayment />
      </NavBar>
      <div className=" d-flex row align-items-start">
        <PaymentUserInfo tab={tab} />
        <PaymentPayLog />
      </div>
      <div className="row mt-4 history-col">
        {tab === null && <CoinTab />}
        {TAB_INDEX[tab as "coin" | "starter"]}
        <PayInfo />
      </div>
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </div>
  );
};

export default Payment;
