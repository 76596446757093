import { GreenMiddleButton } from "@/components/Button/allttamButton/GreenMiddleButton";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";

export const DetailImgTemplates = () => {
  return (
    <div className="opacity5">
      <div className="d-flex gap-3 mt-3 align-items-center justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          <p className="margin0 weight500 text16 ">이미지 템플릿</p>
          <QuestionMarkIcon />
        </div>
        <GreenMiddleButton>패널적용</GreenMiddleButton>
      </div>
      <div className="img-templates-container mt-3">
        <p className="pt-5 pb-5 margin0 text12 weight500">준비 중입니다.</p>
      </div>
    </div>
  );
};
