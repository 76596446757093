import { upload } from "@/utils/functions/postApi";
import { replaceWord } from "@/utils/functions/replaceWord";
import { ProhibitReplaceWord } from "@/interface/uploadinterface";
import { sliceProhibitWord } from "@/utils/functions/sliceProhibitWord";
import { duplicateRemover } from "@/utils/functions/duplicateRemover";
import { useState } from "react";
import { GreyMiddleButton } from "@/components/Button/allttamButton/GreyMiddleButton";
import { WordOptionButton } from "./WordOptionButton";
import { WordOptionSelect } from "./WordOptionSelect";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";

interface Props {
  setProductNameValueList: React.Dispatch<
    React.SetStateAction<
      {
        code: string;
        text: string;
      }[]
    >
  >;
  setWordLengthLimit: React.Dispatch<React.SetStateAction<number>>;
  wordLengthLimit: number;
}

export const ProductNameWordSettingButton = ({
  setProductNameValueList,
  setWordLengthLimit,
  wordLengthLimit,
}: Props) => {
  const [checkedValue, setCheckedValue] = useState({
    isFillNumber: false,
    isDuplicateRemove: true,
    isProhibit: false,
    isReplace: false,
  });

  const clickRemoveProhibitWord = async () => {
    const getRes = await upload.getProhibitReplaceWord("Remove");
    setProductNameValueList((prev) =>
      prev.map((item) => {
        return {
          code: item.code,
          text: sliceProhibitWord(
            item.text,
            getRes.map((item: ProhibitReplaceWord) => item.targetWord)
          ),
        };
      })
    );
  };

  const clickReplaceWord = async () => {
    const getRes = await upload.getProhibitReplaceWord("Replace");
    const replaceWordArr = getRes.map((item: ProhibitReplaceWord) => {
      return {
        before: item.targetWord,
        after: item.convertWord,
      };
    });

    setProductNameValueList((prev) =>
      prev.map((item) => {
        return {
          code: item.code,
          text: replaceWord(item.text, replaceWordArr),
        };
      })
    );
  };

  const clickDuplicateRemover = () => {
    if (checkedValue.isFillNumber) {
      if (checkedValue.isDuplicateRemove) {
        setProductNameValueList((prev) =>
          prev.map((item, idx) => {
            const number = (idx + 1).toString().length;
            return {
              code: item.code,
              text:
                duplicateRemover(item.text).slice(0, wordLengthLimit - number) +
                (idx + 1),
            };
          })
        );
      } else {
        setProductNameValueList((prev) =>
          prev.map((item, idx) => {
            return {
              code: item.code,
              text: item.text + (idx + 1),
            };
          })
        );
      }
    } else if (checkedValue.isDuplicateRemove) {
      setProductNameValueList((prev) =>
        prev.map((item) => {
          return {
            code: item.code,
            text: duplicateRemover(item.text).slice(0, wordLengthLimit),
          };
        })
      );
    } else {
      setProductNameValueList((prev) =>
        prev.map((item) => {
          return {
            code: item.code,
            text: item.text.slice(0, wordLengthLimit),
          };
        })
      );
    }
  };

  const clickApply = () => {
    if (checkedValue.isProhibit) {
      clickRemoveProhibitWord();
    }
    if (checkedValue.isReplace) {
      clickReplaceWord();
    }
    clickDuplicateRemover();
  };

  return (
    <div className="d-flex gap-2 align-items-center">
      <div className="oneLine width15 margin0 d-flex align-items-center gap-3">
        <p className="productNameModalSubtitle margin0">글자 옵션 설정</p>
        <QuestionMarkIcon color="#39415c" />
      </div>
      <WordOptionButton
        setWordLengthLimit={setWordLengthLimit}
        wordLengthLimit={wordLengthLimit}
      />
      <div className="widthHalf d-flex gap-3 justify-content-between">
        <WordOptionSelect
          setCheckedValue={setCheckedValue}
          checkedValue={checkedValue}
        />
        <GreyMiddleButton onClick={clickApply}>적용</GreyMiddleButton>
      </div>
    </div>
  );
};
