import { UseMutationResult } from "react-query";
import { SearchingIconGreen } from "../../../../svg/SearchingIconGreen";
import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import { useState } from "react";
import { upload } from "@/utils/functions/postApi";
import { Keyword } from "@/interface/uploadinterface";

interface Props {
  setSearchAdKeywordList: React.Dispatch<React.SetStateAction<Keyword[]>>;
}

export const TagSettingSearchingBarProductManage = ({
  setSearchAdKeywordList,
}: Props) => {
  const [keywordValue, setKeyWordValue] = useState("");

  const searchKeyword = async () => {
    if (keywordValue.split(",").length < 5) {
      setKeyWordValue("");
    } else {
      setKeyWordValue(keywordValue.split(",").slice(5).join(", "));
    }

    const { keywordList } = await upload.getNaverKeyword(keywordValue);
    setSearchAdKeywordList(keywordList);
  };

  const inputKeyWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWordValue(e.target.value);
  };

  return (
    <div className="row mt-4">
      <div className="d-flex align-items-center gap-3">
        <p className="margin0 text18 weight500">태그 입력</p>
        <QuestionMarkIcon />
      </div>
      <div className="col-12 mt-3">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            searchKeyword();
          }}
          className="relative"
        >
          <input
            value={keywordValue}
            type="text"
            className="allttam-input input-pr-3 tag-placeholder"
            placeholder=""
            onChange={inputKeyWord}
          />
          <button className="madebutton input-searching-icon">
            <SearchingIconGreen />
          </button>
        </form>
      </div>
    </div>
  );
};
