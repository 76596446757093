import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import useSelectHelperComponent from "@/hooks/useSelectHelperComponent";
import { CollectValue } from "@/interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

export const SearchingDuplicate1688 = ({
  collectValue,
  inputCollectValue,
}: Props) => {
  const openHelper = useSelectHelperComponent();
  return (
    <div className="product-label select-image col-md-3 col-12">
      <div className="d-flex align-items-center gap-3 mb-2">
        <label htmlFor="product-name" className="paddingZero">
          중복상품 처리
        </label>
      </div>
      <div className="state-selection">
        <select
          onChange={inputCollectValue}
          value={collectValue.duplicate}
          name="duplicate"
          className="form-select"
        >
          <option value="UPDATE">업데이트</option>
          <option value="SKIP">건너뛰기</option>
        </select>
      </div>
    </div>
  );
};
