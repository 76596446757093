import useToggle from "@/hooks/useToggle";
import { CollectValue } from "@/interface/collectInterface";
import { GroupNameSelect } from "../../GroupNameSelect/GroupNameSelect";
import { DownArrowIcon } from "@/components/svg/DownArrowIcon";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  setColletValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const CostcoSearchingGroupName = ({
  collectValue,
  inputCollectValue,
  setColletValue,
}: Props) => {
  const { state: isSelect, handleToggle: handleSelect } = useToggle();
  return (
    <div className="product-label col-md-3 col-12">
      <label>상품 그룹명</label>
      <div className="relative">
        <input
          onChange={inputCollectValue}
          value={collectValue.groupName}
          name="groupName"
          type="text"
          className="form-control"
          placeholder="상품 그룹명을 입력해주세요."
        />
        <button
          className="madebutton collect-group-select-btn"
          onClick={handleSelect}
        >
          <DownArrowIcon />
        </button>
        {isSelect && (
          <GroupNameSelect
            setCollectValue={setColletValue}
            closeMenu={handleSelect}
          />
        )}
      </div>
    </div>
  );
};
