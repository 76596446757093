// export const urlToBase64 = async (url: string): Promise<string> => {
//   const data = await fetch(url);
//   const blob = await data.blob();
//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onloadend = () => {
//       const base64data = reader.result;
//       resolve(base64data as string);
//     };
//   });
// };

// ------------------------------------------------------------------------

// export const urlToBase64 = async (url: string): Promise<string> => {
//   try {
//     const data = await fetch(url);

//     // HTTP 요청 성공 여부 확인
//     if (!data.ok) {
//       throw new Error(`Failed to fetch resource. Status: ${data.status}`);
//     }

//     const blob = await data.blob();

//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(blob);

//       // 파일 읽기 성공 시
//       reader.onloadend = () => {
//         const base64data = reader.result;
//         resolve(base64data as string);
//       };

//       // 파일 읽기 실패 시
//       reader.onerror = (error) => {
//         reject('FileReader failed to convert blob to base64');
//       };
//     });
//   } catch (error) {
//     console.error('Error occurred during URL to base64 conversion:', error);
//     throw error;
//   }
// };


// ------------------------------------------------------------------------


export const urlToBase64 = async (url: string): Promise<string> => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 10000); // 10초 후 요청 취소

  try {
    // 기존 url을 HTTP에서 HTTPS로 변환
    const secureUrl = url.replace('http://', 'https://');

    // HTTPS로 변환된 secureUrl로 fetch 요청
    const data = await fetch(secureUrl, { signal: controller.signal });

    if (!data.ok) {
      throw new Error(`Failed to fetch resource. Status: ${data.status}`);
    }

    const blob = await data.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data as string);
      };

      reader.onerror = (error) => {
        reject('FileReader failed to convert blob to base64');
      };
    });
  } catch (error: any) {
    if (error.name === 'AbortError') {
      console.error('Fetch request timed out');
    } else {
      console.error('Error occurred during URL to base64 conversion:', error);
    }
    throw error;
  } finally {
    clearTimeout(timeoutId); // 타이머 정리
  }
};
