interface Props {
  isVisible: boolean;
  children: string | JSX.Element;
  style?: React.CSSProperties;
}

export const GlobalTooltip = ({ isVisible, children, style }: Props) => {
  return (
    <>
      {isVisible && (
        <div style={style} className="global-tooltip">
          <p className="oneLine">{children}</p>
        </div>
      )}
    </>
  );
};
