import useToggle from "@/hooks/useToggle";
import { UploadSummary } from "@/interface/uploadinterface";
import { UseMutationResult } from "react-query";
import { UploadEditOptionModal } from "../../modal/UploadEditOptionModal";

interface Props {
  item: UploadSummary;
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
}

export const ItemFunctionEditOption = ({
  item,
  collectProductSummary,
}: Props) => {
  const { state: isEditOptionModal, handleToggle: handleEditOptionModal } =
    useToggle();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <button
        onClick={handleEditOptionModal}
        className="cancel-btn save-btn reset-btn name-set-btn basic-btn"
      >
        옵션
      </button>
      {isEditOptionModal && (
        <UploadEditOptionModal
          closeModal={handleEditOptionModal}
          item={item}
          collectProductSummary={collectProductSummary}
        />
      )}
    </div>
  );
};
