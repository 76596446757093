import { DeliveryInfo } from "../OrderInfoModal";
import { OrderInfoPcc } from "./OrderInfoPcc";

interface Props {
  deliveryInfo: DeliveryInfo;
  inputDeliveryInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setIsCheckPcc: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OrderInfoDeliveryInfo = ({
  setIsCheckPcc,
  deliveryInfo,
  inputDeliveryInfo,
}: Props) => {
  return (
    <>
      <div className="textStart mt-4">
        <div>
          <div>
            <h5>주문자정보</h5>
            <div className="d-flex widthFull gap-3 mt-3 mobile-order-info">
              <div className="d-flex widthHalf align-items-center">
                <p className="ordererInfoTitle">주문자</p>
                <input
                  className="orderInfoModalInput"
                  value={deliveryInfo?.ordererName}
                  readOnly
                />
              </div>
              <div className="d-flex widthHalf align-items-center">
                <p className="ordererInfoTitle">전화번호</p>
                <input
                  className="orderInfoModalInput"
                  value={deliveryInfo?.ordererContactTelNo}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="textStart mt-4">
        <h5>배송정보</h5>
        <div className="d-flex widthFull gap-3 mt-3 mobile-order-info">
          <div className="d-flex align-items-center widthHalf">
            <p className="ordererInfoTitle">수취인</p>
            <input
              name="receiverName"
              onChange={inputDeliveryInfo}
              value={deliveryInfo?.receiverName}
              className="orderInfoModalInput"
            />
          </div>
          <div className="d-flex align-items-center widthHalf">
            <p className="ordererInfoTitle">전화번호</p>
            <input
              value={deliveryInfo?.receiverContactTelNo}
              name="receiverContactTelNo"
              className="orderInfoModalInput"
              onChange={inputDeliveryInfo}
            />
          </div>
        </div>
        <div className="d-flex widthFull mt-3 mobile-order-info">
          <div className="d-flex align-items-center widthHalf">
            <p className="ordererInfoTitle">배송주소</p>
            <input
              value={deliveryInfo?.receiverZipCode}
              name="receiverZipCode"
              className="orderInfoModalInput"
              onChange={inputDeliveryInfo}
            />
          </div>
        </div>
        <div className="d-flex widthFull mt-3 ">
          <div className="d-flex align-items-center widthFull">
            <p className="ordererInfoTitle2"></p>
            <input
              value={deliveryInfo?.receiverAddress}
              name="receiverAddress"
              className="orderInfoModalInput"
              onChange={inputDeliveryInfo}
            />
          </div>
        </div>
        <OrderInfoPcc
          setIsCheckPcc={setIsCheckPcc}
          deliveryInfo={deliveryInfo!}
          inputDeliveryInfo={inputDeliveryInfo}
        />
      </div>
    </>
  );
};
