import { OrdermanageSerachingValue } from "@/interface/orderManageInterface";
import { useCallback } from "react";
import { ORDER_SEARCHING_WAY, ORDER_STATUS } from "@/common/const/OrderStatus";

interface Props {
  searchValue: Omit<
    OrdermanageSerachingValue,
    "page" | "startDate" | "endDate" | "row"
  >;
  setSearchValue: React.Dispatch<
    React.SetStateAction<
      Omit<OrdermanageSerachingValue, "row" | "startDate" | "endDate" | "page">
    >
  >;
}

export const OrderSearchForm = ({ searchValue, setSearchValue }: Props) => {
  const selectSearchingValue = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const { name, value } = e.target;
      if ((name === "site" || name === "market") && value === "") {
        setSearchValue((prev) => ({
          ...prev,
          [name]: null,
        }));
      } else {
        setSearchValue((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    },
    [setSearchValue]
  );

  return (
    <div className="widthHalf d-flex gap-3 moblie-orderSearchboard">
      <div className="widthFull">
        <label htmlFor="productName" className="searchingTableFont">
          오픈마켓
        </label>
        <div className="state-selection widthFull">
          <select
            value={searchValue.market}
            onChange={selectSearchingValue}
            name="market"
            className="form-select searchWaySelect searchingFormHeight"
          >
            <option value="">전체마켓보기</option>
            <option value="SmartStore">스마트스토어</option>
            <option value="Coupang">쿠팡</option>
            <option value="Auction">옥션</option>
            <option value="Gmarket">지마켓</option>
            <option value="Elevenst">11번가</option>
          </select>
        </div>
        <label htmlFor="productName" className="searchingTableFont">
          검색방법
        </label>
        <div className="state-selection widthFull">
          <select
            value={searchValue.type}
            onChange={selectSearchingValue}
            name="type"
            className="form-select searchWaySelect searchingFormHeight"
          >
            <option value="">검색방법을 선택해주세요</option>
            {ORDER_SEARCHING_WAY.map((item) => (
              <option key={item.id} value={item.value}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
        <label className="searchingTableFont">주문상태</label>
        <div className="state-selection widthFull">
          <select
            value={searchValue.status}
            onChange={selectSearchingValue}
            name="status"
            className="form-select searchWaySelect searchingFormHeight"
          >
            <option value="">검색방법을 선택해주세요</option>
            {ORDER_STATUS.map((item) => (
              <option key={item.id} value={item.value}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="widthFull">
        <div className="widthFull">
          <label htmlFor="productName" className="searchingTableFont">
            수집마켓
          </label>
          <div className="state-selection widthFull">
            <select
              value={searchValue.site!}
              onChange={selectSearchingValue}
              name="site"
              className="form-select searchWaySelect searchingFormHeight"
            >
              <option value="">전체소싱사이트</option>
              <option value="AliExpress">알리익스프레스</option>
              <option value="Taobao">타오바오</option>
              {/* FIXME 1688 VVIC COSTCO value 추후 확인필요!!!!! */}
              <option value="1688">1688</option>
              <option value="Vvic">VVIC</option>
              <option value="Costco">코스트코</option>
            </select>
          </div>
          <label htmlFor="productName" className="searchingTableFont">
            검색어
          </label>
          <div className="state-selection widthFull">
            <input
              type="text"
              className="form-control searchingFormHeight"
              onChange={selectSearchingValue}
              placeholder="검색어를 입력하세요."
              name="value"
              value={searchValue.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
