import { useState } from "react";
import { CollectValue, CollectWay } from "../../../interface/collectInterface";
import { CostcoSearchingBoard } from "./component/CostcoSearchingBoard";

interface Props {
  COLLECT_VALUE_DEFAULT: CollectValue;
}

export const CollectCostco = ({ COLLECT_VALUE_DEFAULT }: Props) => {
  const [collectValue, setColletValue] = useState<CollectValue>(
    COLLECT_VALUE_DEFAULT
  );
  const inputCollectValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setColletValue({
      ...collectValue,
      [name]: value,
    });
  };

  const resetValue = () => {
    setColletValue(COLLECT_VALUE_DEFAULT);
  };

  return (
    <>
      <CostcoSearchingBoard
        setColletValue={setColletValue}
        inputCollectValue={inputCollectValue}
        collectValue={collectValue}
        resetValue={resetValue}
      />
    </>
  );
};
