import useToggle from "@/hooks/useToggle";
import { UploadSummary } from "@/interface/uploadinterface";
import { UploadEditDefaultInfoModal } from "../../modal/UploadEditDefaultInfoModal";

interface Props {
  item: UploadSummary;
}

export const ItemFunctionEditDefault = ({ item }: Props) => {
  const { state: isEditDefaultModal, handleToggle: handleEditDefaultModal } =
    useToggle();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <button
        onClick={handleEditDefaultModal}
        className="cancel-btn save-btn reset-btn name-set-btn basic-btn"
      >
        속성
      </button>
      {isEditDefaultModal && (
        <UploadEditDefaultInfoModal
          closeModal={handleEditDefaultModal}
          item={item}
        />
      )}
    </div>
  );
};
