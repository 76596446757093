import { UseMutationResult } from "react-query";
import { ItemFunctionEditDefault } from "./ItemFunctionEditDefault";
import { ItemFunctionEditDetailPage } from "./ItemFunctionEditDetailPage";
import { ItemFunctionEditOption } from "./ItemFunctionEditOption";
import { ItemFunctionUploadLog } from "./ItemFunctionUploadLog";
import { UploadSummary } from "@/interface/uploadinterface";

interface Props {
  item: UploadSummary;
  isSelect: boolean;
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
}

export const ItemFunction = ({
  item,
  isSelect,
  collectProductSummary,
}: Props) => {
  return (
    <td>
      <div className={`collect-inner-1 ${isSelect && "selectedItem"}`}>
        <div>
          <div className="d-flex align-items-center gap-2 mb-2">
            <ItemFunctionEditDefault item={item} />
            <ItemFunctionEditOption
              item={item}
              collectProductSummary={collectProductSummary}
            />
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            className="d-flex align-items-center gap-2 mb-2"
          >
            <ItemFunctionEditDetailPage item={item} />
            <ItemFunctionUploadLog item={item} />
          </div>
        </div>
      </div>
    </td>
  );
};
