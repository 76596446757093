import { QuestionButton } from "@/components/Button/QuestionButton";
import { CollectValue } from "@/interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectWay: string;
}

export const TaobaoSearchingUrlInput = ({
  collectValue,
  inputCollectValue,
  collectWay,
}: Props) => {
  return (
    <div className="product-label product-label-1 col-md-9 col-12">
      <div className="d-flex align-items-center gap-3 mb-2">
        <label htmlFor="product-name" className="paddingZero">
          {collectWay === "multi" ? "상품목록URL" : "상품단일URL"}
        </label>
        <QuestionButton openId="66755bbd604d73de56fa56a4" />
      </div>
      <input
        type="text"
        className="form-control"
        value={collectValue.url}
        name="url"
        onChange={inputCollectValue}
        placeholder="URL 주소를 입력해주세요"
      />
    </div>
  );
};
