import { QuestionMarkIcon } from "@/components/svg/QuestionMarkIcon";
import { DefaultSetting } from "./DefaultSetting";
import { AdditionalSetting } from "./AdditionalSetting";
import { PriceType } from "@/interface/uploadinterface";
import { useTooltip } from "@/hooks/useTooltip";
import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";

interface Props {
  price: PriceType;
  setPrice: React.Dispatch<React.SetStateAction<PriceType>>;
  isFreeDelivery: boolean;
  setIsFreeDelivery: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PriceSetComponent = ({
  price,
  setPrice,
  isFreeDelivery,
  setIsFreeDelivery,
}: Props) => {
  const [isTooltip, hover, leave] = useTooltip();
  const clickFreeDelivery = () => {
    if (isFreeDelivery) setIsFreeDelivery(false);
    if (!isFreeDelivery) setIsFreeDelivery(true);
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="d-flex align-items-center gap-3 relative">
          <p className="margin0 weight500 navy text18">공식 설정</p>
          <QuestionMarkIcon
            onMouseEnter={hover}
            onMouseLeave={leave}
            color="#39415c"
          />
          {
            <GlobalTooltip
              isVisible={isTooltip}
              style={{
                top: "-100px",
              }}
            >
              <p className="margin0">
                오픈마켓에 판매등록할 상품의 판매가를 설정하는 공식입니다.
                <br /> ex{")"} <br />· 35% 마진 = 원가격 X 1.35 <br />· 2배 마진
                = 원가격 X 2
              </p>
            </GlobalTooltip>
          }
        </div>
        <div className="weight500 text12 grey d-flex gap-3">
          <div className="d-flex align-items-center gap-1">
            <div
              className="price-set-check relative"
              onClick={clickFreeDelivery}
            >
              <input readOnly type="checkbox" checked={isFreeDelivery} />
              <label></label>
            </div>
            <p className="margin0 weight500 text14 black">무료배송</p>
          </div>
          <div className="d-flex align-items-center gap-1">
            <div className="price-set-check2 relative">
              <input readOnly type="checkbox" checked />
              <label></label>
            </div>
            <p className="margin0 weight500 text14 black">
              오기입 배송비 최적화
            </p>
          </div>
        </div>
      </div>
      <DefaultSetting
        setPrice={setPrice}
        price={price}
        isFreeDelivery={isFreeDelivery}
      />
      <AdditionalSetting setPrice={setPrice} price={price} />
    </div>
  );
};
