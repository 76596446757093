import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";
import { useTooltip } from "@/hooks/useTooltip";
import { EditProductTitleValueType } from "@/interface/productmanageinterface";

interface Props {
  setProductTitleValue: React.Dispatch<
    React.SetStateAction<EditProductTitleValueType[]>
  >;
  productTitleValue: EditProductTitleValueType[];
  setSettingKeywordValue: React.Dispatch<
    React.SetStateAction<{
      forwardKeyword: string;
      backKeyword: string;
    }>
  >;

  settingKeyWordValue: {
    forwardKeyword: string;
    backKeyword: string;
  };
}

export const EditProductKeyWordSetting = ({
  settingKeyWordValue,
  setSettingKeywordValue,
  setProductTitleValue,
  productTitleValue,
}: Props) => {
  const [isForwardTooltip, enterForward, leaveForward] = useTooltip();
  const [isBackTooltip, enterBack, leaveBack] = useTooltip();

  const clickApplyKeyWord = () => {
    const editedProductName = productTitleValue.map((item) => {
      return {
        ...item,
        title: `${settingKeyWordValue.forwardKeyword} ${item.title} ${settingKeyWordValue.backKeyword}`,
      };
    });
    setProductTitleValue(editedProductName);
  };

  const inputSettingKeyWordValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettingKeywordValue({
      ...settingKeyWordValue,
      [name]: value,
    });
  };

  return (
    <div className="d-flex align-items-end gap-4">
      <p className="productNameModalSubtitle oneLine width20">글자 추가 설정</p>
      <div className="widthFull d-flex  align-items-end justify-content-between">
        <div className="product-label width40 relative">
          <GlobalTooltip isVisible={isForwardTooltip as boolean}>
            상품명 맨앞에 글자를 추가합니다.
          </GlobalTooltip>
          <label
            onMouseEnter={enterForward as () => void}
            onMouseLeave={leaveForward as () => void}
            htmlFor="product-name35"
          >
            상품명 앞글자
          </label>
          <input
            onChange={inputSettingKeyWordValue}
            value={settingKeyWordValue.forwardKeyword}
            name="forwardKeyword"
            type="text"
            className="form-control"
            placeholder="키워드를 입력"
          />
        </div>
        <div className="product-label width40 relative">
          <GlobalTooltip isVisible={isBackTooltip as boolean}>
            상품명 맨뒤에 글자를 추가합니다.
          </GlobalTooltip>
          <label
            onMouseEnter={enterBack as () => void}
            onMouseLeave={leaveBack as () => void}
            htmlFor="product-name35"
          >
            상품명 뒤글자
          </label>
          <input
            onChange={inputSettingKeyWordValue}
            value={settingKeyWordValue.backKeyword}
            name="backKeyword"
            type="text"
            className="form-control"
            placeholder="키워드를 입력"
          />
        </div>
        <div className="">
          <button
            onClick={clickApplyKeyWord}
            className="cancel-btn save-btn correction-btn apply-btn1"
          >
            적용
          </button>
        </div>
      </div>
    </div>
  );
};
