import { FourSquareIcon } from "@/components/svg/FourSquareIcon";

interface Props {
  collectRow: (row: number) => void;
}

export const SelectTableRow = ({ collectRow }: Props) => {
  return (
    <div className="state-selection total-select">
      <FourSquareIcon />
      <select
        onChange={(e) => collectRow(Number(e.target.value))}
        name="row"
        className="form-select"
      >
        <option value={10}>10개</option>
        <option value={30}>30개</option>
        <option value={50}>50개</option>
        <option value={100}>100개</option>
      </select>
    </div>
  );
};
