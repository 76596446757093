import PaymentCoin from "../PaymentCoin/PaymentCoin";
import { Promote } from "../PaymentCoin/Promote";

export const CoinTab = () => {
  return (
    <>
      <PaymentCoin />
      <Promote />
    </>
  );
};
