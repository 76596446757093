interface Props {
  isSelect?: boolean;
}

export const CollectProductIcon = ({ isSelect }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="그룹 10627">
        <path
          d="M14.485 4h3.1v5.3l-1.073-.75a.833.833 0 0 0-.955 0l-1.072.75zm-1.667 0v6.9a.833.833 0 0 0 1.311.683l1.906-1.333 1.906 1.333a.833.833 0 0 0 1.311-.683V4zM22.7 19.833a.833.833 0 0 1-.833.833h-3.194a.833.833 0 0 1 0-1.667h3.195a.833.833 0 0 1 .832.834zm-.832-3.333a.833.833 0 1 1 0 1.667h-3.195a.833.833 0 0 1 0-1.667z"
          transform="translate(-1.035 1)"
          fill={isSelect ? "#00b8ba" : "#fff"}
        />
        <path
          data-name="패스 10503"
          d="M17.189 20.252H3.311A3.066 3.066 0 0 1 .25 17.19V3.313A3.065 3.065 0 0 1 3.311.252h13.878a3.065 3.065 0 0 1 3.061 3.061V17.19a3.066 3.066 0 0 1-3.061 3.062zM3.311 1.7A1.613 1.613 0 0 0 1.7 3.313V17.19a1.614 1.614 0 0 0 1.611 1.61h13.878a1.614 1.614 0 0 0 1.611-1.61V3.313A1.613 1.613 0 0 0 17.189 1.7z"
          transform="translate(4.75 4.748)"
          fill={isSelect ? "#00b8ba" : "#fff"}
        />
      </g>
      <path data-name="사각형 3778" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
