import { useQuery } from "react-query";
import { getPayLog } from "@/apis/payApis";

interface Props {
  page?: number;
  limit?: number;
}

const usePayLogs = ({ page = 1, limit = 3 }: Props) => {
  const { data } = useQuery(["paylog", page, limit], () =>
    getPayLog(page, limit)
  );

  if (data) {
    return {
      meta: {
        nowPage: data.data.nowPage,
        totalPage: data.data.totalPage,
        totalCount: data.data.totalCount,
      },
      payLogs: data.data.data,
    };
  } else
    return {
      meta: null,
      payLogs: null,
    };
};

export default usePayLogs;
