export const ReverseLeftRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"36"}
      height={"36"}
      viewBox="0 0 36 36"
    >
      <defs>
        <style>
          {`.cls-1,.cls-2,.cls-3 {fill: none;}.cls-1,.cls-2 {stroke: #39415c;stroke-linecap: round;stroke-width: 2px;}.cls-1 {stroke-linejoin: round;}`}
        </style>
      </defs>
      <g
        id="그룹_11486"
        data-name="그룹 11486"
        transform="translate(-1243 -847)"
      >
        <path
          id="패스_10547"
          data-name="패스 10547"
          className="cls-1"
          d="M1254.335,857.713l-6,6,6,6"
          transform="translate(0.665 1.287)"
        />
        <path
          id="패스_10549"
          data-name="패스 10549"
          className="cls-1"
          d="M1248.335,857.713l6,6-6,6"
          transform="translate(18.665 1.287)"
        />
        <path
          id="패스_10548"
          data-name="패스 10548"
          className="cls-2"
          d="M148.409,16V30"
          transform="translate(1112.591 842)"
        />
      </g>
      <rect
        id="사각형_3980"
        data-name="사각형 3980"
        className="cls-3"
        width="36"
        height="36"
      />
    </svg>
  );
};
