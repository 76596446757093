import { GreenMiddleButton } from "@/components/Button/allttamButton/GreenMiddleButton";
import { GreyMiddleButton } from "@/components/Button/allttamButton/GreyMiddleButton";
import { Input } from "@/components/Input/Input";
import { ModalBody } from "@/components/ModalLayout/ModalBody";
import { ModalHead } from "@/components/ModalLayout/ModalHead";
import ModalContent from "@/components/shared/modal/ModalContent";
import useFetch from "@/hooks/useFetch";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import useModal from "@/hooks/useModal";
import axiosInstance from "@/utils/axiosInstance";
import { ChangeEvent, useState } from "react";

const UploadAccountModal = () => {
  const { closeModal } = useModal();
  const openWindow = useInfoWindow();
  const [userInfo] = useFetch("userInfo", "Auth/user");

  const [vvicLoginValue, setVvicLoginValue] = useState({
    email: "",
    password: "",
  });

  const handleLoginValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVvicLoginValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitForm = async () => {
    const res = await axiosInstance.post(
      `/Product/SetSourcingAccount?userId=${userInfo.username}`,
      {
        sourcingSite: "VVIC",
        account: vvicLoginValue.email,
        password: vvicLoginValue.password,
      }
    );

    if (res.status === 200) {
      closeModal();
      openWindow("계정등록이 등록되었습니다.");
    }
  };

  return (
    <ModalContent>
      <ModalHead clickClose={closeModal}>VVIC 계정등록</ModalHead>
      <ModalBody>
        <p className="text14 weight500 gray margin0">
          vvic 계정등록이 필요합니다.
        </p>
        <div className="d-flex flex-column gap-3 mt-4">
          <p className="text14 weight500 gray margin0">vvic 이메일</p>
          <Input
            value={vvicLoginValue.email}
            name="email"
            onChange={handleLoginValue}
          />
          <p className="text14 weight500 gray margin0">패스워드</p>
          <Input
            value={vvicLoginValue.password}
            name="password"
            type="password"
            onChange={handleLoginValue}
          />
        </div>
        <div>
          <p className="weight500 text14 mt-3">주의사항</p>
          <a
            className="margin0 weight500 text12 grey"
            href="https://www.vvic.com/login.html"
            target="_blank"
          >
            vvic로그인 사이트
          </a>
          <p className="margin0 weight500 text12 grey">
            위의 링크에서 왼쪽 하단의 아이디 비밀번호로 로그인하기에서 로그인
            가능한 아이디와 비밀번호를 입력해주세요
          </p>
        </div>
        <div className="d-flex justify-content-center gap-3 mt-5">
          <GreyMiddleButton onClick={closeModal}>닫기</GreyMiddleButton>
          <GreenMiddleButton onClick={submitForm}>등록</GreenMiddleButton>
        </div>
      </ModalBody>
    </ModalContent>
  );
};

export default UploadAccountModal;
