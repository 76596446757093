export const PlusMinusIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 7.99805H8V10.998C8 11.2633 7.89464 11.5176 7.70711 11.7052C7.51957 11.8927 7.26522 11.998 7 11.998C6.73478 11.998 6.48043 11.8927 6.29289 11.7052C6.10536 11.5176 6 11.2633 6 10.998V7.99805H3C2.73478 7.99805 2.48043 7.89269 2.29289 7.70515C2.10536 7.51762 2 7.26326 2 6.99805C2 6.73283 2.10536 6.47848 2.29289 6.29094C2.48043 6.1034 2.73478 5.99805 3 5.99805H6V2.99805C6 2.73283 6.10536 2.47848 6.29289 2.29094C6.48043 2.1034 6.73478 1.99805 7 1.99805C7.26522 1.99805 7.51957 2.1034 7.70711 2.29094C7.89464 2.47848 8 2.73283 8 2.99805V5.99805H11C11.2652 5.99805 11.5196 6.1034 11.7071 6.29094C11.8946 6.47848 12 6.73283 12 6.99805C12 7.26326 11.8946 7.51762 11.7071 7.70515C11.5196 7.89269 11.2652 7.99805 11 7.99805ZM21 17.998H15C14.7348 17.998 14.4804 17.8927 14.2929 17.7052C14.1054 17.5176 14 17.2633 14 16.998C14 16.7328 14.1054 16.4785 14.2929 16.2909C14.4804 16.1034 14.7348 15.998 15 15.998H21C21.2652 15.998 21.5196 16.1034 21.7071 16.2909C21.8946 16.4785 22 16.7328 22 16.998C22 17.2633 21.8946 17.5176 21.7071 17.7052C21.5196 17.8927 21.2652 17.998 21 17.998ZM17.793 4.70705L4.707 17.793C4.51953 17.9806 4.41421 18.2349 4.41421 18.5C4.41421 18.7652 4.51953 19.0195 4.707 19.207L4.793 19.293C4.98053 19.4805 5.23484 19.5858 5.5 19.5858C5.76516 19.5858 6.01947 19.4805 6.207 19.293L19.293 6.20705C19.4805 6.01952 19.5858 5.76521 19.5858 5.50005C19.5858 5.23488 19.4805 4.98057 19.293 4.79305L19.207 4.70705C19.0195 4.51958 18.7652 4.41426 18.5 4.41426C18.2348 4.41426 17.9805 4.51958 17.793 4.70705Z"
        fill="#596380"
      />
    </svg>
  );
};
