import { paymentCoin, subscribePlan } from "@/apis/payApis";
import { GreenMiddleButton } from "@/components/Button/allttamButton/GreenMiddleButton";
import { ModalHead } from "@/components/ModalLayout/ModalHead";
import { Spinner } from "@/components/Spinner/Spinner";
import ModalContent from "@/components/shared/modal/ModalContent";
import useCardInfo from "@/hooks/useCardInfo";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import useModal from "@/hooks/useModal";
import { useState } from "react";
import { ModalBody } from "react-bootstrap";

interface Props {
  planName: string;
  slot?: string;
  price: number;
  goodsName: string;
  type: "PLAN" | "COIN";
}

const CheckPayModal = ({ planName, slot, price, goodsName, type }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const openWindow = useInfoWindow();
  const { closeModal } = useModal();
  const { data } = useCardInfo();

  const clickSubscribe = async () => {
    setIsLoading(true);
    if (type === "PLAN") {
      const res = await subscribePlan(planName, Number(slot) - 5);
      
      if (res.status === "Success") {
        closeModal();
        openWindow("구독이 완료되었습니다.");
      }
    }
    if (type === "COIN") {
      const res = await paymentCoin(planName as "C1" | "C2");
      if (res.status === "Success") {
        closeModal();
        openWindow(`구독을 완료했습니다.\n${goodsName}`);
      }
    }

    setIsLoading(false);
  };

  return (
    <ModalContent>
      <ModalHead clickClose={closeModal}>결제확인</ModalHead>
      <ModalBody>
        <div className="text-center">
          <p className="weight500">
            <span className="text18 textGreen">{goodsName}</span>
            {slot && (
              <span>
                와 오픈마켓 슬롯{" "}
                <span className="text18 textGreen">{slot}</span>개
              </span>
            )}
          </p>
          <p className="weight500">
            Vat포함 총 결제금액{" "}
            <span className="text18 textYellow">{price.toLocaleString()}</span>
            원이
          </p>
          <p className="weight500">등록하신 {data?.data} 카드로 결제됩니다.</p>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <GreenMiddleButton onClick={clickSubscribe}>결제</GreenMiddleButton>
        </div>
        {isLoading && (
          <div className="full-screen-loader d-flex justify-content-center align-items-center">
            <div className="white-box width20 py-4">
              <Spinner />
              <p className="weight500 text14 text-center mt-3 margin0">
                결제 중입니다. 잠시만 기다려주세요
              </p>
            </div>
          </div>
        )}
      </ModalBody>
    </ModalContent>
  );
};

export default CheckPayModal;
