import { Spinner } from "@/components/Spinner/Spinner";
import { UploadNoneResult } from "@/components/Upload/Uploadresulttable/UploadNoneResult";
import { ResultItem } from "@/interface/orderManageInterface";
import { OrderManageTableHead } from "../component/OrderManageTableHead";
import { OrderManageTableItem } from "../component/OrderManageTableItem";
import { OrderManageTableItemMobile } from "../component/mobile/OrderManageTableItemMobile";
import { useRecoilValue } from "recoil";
import { isDeskTop } from "@/atom/atom";

interface Props {
  orderList: ResultItem[] | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  selectedOrder: ResultItem[];
  isLoading: boolean;
  setOrderList: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  refetchNowValue: () => void;
}

export const OrderManageTable = ({
  orderList = [],
  selectedOrder,
  setSelectedOrder,
  isLoading,
  setOrderList,
  refetchNowValue,
}: Props) => {
  const isDesktop = useRecoilValue(isDeskTop);

  return (
    <div className="row total-table-col collect-market">
      <div className="col-12">
        <div className="heading-two heading-three heading-five heading-five2">
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border={0}
            align="center"
          >
            {isDesktop && (
              <OrderManageTableHead
                selectedOrder={selectedOrder}
                orderList={orderList}
                setSelectedOrder={setSelectedOrder}
              />
            )}

            <tbody className="body-one mobile-table-body">
              {isLoading && <TableSpinner />}
              {!orderList?.length && orderList == null ? (
                <UploadNoneResult colSpan={8} />
              ) : isDesktop ? (
                orderList.map((item, idx) => (
                  <OrderManageTableItem
                    refetchNowValue={refetchNowValue}
                    setOrderList={setOrderList}
                    idx={idx}
                    orderList={orderList}
                    key={item.idx}
                    item={item}
                    setSelectedOrder={setSelectedOrder}
                    selectedOrder={selectedOrder}
                  />
                ))
              ) : (
                orderList.map((item, idx) => (
                  <OrderManageTableItemMobile
                    refetchNowValue={refetchNowValue}
                    setOrderList={setOrderList}
                    idx={idx}
                    orderList={orderList}
                    key={item.idx}
                    item={item}
                    setSelectedOrder={setSelectedOrder}
                    selectedOrder={selectedOrder}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const TableSpinner = () => {
  return (
    <tr>
      <td className="" colSpan={8}>
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      </td>
    </tr>
  );
};
