export const comparePriority = (value: string): number => {
  switch (value) {
    case "낮음":
      return 0;
    case "중간":
      return 1;
    case "높음":
      return 2;
    default:
      return -1; // 잘못된 값은 가장 먼저 나오도록 함
  }
};
