import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";
import useFetch from "@/hooks/useFetch";
import { useTooltip } from "@/hooks/useTooltip";
import { PriceType } from "@/interface/uploadinterface";
import { useEffect, useState } from "react";
import { madePercent } from "../../function/madePercent";

interface Props {
  price: PriceType;
  setPrice: React.Dispatch<React.SetStateAction<PriceType>>;
  isFreeDelivery: boolean;
}

export const DefaultSetting = ({ price, setPrice, isFreeDelivery }: Props) => {
  const [isExchangeTooltip, enterExchange, leavaExchange] = useTooltip();
  const [isReturnTooltip, enterReturn, leaveReturn] = useTooltip();
  const [isDeliveryTooltip, enterDelivery, leaveDelivery] = useTooltip();
  const [getPrice] = useFetch("price", "/Market/GetPrice");

  const inputPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPrice({
      ...price,
      [name]: parseFloat(value) ? parseFloat(value) : undefined,
    });
  };
  const onBlurPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPrice({
      ...price,
      [name]: parseFloat(value) ? Math.round(parseFloat(value)) : undefined,
    });
  };

  return (
    <div className="d-flex">
      <div className="width20 mt-3">
        <p className="table-bg text-center grey weight600 text14 rounded-sm py-2">
          기본 판매가 공식
        </p>
        <p className="text12 weight500 text12 grey margin0 text-center">
          원가*환율*(1+기본 마진율)+추가마진 <br />
          <br />
          <span className="mt-3">※ 스마트스토어 기준</span>
        </p>
      </div>
      <div className="d-flex flex-col gap-3 width80 mt-3 ml-3">
        <div className="widthFull table-bg rounded-sm py-2 d-flex relative">
          <p className="width20 margin0 text-center grey weight600 text14">
            기본 마진율(%)
          </p>
          <p className="width20 margin0 text-center grey weight600 text14">
            추가 마진
          </p>
          <div className="width20 margin0 text-center grey weight600 text14">
            <p
              className="margin0"
              onMouseEnter={enterDelivery}
              onMouseLeave={leaveDelivery}
            >
              기본배송비
            </p>
            <GlobalTooltip
              isVisible={isDeliveryTooltip}
              style={{
                top: "-180px",
                right: 0,
              }}
            >
              <p className="margin0 textStart">
                오픈마켓마다 유료/무료배송비에 따라 상품 판매가/배송비를
                기준으로 반품배송비/교
                <br />
                환배송비의 상·하한선이 다르게 정해짐으로 주의해주세요. 오픈마켓
                가격 정책에 부합
                <br />
                하지 않을 때에는 등록이 실패(반려) 될 수 있습니다.
                <br />
                <br />
                <br />
                · 유료배송비의 교환/반품배송비는 유료배송비의 100%를 초과할 수
                없습니다. <br />
                · 무료배송비의 교환/반품배송비는 상품 판매가의 100%(쿠팡은 상품
                판매가의 50%)
                <br />를 초과할 수 없습니다.
              </p>
            </GlobalTooltip>
          </div>
          <div className="width20 margin0 text-center grey weight600 text14">
            <p
              className="margin0"
              onMouseLeave={leaveReturn}
              onMouseEnter={enterReturn}
            >
              반품배송비
            </p>
            <GlobalTooltip
              isVisible={isReturnTooltip}
              style={{
                top: "-70px",
                right: 0,
              }}
            >
              <p className="margin0 textStart">
                무료배송비의 교환/반품배송비는 상품 판매가의 50% 를 초과할
                <br /> 수 없습니다. 초과하여 판매 등록 시 실패(반려)되며,
                [초도반품배송비 + 반품배송비]
                <br /> 에 대한 교환배송비의 에러메시지를 확인할 수 있습니다.
              </p>
            </GlobalTooltip>
          </div>
          <div className="width20 margin0 text-center grey weight600 text14">
            <p
              className="margin0"
              onMouseEnter={enterExchange}
              onMouseLeave={leavaExchange}
            >
              교환배송비
            </p>
            <GlobalTooltip
              isVisible={isExchangeTooltip}
              style={{
                right: 0,
              }}
            >
              교환배송비는 반품배송비 입력값의 2배(200%)로 판매등록됩니다.
            </GlobalTooltip>
          </div>
        </div>
        <div className="widthFull d-flex gap-2">
          {/* 기본마진률 */}

          <input
            value={Math.round(price?.mainValue)}
            name="mainValue"
            onChange={inputPrice}
            onBlur={onBlurPrice}
            className="width20 price-setting-input"
            type="number"
          />
          {/* 추가 마진 */}
          <input
            onChange={inputPrice}
            defaultValue={getPrice?.subValue ? getPrice?.subValue : 0}
            onBlur={onBlurPrice}
            value={price?.subValue}
            name="subValue"
            className="width20 price-setting-input"
            type="number"
          />
          {/* 기본 배송비 */}
          <input
            onChange={inputPrice}
            defaultValue={getPrice?.deliveryBasicPrice}
            value={isFreeDelivery ? 0 : price.deliveryBasicPrice}
            name="deliveryBasicPrice"
            className="width20 price-setting-input"
            type="number"
            disabled={isFreeDelivery}
          />
          {/* 반품배송비 */}
          <input
            onChange={inputPrice}
            className="width20 price-setting-input"
            type="number"
            defaultValue={getPrice?.deliveryReturnPrice}
            name="deliveryReturnPrice"
          />
          {/* 교환배송비 */}
          <input
            name="deliveryExchangePrice"
            readOnly
            value={price.deliveryReturnPrice * 2}
            className="width20 price-setting-input"
            type="number"
            disabled
          />
        </div>
      </div>
    </div>
  );
};
