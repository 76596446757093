import { ReactCropperElement } from "react-cropper";
import lotateLeftIcon from "@/assets/images/lotateLeft.svg";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const RotateLeftButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleRotateLeft = (): void => {
    cropperRef.current?.cropper?.rotate(-90);
  };

  return (
    <button
      onClick={handleRotateLeft}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("좌로 회전")}
    >
      <img src={lotateLeftIcon} />
    </button>
  );
};
