interface Props {
  color?: string;
}

export const MinusIcon = ({ color }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 20 20"
    >
      {/* <path
        data-name="패스 10534"
        d="M-15249.48-10036.957v8.334"
        transform="translate(15259.48 10042.79)"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5px"
        fill="none"
      /> */}
      <path
        data-name="패스 10535"
        d="M0 0v8.334"
        transform="rotate(90 2.083 12.084)"
        stroke={color ? color : "#fff"}
        strokeLinecap="round"
        strokeWidth="1.5px"
        fill="none"
      />
    </svg>
  );
};
