import { axios } from "../axioxFetch";
import chromeAPI from "./chromeAPI";

const getMarketLoginResult = async (account, market) => {
  switch (market) {
    case "SmartStore":
      return await checkSmartStoreLogin(account);
    case "Auction":
      return checkAuctionLogin(account);
    case "Gmarket":
      return checkGmarketLogin(account);
    case "Coupang":
      return checkCoupangLogin(account);
    case "Elevenst":
      return checkElevenstLogin(account);
  }
};

export const checkLoginAllOnce = async (account, market) => {
  if (!account) {
    return;
  }

  try {
    if (typeof window !== "undefined") {
      if (window.innerWidth > 768) {
        const isExCheck = await chromeAPI.exCheck();
        if (isExCheck.responseMsg.code !== 200) {
          alert("확장프로그램을 확인해주세요.");
          return;
        }
      }
    }

    const loginResult = await getMarketLoginResult(account, market);

    return loginResult;
  } catch {
    return;
  }
};

const checkSmartStoreLogin = async (account) => {
  const loginResult = await chromeAPI.NaverLoginCheck(account);
  if (loginResult.responseMsg.code === 200) {
    const result = await axios.post(
      `/MarketInfo/CheckSmartStoreLoginID?selectedAccount=${account}`,
      loginResult.responseMsg.data
    );
    if (result.data === "[스마트스토어] 로그인 성공") {
      return result;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkAuctionLogin = async (account) => {
  const loginResult = await chromeAPI.AuctionLoginCheck(account);
  if (loginResult.responseMsg.code === 200) {
    const result = await axios.post(
      `/MarketInfo/CheckAuctionLoginID?selectedAccount=${account}`,
      loginResult.responseMsg.data
    );
    if (result.data === "[옥션] 로그인 성공") {
      return result;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkGmarketLogin = async (account) => {
  const loginResult = await chromeAPI.AuctionLoginCheck(account);
  if (loginResult.responseMsg.code === 200) {
    const result = await axios.post(
      `/MarketInfo/CheckGmarketLoginID?selectedAccount=${account}`,
      loginResult.responseMsg.data
    );
    if (result.data === "[지마켓] 로그인 성공") {
      const loginId = await axios.post(
        "MarketInfo/GetGmarketLoginId",
        loginResult.responseMsg.data
      );
      return { id: loginId.data, data: loginResult.responseMsg.data };
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkCoupangLogin = async (account) => {
  const result = await axios.post("/MarketInfo/CheckCoupangLogin", account);

  if (result.status === 200) {
    return result;
  } else {
    return false;
  }
};

const checkElevenstLogin = async (account) => {
  const result = await axios.post("/MarketInfo/CheckElevenstLogin", account);
  if (result.status === 200) {
    return result;
  } else {
    return false;
  }
};
