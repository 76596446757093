export const ProductManageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 3746" fill="none" d="M0 0h30v30H0z" />
      <g data-name="checklist (1)">
        <path
          className="icon"
          data-name="패스 10479"
          d="M20.3 24.579H5.751a.854.854 0 0 1-.851-.851V8.451a.854.854 0 0 1 .851-.851h3.66a.851.851 0 0 1 0 1.7H6.56v13.619H19.5V9.3h-2.557a.851.851 0 1 1 0-1.7h3.4a.854.854 0 0 1 .851.851v15.277a.921.921 0 0 1-.894.851z"
          transform="translate(1.951 .421)"
          fill="#fff"
        />
        <path
          className="icon"
          data-name="패스 10480"
          d="M21.888 8.832h-8a.905.905 0 0 1-.638-.3.765.765 0 0 1-.17-.681l.72-3.87a.859.859 0 0 1 .809-.681h6.511a.859.859 0 0 1 .809.681l.723 3.787a.54.54 0 0 1 .043.255.782.782 0 0 1-.807.809zm-6.979-1.7h5.957l-.426-2.213h-5.105z"
          transform="translate(-2.739 2.891)"
          fill="#fff"
        />
        <path
          className="icon"
          data-name="패스 10481"
          d="M23.251 3.309a.854.854 0 0 1-.851-.851V1.351a.851.851 0 1 1 1.7 0V2.5a.845.845 0 0 1-.849.809z"
          transform="translate(-8.102 4.5)"
          fill="#fff"
        />
        <g data-name="그룹 10599">
          <path
            className="icon"
            data-name="패스 10482"
            d="M18.653 29.864a.84.84 0 0 1-.6-.255l-1.7-1.7a.843.843 0 0 1 1.191-1.191l1.106 1.106 2.766-2.766a.843.843 0 1 1 1.191 1.191l-3.319 3.319a.887.887 0 0 1-.635.296z"
            transform="translate(-4.483 -9.46)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
