import { YellowMiddleButton } from "@/components/Button/allttamButton/yellowMiddleButton";
import { useTooltip } from "../../../../../hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";

interface Props {
  clickTranslate: () => Promise<void>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const ImportOhooButton = ({
  clickTranslate,
  mouseHover,
  mouseLeave,
}: Props) => {
  return (
    <YellowMiddleButton
      onClick={clickTranslate}
      onMouseLeave={mouseLeave}
      onMouseEnter={() =>
        mouseHover("이미지 내 텍스트 번역·수정, 이미지 보정하기")
      }
    >
      올땀 스튜디오
    </YellowMiddleButton>
  );
};
