import { useTooltip } from "@/hooks/useTooltip";
import { UploadSummary } from "@/interface/uploadinterface";
import { ProductNameInfoTableItem } from "./ProductNameInfoTableItem";
import { GlobalTooltip } from "@/components/GlobalTooltip/GlobalTooltip";
import { XIcon } from "@/components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { CheckBoxInput } from "@/components/Input/CheckBoxInput";
import { useMemo } from "react";
import { SearchinGreenCircleIcon } from "@/components/svg/SearchingGreenCircleIcon";

interface Props {
  selectedItem: UploadSummary[];
  setProductNameValueList: React.Dispatch<
    React.SetStateAction<
      {
        code: string;
        text: string;
      }[]
    >
  >;
  productNameValueList: {
    code: string;
    text: string;
  }[];
  wordLengthLimit: number;
  setNameSelect: React.Dispatch<React.SetStateAction<string[]>>;
  nameSelect: string[];
}

export const ProductNameInfoTable = ({
  selectedItem,
  setProductNameValueList,
  productNameValueList,
  wordLengthLimit,
  setNameSelect,
  nameSelect,
}: Props) => {
  const [isLengthTooltip, enterLength, leaveLength] = useTooltip();

  const setEraseAllName = () => {
    setProductNameValueList((prev) =>
      prev.map((item) => {
        return {
          ...item,
          text: "",
        };
      })
    );
  };

  const isSelectAll = useMemo(() => {
    return nameSelect.length === selectedItem.length;
  }, [nameSelect]);

  const selectAll = () => {
    if (isSelectAll) setNameSelect([]);
    if (!isSelectAll) setNameSelect(selectedItem.map((item) => item.id));
  };

  return (
    <div className="row total-table-col mt-4">
      <div className="col-12">
        <div className="heading-two heading-three heading-five heading-five1 m-0 relative">
          <GlobalTooltip
            isVisible={isLengthTooltip as boolean}
            style={{ right: "0px", top: "-5rem" }}
          >
            <span className="margin0">
              ‘실제 글자수 / 최대 글자수 ‘ 로 표기됩니다. <br></br>실제 글자수가
              최대 글자수를 넘어서면,<br></br> 실제 글자수의 색상이 빨간색으로
              표기됩니다.
            </span>
          </GlobalTooltip>
          <table width="100%">
            <thead className="head-one">
              <tr>
                <th className="width5">
                  <CheckBoxInput onClick={selectAll} checked={isSelectAll} />
                </th>
                <th className="width10">NO .</th>
                <th className="width10">
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <span>썸네일</span>
                    <SearchinGreenCircleIcon />
                  </div>
                </th>
                <th className="width50">상품명</th>
                <th className="width5">
                  <button
                    onClick={setEraseAllName}
                    className="madebutton paddingZero"
                  >
                    <XIcon />
                  </button>
                </th>
                <th
                  className="width10"
                  onMouseEnter={enterLength as () => void}
                  onMouseLeave={leaveLength as () => void}
                >
                  글자수
                </th>
                <th className="width20">메모</th>
              </tr>
            </thead>
            <tbody className="">
              {selectedItem.map((item, idx) => (
                <ProductNameInfoTableItem
                  nameSelect={nameSelect}
                  setNameSelect={setNameSelect}
                  setProductNameValueList={setProductNameValueList}
                  wordLengthLimit={wordLengthLimit}
                  key={item.id}
                  item={item}
                  idx={idx}
                  productNameValue={productNameValueList[idx].text}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
