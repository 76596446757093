import { CollectValue, CollectWay } from "@/interface/collectInterface";
import { AliSearchingDuplicateSelect } from "./AliSearchingDuplicateSelect";
import { AliSearchingGroupName } from "./AliSearchingGroupName";
import { AliSearchingImgSelect } from "./AliSearchingImgSelect";
import { AliSearchingMemo } from "./AliSearchingMemo";
import { AliSearchingUrl } from "./AliSearchingUrl";
import { SearchingTab } from "@/components/Collect/CollectSearchingTab/SearchingTab";
import { SearchingIcon } from "@/components/svg/SeachingIcon";
import { ResetIcon } from "@/components/svg/ResetIcon";

interface Props {
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectValue: CollectValue;
  clickCollect: (page: number) => Promise<void>;
  clickReset: () => void;
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const AliSearchingBoard = ({
  collectWay,
  setCollectWay,
  inputCollectValue,
  collectValue,
  clickCollect,
  clickReset,
  setCollectValue,
}: Props) => {
  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="tabs-col-btn">
          <SearchingTab
            clickReset={clickReset}
            setCollectWay={setCollectWay}
            collectWay={collectWay}
          />
          <div className="tab-content">
            <div className="first-product-tab serachingBoard shadow">
              <div className="row">
                <AliSearchingGroupName
                  setCollectValue={setCollectValue}
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
                <AliSearchingUrl
                  collectWay={collectWay}
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
              </div>
              <div className="row mt-3">
                <AliSearchingMemo
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                  setCollectValue={setCollectValue}
                />
                <AliSearchingImgSelect
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
                <AliSearchingDuplicateSelect
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
              </div>
              <hr className="hr1 mt-4" />
              <div className="row pt-3 pb-1 align-items-center justify-content-end">
                <div className="col-md-6 col-12">
                  <div className="search-btn d-flex justify-content-end gap-3 align-items-center">
                    <button className="cancel-btn save-btn reset-btn">
                      <ResetIcon />
                      초기화
                    </button>
                    <button
                      onClick={() => clickCollect(1)}
                      className="cancel-btn save-btn"
                    >
                      <SearchingIcon />
                      {collectWay === "one" ? "수집" : "검색"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
