import { useEffect, useMemo, useState } from "react";
import { ResultItem } from "../../../../../interface/orderManageInterface";
import { ItemOrderDate } from "../item/ItemOrderDate";
import { ItemProductInfo } from "../item/ItemProductInfo";
import { ItemProductOrderInfo } from "../item/ItemProductOrderInfo";
import { ItemExpectationRevenueButton } from "../item/ItemExpectationRevenueButton";
import { ItemOrderState } from "../item/ItemOrderState";
import smartStore from "@/assets/images/marketIcon/smartstore.svg";
import auction from "@/assets/images/marketIcon/auction.svg";
import gmarket from "@/assets/images/marketIcon/gmarket.svg";
import coupnag from "@/assets/images/marketIcon/coupang.svg";
import elevenst from "@/assets/images/marketIcon/elevenst.svg";
import { MarketEnglish } from "@/interface/uploadinterface";
import { SubmitButtonComponent } from "@/components/Productmanage/modal/ProductEditModal/component/SubmitButtonComponent";

interface Props {
  item: ResultItem;
  setSelectedOrder: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  selectedOrder: ResultItem[];
  orderList: ResultItem[];
  idx: number;
  setOrderList: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  refetchNowValue: () => void;
}

export const OrderManageTableItemMobile = ({
  idx,
  item,
  selectedOrder,
  setSelectedOrder,
  orderList,
  setOrderList,
  refetchNowValue,
}: Props) => {
  const isSelect = useMemo(
    () => Boolean(selectedOrder.find((order) => order.idx === item.idx)),
    [selectedOrder]
  );

  const selectOrder = () => {
    if (isSelect) {
      const newArr = [...selectedOrder];
      setSelectedOrder(newArr.filter((order) => order.idx !== item.idx));
    } else {
      setSelectedOrder((prev) => [...prev, item]);
    }
  };

  return (
    <tr onClick={selectOrder} className="p-1">
      <td className="width30">
        <div
          className={`collect-inner-1 div-collect p-2 
          ${isSelect && "selectedItem"}`}
        >
          <div className="d-flex gap-2 align-items-center justify-content-around">
            <div className="form-group">
              <input
                onClick={(e) => e.stopPropagation()}
                type="checkbox"
                id="htmlh"
                checked={isSelect}
                readOnly={true}
              />
              <label htmlFor="htmlh"></label>
            </div>

            <img
              src={IMG_INDEX[item.market as MarketEnglish]}
              className="logoRound"
            />
          </div>
        </div>
      </td>
      <ItemProductInfo
        idx={idx}
        item={item}
        isSelect={isSelect}
        orderList={orderList}
        setOrderList={setOrderList}
        selectedOrder={selectedOrder}
        selectOrder={selectOrder}
      />
      <ItemOrderDate
        item={item}
        isSelect={isSelect}
        refetchNowValue={refetchNowValue}
      />
    </tr>
  );
};
const IMG_INDEX = {
  SmartStore: smartStore,
  Coupang: coupnag,
  Elevenst: elevenst,
  Auction: auction,
  Gmarket: gmarket,
};

const URL_INDEX = {
  SmartStore: "https://sell.smartstore.naver.com/#/home/dashboard",
  Coupang: "https://wing.coupang.com/",
  Elevenst: "https://soffice.11st.co.kr/view/main",
  Auction: "https://www.esmplus.com/Home/Home#",
  Gmarket: "https://www.esmplus.com/Home/Home#",
};
