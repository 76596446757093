import { useEffect, useState } from "react";
import { useInput } from "@/hooks/useInput";
import { usePost } from "@/hooks/usePost";
import {
  MarketAccount,
  ProdcutManageResultItem,
} from "@/interface/productmanageinterface";
import { ItemUploadMarketButtonBox } from "./ItemUploadMarketButtonBox";
import { ResultItemAdditionInfo } from "./ResultItemAdditionInfo";
import { ResultItemPriceTag } from "./ResultItemPriceTag";
import { ResultItemTitleMemo } from "./ResultItemTitleMemo";
import { ResultItemTumbnail } from "./ResultItemTumbnail";

interface Props {
  result: ProdcutManageResultItem;
  account: string;
  selectedItem: ProdcutManageResultItem[];
  setSelectedItem: React.Dispatch<
    React.SetStateAction<ProdcutManageResultItem[]>
  >;
  selectedMarket: MarketAccount | undefined;
}

export const ResultTableItemComponent = ({
  result,
  account,
  selectedItem,
  setSelectedItem,
  selectedMarket,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const checkItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setSelectedItem(
        selectedItem.filter((item: any) => item.id !== result.id)
      );
    } else {
      setSelectedItem([...selectedItem, result]);
    }
  };

  useEffect(() => {
    setIsSelect(Boolean(selectedItem.find((item) => item.id === result.id)));
  }, [selectedItem]);

  const selectItem = () => {
    if (isSelect) {
      setSelectedItem(selectedItem.filter((item) => item.id !== result.id));
    } else {
      setSelectedItem((prev) => [...prev, result]);
    }
  };

  const [title, inputTitle] = useInput({
    text: result.title.filter((item) => item.language === "KR")[0]?.text
      ? result.title.filter((item) => item.language === "KR")[0]?.text
      : "",
  });
  const [memo, inputMemo] = useInput({ memo: result.memo });

  const changeMemo = usePost("/Product/ProductMemoEdit", {
    objectId: result.id,
    memo: memo.memo,
  });

  // 에러 체크
  // ToDo api상에서 정상적으로 작동하지 않음.. 확인해야함!!
  // const changeTitle = usePost("/Product/ProductTitleChange", [
  //   {
  //     text: title.text,
  //     code: result.code,
  //   },
  // ]);

  return (
    <tr onClick={selectItem}>
      <td>
        <div
          className={`collect-inner-1 div-collect ${
            isSelect && "selectedItem"
          }`}
        >
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <input
              onClick={selectItem}
              readOnly
              type="checkbox"
              id={result.id}
              checked={isSelect}
            />
            <label htmlFor={result.id}></label>
          </div>
        </div>
      </td>
      <ResultItemTumbnail
        code={result.code}
        isSelect={isSelect}
        url={result.productUrl}
        site={result.site}
      />
      <ResultItemTitleMemo
        isSelect={isSelect}
        result={result}
        title={title}
        inputTitle={inputTitle}
        memo={memo}
        inputMemo={inputMemo}
        changeMemo={changeMemo}
      />
      <ResultItemPriceTag result={result} isSelect={isSelect} />
      <ItemUploadMarketButtonBox
        selectedMarket={selectedMarket}
        result={result.marketProductDetailInfo}
        account={account}
        isSelect={isSelect}
      />
      <ResultItemAdditionInfo
        result={result}
        isSelect={isSelect}
        market={selectedMarket?.market!}
      />
    </tr>
  );
};
