export const setCookie = (name: string, value: string, day: number) => {
  const date = new Date();
  date.setTime(date.getTime() + day * 24 * 60 * 60 * 1000);

  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const getCookie = (name: string) => {
  const cookiename = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);

  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(cookiename) === 0) {
      return c.substring(cookiename.length, c.length);
    }
  }
  return "";
};
