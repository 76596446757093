import ChannelService from "@/components/ChannelTalk/ChannelService.js";
import { User } from "@/interface/user";
import { useEffect } from "react";

const useChannelTalk = (userInfo: User) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      ChannelService.loadScript();

      ChannelService.boot({
        pluginKey: process.env.REACT_APP_CHANNEL_TALK_PLUGIN,
        memberId: userInfo?.username,
        profile: {
          name: userInfo?.originalUserName,
          mobileNumber: userInfo?.userTelNo,
          email: userInfo?.userEmail,
        },
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
      ChannelService.shutdown();
    };
  }, [userInfo]);
};

export default useChannelTalk;
