import { ReactCropperElement } from "react-cropper";
import { useTooltip } from "@/hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";
import { ReverseLeftRightIcon } from "@/components/svg/ReverseLeftRightIcon";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const FlipHorizontalButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleFlipH = (): void => {
    cropperRef.current?.cropper?.scaleX(
      -cropperRef.current.cropper.getData().scaleX
    );
  };

  return (
    <button
      onClick={handleFlipH}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("좌우 반전")}
    >
      <ReverseLeftRightIcon />
    </button>
  );
};
