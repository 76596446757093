import { GreenBigButton } from "@/components/Button/allttamButton/GreenBigButton";
import { WhiteBigButton } from "@/components/Button/allttamButton/WhiteBigButton";
import { WhiteGreenBigButton } from "@/components/Button/allttamButton/WhiteGreenBigButton";
import useFetch from "@/hooks/useFetch";
import {
  ExpectListType,
  MarketPrice,
  PriceType,
  UploadSummary,
} from "@/interface/uploadinterface";
import { upload } from "@/utils/functions/postApi";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import { UseMutationResult, useQueryClient } from "react-query";
import { calculateExpect } from "../../function/calculateExpect";
import { madePercent } from "../../function/madePercent";
import useModal from "@/hooks/useModal";
import { Dispatch, SetStateAction } from "react";

interface Props {
  isFreeDelivery: boolean;
  price: PriceType;
  selectedItem: UploadSummary[];
  exchangeRateValue: {
    exchangeCNYBase: number;
    exchangeUSDBase: number;
  };
  setExpectList: React.Dispatch<React.SetStateAction<ExpectListType[]>>;
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
  setSelectedItem: React.Dispatch<React.SetStateAction<UploadSummary[]>>;
}

export const PriceSetButton = ({
  isFreeDelivery,
  price,
  selectedItem,
  exchangeRateValue,
  setExpectList,
  collectProductSummary,
  setSelectedItem,
}: Props) => {
  const { closeModal } = useModal();
  const [getPrice] = useFetch("price", "/Market/GetPrice");
  const openWindow = useInfoWindow();
  const queryClient = useQueryClient();

  const clickSetButton = async () => {
    try {
      if (
        (price.mainValue <= 0 || !price.mainValue) &&
        (!price.subValue || price.subValue <= 0)
      )
        throw new Error();
      const setPriceRes = await upload.setPrice({
        objectIds: selectedItem.map((item) => item.id),
        priceTemplate: {
          deliveryBasicPrice: isFreeDelivery ? 0 : price.deliveryBasicPrice,
          deliveryReturnPrice: price.deliveryReturnPrice,
          deliveryExchangePrice: price.deliveryReturnPrice * 2,
          exchangeCNYBase: exchangeRateValue.exchangeCNYBase,
          exchangeUSDBase: exchangeRateValue.exchangeUSDBase,
          mainType: "×",
          mainValue: madePercent.percentToInt(price.mainValue)
            ? madePercent.percentToInt(price.mainValue)
            : 1,
          subType: "+",
          subValue: price.subValue,
          marketExpandPrice: [
            {
              market: "Coupang",
              addRatio: price.Coupang ? price.Coupang : 0,
              deliveryBaiscPrice: isFreeDelivery ? 0 : price.deliveryBasicPrice,
              deliveryReturnPrice: price.deliveryReturnPrice,
            },
            {
              market: "Auction",
              addRatio: price.Auction ? price.Auction : 0,
              deliveryBaiscPrice: isFreeDelivery ? 0 : price.deliveryBasicPrice,
              deliveryReturnPrice: price.deliveryReturnPrice,
            },
            {
              market: "Gmarket",
              addRatio: price.Gmarket ? price.Gmarket : 0,
              deliveryBaiscPrice: isFreeDelivery ? 0 : price.deliveryBasicPrice,
              deliveryReturnPrice: price.deliveryReturnPrice,
            },
            {
              market: "Elevenst",
              addRatio: price.Elevenst ? price.Elevenst : 0,
              deliveryBaiscPrice: isFreeDelivery ? 0 : price.deliveryBasicPrice,
              deliveryReturnPrice: price.deliveryReturnPrice,
            },
          ],
        },
      });
      if (setPriceRes === "적용 완료") {
        openWindow(
          `가격이 설정이 완료되었습니다.\n 상품 ${selectedItem.length}건`
        );
        collectProductSummary.mutate();
        queryClient.invalidateQueries("price");
        setSelectedItem((prev) => {
          const updatedItems = [...prev];
          updatedItems.map((item) => {
            item.minimumPrice[1].salePrice = 1;
          });
          return updatedItems;
        });
        closeModal();
      }
    } catch {
      openWindow(
        `마진(율)을 입력해 주세요\n기본 판매가 공식을 참고하여 ‘기본 마진율(%)’ 또는 ‘추가 마진’ 금액을 입력해 주세요`
      );
    }
  };

  const calculateExpectationMargin = () => {
    const expectList = calculateExpect({
      itemList: selectedItem,
      rateUSD: exchangeRateValue.exchangeUSDBase,
      rateCNY: exchangeRateValue.exchangeCNYBase,
      mainValue: madePercent.percentToInt(
        !price.mainValue ? 0 : price.mainValue
      ),
      subValue: !price.subValue ? 0 : price.subValue,
      deliveryFee: price.deliveryBasicPrice,
      extraMargin: !price.subValue ? 0 : price.subValue,
    });

    setExpectList(expectList);
  };

  return (
    <div className="d-flex mt-5 gap-3 justify-content-center">
      <WhiteBigButton onClick={closeModal}>취소</WhiteBigButton>
      <WhiteGreenBigButton onClick={calculateExpectationMargin}>
        예상 마진
      </WhiteGreenBigButton>
      <GreenBigButton onClick={clickSetButton}>저장</GreenBigButton>
    </div>
  );
};
