import { SaleStartIcon } from "@/components/svg/SaleStartIcon";

interface Props {
  value: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SaleStartButton = ({ onClick, value }: Props) => {
  return (
    <button
      value={value}
      onClick={onClick}
      className="cancel-btn save-btn reset-btn del-btn sale-reg-btn pause-btn mobile-width"
    >
      <SaleStartIcon />
      판매 시작
    </button>
  );
};
