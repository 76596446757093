import { Link } from "react-router-dom";
import { PaymentLog } from "../../../interface/paymentInterface";
import { RightArrowIcon } from "../../svg/RightArrowIcon";
import usePayLogs from "../hooks/usePayLogs";

export const PaymentPayLog = () => {
  const { payLogs } = usePayLogs({ page: 1, limit: 3 });

  return (
    <div
      className="col-md-6 col-12 payment-log align-content-center"
      style={{ maxWidth: "810px" }}
    >
      <div className="heading-two-inner history-col-payinfo">
        <h3 className="h3 weight600 margin0">결제 내역</h3>
        <Link to="/payment/log" className="heading-two-inner-1">
          <p>전체보기</p>
          <RightArrowIcon />
        </Link>
      </div>
      {(payLogs?.length === 0 || !payLogs) && (
        <h6 className="h5 d-flex widthFull justify-content-center mt-5">
          결제 내역이 없습니다.
        </h6>
      )}
      <div className="paylog-table-header">
        {payLogs?.length > 0 && (
          <table width="100%" cellPadding={7} className="homeLogTable">
            <thead className="head-one">
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">상품이름</th>
                <th className="text-center">가격</th>
                <th className="text-center">결제일시</th>
              </tr>
            </thead>
            <tbody className="body-one">
              {payLogs?.map((log: PaymentLog) => (
                <>
                  <tr
                    key={log.no}
                    className="text-center text14 grey"
                    style={{ height: "2rem" }}
                  >
                    <td>{log.no}</td>
                    <td>{log.planName}</td>
                    <td>{log.price.toLocaleString()}</td>
                    <td>{log.lastPayDate}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
