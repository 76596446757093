import { useEffect, useState } from "react";
import { CalendarIcon } from "../../../svg/CalendarIcon";
import { changePeriodTimeFormat } from "../../../../utils/functions/changePeriodTimeFormat";

interface Props {
  setDate: React.Dispatch<
    React.SetStateAction<{
      startDate: string;
      endDate: string;
    }>
  >;
  date: {
    startDate: string;
    endDate: string;
  };
}

export const CSPeriodBar = ({ setDate, date }: Props) => {
  const [selcted, setSelected] = useState("aDay");
  useEffect(() => {
    const { startDate, endDate } = changePeriodTimeFormat(selcted);
    setDate({ startDate: startDate, endDate: endDate });
  }, [selcted]);

  const selectPeriod = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelected(e.currentTarget.value);
  };

  const changeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDate({
      ...date,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="col-12">
        <div className="payment-detail-1 gap-0 justify-content-between">
          <div className="d-flex flex-wrap align-items-center gap-5">
            <div className="payment-detail-inner d-flex align-items-center hidden">
              <button
                onClick={selectPeriod}
                className={`dateSelector madebutton ${
                  selcted === "aDay" && "selectedButton"
                }`}
                value="aDay"
              >
                1일
              </button>
              <div className="buttonDivder" />
              <button
                onClick={selectPeriod}
                className={`dateSelector madebutton ${
                  selcted === "threeDays" && "selectedButton"
                }`}
                value="threeDays"
              >
                3일
              </button>
              <div className="buttonDivder" />
              <button
                onClick={selectPeriod}
                className={`dateSelector madebutton ${
                  selcted === "fiveDays" && "selectedButton"
                }`}
                value="fiveDays"
              >
                5일
              </button>
              <div className="buttonDivder" />
              <button
                onClick={selectPeriod}
                className={`dateSelector madebutton ${
                  selcted === "sixDays" && "selectedButton"
                }`}
                value="sixDays"
              >
                6일
              </button>
            </div>
            <div className="d-flex align-items-center gap-1">
              <div className="dateContainer">
                <input
                  value={date.startDate as string}
                  name="startDate"
                  onChange={changeDate}
                  className="date-input"
                  type="date"
                />
                <div className="calender">
                  <CalendarIcon />
                </div>
              </div>
              ~
              <div className="dateContainer">
                <input
                  value={date.endDate as string}
                  name="endDate"
                  onChange={changeDate}
                  className="date-input"
                  type="date"
                />
                <div className="calender">
                  <CalendarIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
