import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive-style.css";
import "./assets/css/allttam.style.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "./Router";
import ErrorBoundary from "@/components/ErrorBoundary";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 윈도우 포커싱 되었을 때 다시 패칭 하지 않겠다.
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <BrowserRouter>
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </BrowserRouter>
    </RecoilRoot>
  </QueryClientProvider>,
  document.getElementById("root") as HTMLElement
);

serviceWorkerRegistration.register();
