export const extractUrl = (htmlString: string): string[] => {
  const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;

  // 문자열에서 URL 추출
  if (!htmlString) return [];
  const urls = htmlString?.match(urlPattern);

  return urls?.map((item) =>
    item.split('"')[0].split("'")[0].replace("http:", "https:")
  )!;
};
