import useToggle from "@/hooks/useToggle";
import { UploadSummary } from "@/interface/uploadinterface";
import { UploadCheckLog } from "../../modal/UploadCheckLog";

interface Props {
  item: UploadSummary;
}

export const ItemFunctionUploadLog = ({ item }: Props) => {
  const { state: isUploadLogModal, handleToggle: handleUploadLogModal } =
    useToggle();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <button
        onClick={handleUploadLogModal}
        className="cancel-btn save-btn reset-btn name-set-btn basic-btn"
      >
        업로드 로그
      </button>
      {isUploadLogModal && (
        <UploadCheckLog closeModal={handleUploadLogModal} item={item} />
      )}
    </div>
  );
};
