import { useMemo, useState } from "react";
import Slider from "react-slick";

interface Props {
  bannerArray: Banner[];
}

export const Banner = ({ bannerArray }: Props) => {
  const [index, setIndex] = useState(0);

  const SWIPER_SETTING = useMemo(() => {
    return {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500,
      focusOnSelect: true,
      beforeChange: (oldIndex: number, newIndex: number) => {
        setIndex(newIndex);
      },
    };
  }, []);

  return (
    <div className="swiper">
      <Slider {...SWIPER_SETTING}>
        {bannerArray?.map((item, idx) => (
          <button
            key={idx}
            className={`bannerContainer madebutton paddingZero`}
            onClick={() => {
              if (item.url === "") return;
              if (idx !== index) return;
              window.open(item.url, "_blank");
            }}
          >
            <img
              src={item.img}
              className="bannerImg"
              alt={`배너${index}`}
              loading="lazy"
            />
          </button>
        ))}
      </Slider>
    </div>
  );
};

interface Banner {
  img: string;
  url: string;
}
