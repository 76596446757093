interface Props {
  width?: number;
  height?: number;
}

export const XCircleIcon = ({ width, height }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width ? width : "20"}`}
      height={`${height ? height : "20"}`}
      viewBox="0 0 20 20"
    >
      <circle data-name="타원 330" cx="10" cy="10" r="10" opacity={0.3} />
      <g data-name="그룹 11227">
        <path
          data-name="패스 10536"
          d="m204.162 19.565-6 6"
          transform="translate(-191.162 -12.565)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2px"
        />
        <path
          data-name="패스 10537"
          d="m198.162 19.565 6 6"
          transform="translate(-191.162 -12.565)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2px"
        />
      </g>
    </svg>
  );
};
