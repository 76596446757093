export const ReverseTopDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g data-name="그룹 11488">
        <g data-name="그룹 11486">
          <path
            data-name="패스 10547"
            d="m1254.335 857.713-6 6 6 6"
            transform="rotate(-90 216.311 1062.024)"
            strokeLinecap="round"
            stroke="#39415c"
            strokeLinejoin="round"
            strokeWidth={2}
            fill="none"
          />
          <path
            data-name="패스 10549"
            d="m1248.335 857.713 6 6-6 6"
            transform="rotate(-90 207.311 1053.024)"
            strokeLinecap="round"
            stroke="#39415c"
            strokeLinejoin="round"
            strokeWidth={2}
            fill="none"
          />
          <path
            data-name="패스 10548"
            d="M148.409 16v14"
            transform="rotate(-90 80.705 85.704)"
            stroke="#39415c"
            strokeLinecap="round"
            strokeWidth={2}
            fill="none"
          />
        </g>
        <path
          data-name="사각형 3980"
          transform="rotate(-90 18 18)"
          fill="none"
          d="M0 0h36v36H0z"
        />
      </g>
    </svg>
  );
};
