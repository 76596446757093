import { NavPaymentLog } from "@/components/NavBar/component/NavPaymentLog";
import NavBar from "@/components/NavBar/NavBar";
import useFetch from "../hooks/useFetch";
import { PayLogItem } from "../components/PaymentLog/PayLogItem";
import useToggle from "../hooks/useToggle";
import { ImgTranslateModal } from "../components/Home/modal/ImgTranslateModal";
import usePayLogs from "@/components/Payment/hooks/usePayLogs";
import { PaymentLog as IPaymentLog } from "@/interface/paymentInterface";
import axiosInstance from "@/utils/axiosInstance";
import { RedSmallButton } from "@/components/Button/allttamButton/RedSmallButton";
import { useInfoWindow } from "@/hooks/useInfoWindow";

const PaymentLog = () => {
  const { state: isImgLogModal, handleToggle: handleImgLogModal } = useToggle();
  const { state: isTextLogModal, handleToggle: handleTextLogModal } =
    useToggle();
  const openWindow = useInfoWindow();
  const { payLogs, meta } = usePayLogs({ page: 1, limit: 10 });

  return (
    <div>
      <NavBar>
        <NavPaymentLog />
      </NavBar>
      <div className="row total-table-col">
        <div className="col-12">
          <div className="heading-two heading-three heading-five heading-five1 moblie-pay-heading">
            <div className="heading-two-inner">
              <h6>전체 {meta?.totalCount}</h6>
              <div className="translateLogButtonBox gap-4">
                <button
                  onClick={handleImgLogModal}
                  className="madebutton textYellow imgLogButton"
                >
                  올땀 스튜디오 내역
                </button>
                <button
                  onClick={handleTextLogModal}
                  className="madebutton textLogButton"
                >
                  텍스트 수집 내역
                </button>
              </div>
            </div>
            <table width="100%" align="center" className="payLogTable">
              <thead className="head-one">
                <tr>
                  <th>번호</th>
                  <th>상품이름</th>
                  <th>오픈마켓 슬롯</th>
                  <th>결제가격</th>
                  <th>결제일시</th>
                </tr>
              </thead>
              <tbody className="body-one">
                {payLogs?.map((log: IPaymentLog) => (
                  <PayLogItem key={log.no} log={log} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <RedSmallButton
          style={{
            position: "absolute",
            bottom: "20px",
            right: "100px",
          }}
          onClick={async () => {
            const res = await axiosInstance.patch(
              "AllddamPay/CancelSubscribeAllddamPlan",
              {}
            );

            openWindow(res?.data.message);
          }}
        >
          구독취소
        </RedSmallButton>
      </div>
      {isImgLogModal && (
        <ImgTranslateModal closeModal={handleImgLogModal} type="IT" />
      )}
      {isTextLogModal && (
        <ImgTranslateModal closeModal={handleTextLogModal} type="CT" />
      )}
    </div>
  );
};

export default PaymentLog;
