import { upload } from "@/utils/functions/postApi";
import { useAlert } from "@/hooks/useAlert";
import useToggle from "@/hooks/useToggle";
import { UploadSummary } from "@/interface/uploadinterface";
import { AlertConfirmAndClose } from "@/components/Alert/AlertConfirmAndClose";
import { ResetIcon } from "@/components/svg/ResetIcon";
import { AlertOnlyClose } from "@/components/Alert/AlertOnlyClose";
import { UseMutationResult } from "react-query";
import useModal from "@/hooks/useModal";
import { useInfoWindow } from "@/hooks/useInfoWindow";

interface Props {
  setProductNameValueList: React.Dispatch<
    React.SetStateAction<
      {
        code: string;
        text: string;
      }[]
    >
  >;
  productNameValueList: {
    code: string;
    text: string;
  }[];
  selectedItem: UploadSummary[];
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
  setSelectedItem: React.Dispatch<React.SetStateAction<UploadSummary[]>>;
}

export const ProductNameSetButtonBox = ({
  setProductNameValueList,
  productNameValueList,
  selectedItem,
  collectProductSummary,
  setSelectedItem,
}: Props) => {
  const { closeModal } = useModal();

  const openWindow = useInfoWindow();

  const clickReset = () => {
    setProductNameValueList(
      selectedItem.map((item) => {
        return {
          code: item.id,
          text: item?.title?.[1]?.text
            ? item?.title?.[1]?.text
            : item?.title?.[0]?.text,
        };
      })
    );
  };

  const confirmSetProductName = async () => {
    const setRes = await upload.setProductName(productNameValueList);
    if (setRes === "성공했습니다.") {
      collectProductSummary.mutate();
      openWindow("성공적으로 변경되었습니다.");
      closeModal();
      // setSelectedItem([]);
    } else {
      openWindow("오류가 발생했습니다.");
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
          <button onClick={clickReset} className="cancel-btn">
            <ResetIcon />
            되돌리기
          </button>
          <button
            onClick={confirmSetProductName}
            className="cancel-btn save-btn"
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
};
