import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import "./SideBar.css";
import { SideBarAnchorBox } from "./component/SideBarAnchorBox";
import { SideBarItemBottom } from "./component/SideBarItemBottom";
import SideBarItemTop from "./component/SideBarItemTop";
import { memo } from "react";
import useUser from "@/hooks/useUser";

interface Props {
  handleIsNav: () => void;
  isNav: boolean;
}

const SideBar = ({ handleIsNav, isNav }: Props) => {
  const userInfo = useUser();
  return (
    <div className="sidenav">
      <button className="sideNavArrow madebutton" onClick={handleIsNav}>
        <img src="/images/open.png" alt="" />
      </button>
      <div className="overflowYScroll">
        <Link to="/home" className="logoWrapper">
          <img className="logo2" src="/images/logo.png" alt="logo" />
        </Link>
        <div className="navItemList">
          <SideBarItemTop isNav={isNav} />
          <hr className="hr1" />
          <SideBarItemBottom userInfo={userInfo} isNav={isNav} />
        </div>
      </div>
      <SideBarAnchorBox isNav={isNav} />
    </div>
  );
};

export default SideBar;
