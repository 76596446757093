import { useTooltip } from "@/hooks/useTooltip";
import { UploadSummary } from "@/interface/uploadinterface";
import { UploadTooltip } from "../../Tooltip/UploadTooltip";
import { copyFunction } from "@/utils/functions/copyFunction";

interface Props {
  item: UploadSummary;
  isSelect: boolean;
  isViewWayDetail: boolean;
}

export const ItemDetailInfo = ({ item, isSelect, isViewWayDetail }: Props) => {
  const [isCopyTooltip, enterCopy, leaveCopy] = useTooltip();

  return (
    <td>
      <div
        className={`collect-inner-1 original-price div-collect-1 unset ${
          isSelect && "selectedItem"
        } ${item.tags && isViewWayDetail && "alignBase"}`}
      >
        <div className="original-price-inner detailInfoBox tooltipRel">
          {isViewWayDetail && (
            <h5 className="textCenter">
              수집일 : {item.regDate.split("T")[0].replaceAll("-", ".")}
            </h5>
          )}
          <h5>
            {isViewWayDetail && (
              <>
                원본 판매가 (
                {
                  CURRENCY_INDEX[
                    item?.minimumPrice.filter(
                      (item) => item.currency !== "KRW"
                    )[0].currency as "USD" | "KRW" | "WON" | "CNY"
                  ]
                }
                ) :{" "}
                {item?.minimumPrice
                  ?.filter((item) => item.currency !== "KRW")[0]
                  ?.originalPrice.toLocaleString()}{" "}
                ~{" "}
                {item?.maximumPrice
                  ?.filter((item) => item.currency !== "KRW")[0]
                  ?.originalPrice.toLocaleString()}
                <br />
              </>
            )}
            원본 할인가 (
            {
              CURRENCY_INDEX[
                item?.minimumPrice.filter((item) => item.currency !== "KRW")[0]
                  .currency as "USD" | "KRW" | "WON" | "CNY"
              ]
            }
            ) :{" "}
            {item?.minimumPrice
              ?.filter((item) => item.currency !== "KRW")[0]
              ?.salePrice.toLocaleString()}{" "}
            ~{" "}
            {item?.maximumPrice
              ?.filter((item) => item.currency !== "KRW")[0]
              ?.salePrice.toLocaleString()}
            <br />
            {item?.minimumPrice?.filter((item) => item.currency === "KRW")[0]
              ?.salePrice === 0 ? (
              <p className="textRed mt-3">가격 설정해 주세요</p>
            ) : (
              <>
                설정 상품가 (\) :{" "}
                {item?.minimumPrice
                  ?.filter((item) => item.currency === "KRW")[0]
                  ?.salePrice.toLocaleString("KR")}{" "}
                ~{" "}
                {item?.maximumPrice
                  ?.filter((item) => item.currency === "KRW")[0]
                  ?.salePrice.toLocaleString("KR")}
              </>
            )}
          </h5>
          {isCopyTooltip && (
            <div className="d-flex justify-content-center">
              <UploadTooltip className="tagTooltip">
                키워드 전체 복사
              </UploadTooltip>
            </div>
          )}
          <div className="tags-btn mb-1 tagBox">
            {item.tags ? (
              item.tags?.map((tag: string, idx: number) => (
                <span
                  key={idx}
                  className="tag-1 margin0 pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    copyFunction(item.tags?.join(", ")!);
                  }}
                  onMouseEnter={enterCopy as () => void}
                  onMouseLeave={leaveCopy as () => void}
                >
                  <h5 className="textGreen margin0 tagText">{tag}</h5>
                </span>
              ))
            ) : (
              <h5 onClick={(e) => e.stopPropagation()} className="textRed">
                태그 설정해 주세요
              </h5>
            )}
          </div>
        </div>
      </div>
    </td>
  );
};

const CURRENCY_INDEX = {
  KRW: "\\",
  CNY: "￥",
  USD: "$",
  WON: "\\",
};
