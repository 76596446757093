import { useQuery } from "react-query";
import { getUser } from "@/apis/authApis";

const useUser = () => {
  const { data } = useQuery("user", getUser, {
    staleTime: 600000,
  });
  return data;
};

export default useUser;
